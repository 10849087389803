import React, { FC } from 'react'
import { Avatar, Card, Text } from '@chakra-ui/react'

type Props = {
  name: string,
  image: string,
  backgroundColor: string,
  num: number,
  total: number,
}

const ProfilePreviewCard: FC<Props> = (props: Props) => {
  const dw = window.outerWidth;
  const componentWidth = dw * 0.92;
  return (
    <Card
      // w={"100%"}
      // h={"100%"}
      w={componentWidth - 0.05 * componentWidth * (props.total - 1)}
      h={componentWidth - 0.05 * componentWidth * (props.total - 1)}
      // w={dw * 0.92 + "px"}
      // h={dw * 0.92 + "px"}
      backgroundColor={props.backgroundColor}
      borderRadius={"20px"}
      alignItems={"center"}
      justifyContent={"center"}
      boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
      position={"absolute"}
      top={0.05 * componentWidth * (props.total - 1) / (props.total - 1) * props.num}
      right={0.05 * componentWidth * (props.total - 1) / (props.total - 1) * props.num}
    >
      <Avatar
        size={"xl"}
        src={props.image}
        name={props.name}
      ></Avatar>
      <Text
        variant="profilePreview"
        style={{ fontSize: "16px" }}
      >{props.name}</Text>
    </Card>
  )
}

export default ProfilePreviewCard