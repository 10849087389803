import { Avatar } from "@chakra-ui/react";
import React, { FC, useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { friendsState, profilesState, tmpAvatarState, notFriendsState } from "recoilStore/store";
import { supabase } from "Services/supabase";

type Props = {
  userId: string;
  chatRoomName: string;
  gotBorder?: boolean;
  group?: boolean;
};

type ProfileType = {
  id: string
  avatar: string
  description: string
  name_on_profile: string
  background_color: string
  text_identifier: string
  num_identifier: string
};

type TmpUserMapping = {
  [tmpProfileId: string]: string;
};

const ProfileAvatar: FC<Props> = ({ userId, chatRoomName, gotBorder, group }: Props) => {
  const [friends, setFriends] = useRecoilState(friendsState);
  const [notFriends, setNotFriends] = useRecoilState(notFriendsState);
  const [profileName, setProfileName] = useState<string | null>(null);
  const [profilePic, setProfilePic] = useState<string | null>(null);
  const [profiles, setProfiles] = useRecoilState(profilesState);
  const [tmpAvatar, setTmpAvatar] = useRecoilState(tmpAvatarState);

  useEffect(() => {
    const profile = friends.find((friend) => friend.friend_profile_id === userId);
    const myProfile = profiles.find((profile) => profile.id === userId);
    const notFriend = notFriends.find((friend) => friend.friend_profile_id === userId);
    const tmpProfile = tmpAvatar[userId];

    if (profile) {
      setProfileName(profile.friend_name_on_profile);
      setProfilePic(profile.friend_avatar);
    } else if (myProfile) {
      setProfileName(myProfile.name_on_profile);
      setProfilePic(myProfile.avatar);
    } else if (tmpProfile) {
      setProfileName("New User");
      setProfilePic(tmpProfile);
    } else if (notFriend) {
      setProfileName(notFriend.friend_name_on_profile);
      setProfilePic(notFriend.friend_avatar);
    } else if (userId) {
      const getProfile = async () => {
        const { data, error } = await supabase
          .from("profiles")
          .select("*")
          .eq("id", userId);

        if (error) {
          return null;
        }

        if (data && data.length > 0) {
          const id = data[0].id || 'defaultID';
          const avatar = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/avatars/${data[0].avatar}` || 'chatRoomName'; // Replace 'defaultAvatar' with an appropriate default value

          const mapping: TmpUserMapping = { ...tmpAvatar }
          mapping[id] = avatar;

          if (JSON.stringify(mapping) !== JSON.stringify(tmpAvatar)) {
            setTmpAvatar(mapping);
          }

          setProfileName(data[0].name_on_profile);
          setProfilePic(avatar);
        }
      };

      getProfile();
    }
  }, [friends, userId, profiles, tmpAvatar, profileName]);

  return (
    <>
      <Avatar
        name={profileName || chatRoomName}
        src={profilePic || ""}
        h="100%"
        w="inherit"
        border={gotBorder ? "0.15rem solid white" : "none"}
        backgroundColor={group ? "#73B3A3" : "white"}
      />
    </>

  );
};

export default ProfileAvatar;
