import { Box, Flex, Text } from "@chakra-ui/react";
import React, { FC } from "react";

type Props = {
  username: string;
  // level: number;
  // UCN: string;
};

const ProfileHeader: FC<Props> = ({ username }: Props) => {
  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Text
          variant="soft"
          fontSize="lg"
          fontWeight="bold"
          color="white"
          textShadow="1px 2px 2px black"
        >
          {username}
        </Text>
        {/* <Flex
          bg="white"
          fontSize="10px"
          lineHeight="10px"
          p="0.5em"
          borderRadius="99999rem"
          justifyContent="center"
          alignItems="center"
          h="100%"
        >
          <Text variant="soft" color="green.UC" fontSize="inherit">
            {"Level " + level}
          </Text>
        </Flex> */}
      </Flex>
      {/* <Text 
        color="black.grey" 
        fontSize={"lg"}
      >
        {UCN.toString().slice(0, 4) +
          " " +
          UCN.toString().slice(4, 8) +
          " " +
          UCN.toString().slice(8, 12)}
      </Text> */}
    </Box>
  );
};

export default ProfileHeader;
