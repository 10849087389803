import React, { ChangeEvent, ChangeEventHandler, FC, useState } from "react";
import { supabase } from "Services/supabase";
import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Text,
    Image,
    Box,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

type Props = {};

const LogInPage: FC<Props> = ({}: Props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const toast = useToast();

    const navigate = useNavigate();

    const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (
        e: ChangeEvent<HTMLInputElement>
    ) => {
        e.preventDefault();
        setEmail(e.target.value);
    };

    const handlePasswordChange: ChangeEventHandler<HTMLInputElement> = (
        e: ChangeEvent<HTMLInputElement>
    ) => {
        e.preventDefault();
        setPassword(e.target.value);
    };

    const handleSignIn = async () => {
        const { error } = await supabase.auth.signInWithPassword({
            email: email,
            password: password,
        });

        if (error) {
            toast({
                title: "Error",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        navigate("/chats");
    };

    const handleGoogleSignIn = async (ev: React.MouseEvent<HTMLElement>) => {
        const { data, error } = await supabase.auth.signInWithOAuth({
            provider: "google",
            options: {
                redirectTo: "/",
                queryParams: {
                    access_type: "offline",
                    prompt: "consent",
                },
            },
        });
        localStorage.setItem("UC_OAuth_Data", JSON.stringify(data));

        if (error) {
            toast({
                title: "Error",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        navigate("/home");
    };

    return (
        <Flex
            flexDir="column"
            justifyContent="space-between"
            h="100vh"
            p="10vw"
        >
            <Flex flexGrow={1} flexDir="column" justifyContent="center">
                <Box alignSelf="center" boxSize="40vw" marginBottom="15vw">
                    <Image
                        src="https://zzwxyobwfvjshssazycj.supabase.co/storage/v1/object/public/images/logoUC.png"
                        alt="logo"
                        boxSize="40vw"
                        alignSelf="center"
                    />
                </Box>

                <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Password</FormLabel>
                    <Input
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                </FormControl>
                <Button mt="4vw" onClick={handleSignIn}>
                    Sign-In
                </Button>

                <Button 
                    mt="4vw" 
                    variant="link"
                    onClick={() => {
                        navigate("/forgotpassword");
                    }}
                >
                    Forgot Password?
                </Button>

                <Text
                    mt="10vw"
                    textAlign="center"
                    fontSize="sm"
                    color="gray.500"
                >
                    Don't have an account?{" "}
                    <Button
                        variant="link"
                        onClick={() => {
                            navigate("/signup");
                        }}
                    >
                        Sign-Up
                    </Button>
                </Text>
            </Flex>
            <Button variant="link" onClick={handleGoogleSignIn}>
                Sign-In with Google
            </Button>
        </Flex>
    );
};

export default LogInPage;
