import React, { FC } from 'react';
import { Box, Circle, Flex } from '@chakra-ui/react';

type Props = {
    numberOfNewMsg: number;
}

const NewMsgMarker:FC<Props> = (props: Props) => {
    return (
        <Box as={Flex}>
            {/* <Circle size="0.5rem" bg={"red"} /> */}

            {
                props.numberOfNewMsg > 0 ? (
                    props.numberOfNewMsg > 99 ? (
                        <p style={{
                            backgroundColor: "red",
                            borderRadius: "10px",
                            color: "white",
                            fontSize: "14px",
                            fontWeight: "bold",
                            height: "20px",
                            textAlign: "center",
                            lineHeight: "20px",
                            margin: "0",
                            padding: "0 6px",
                        }}>99+</p>
                    ) : (
                        <p style={{
                            backgroundColor: "red",
                            borderRadius: "10px",
                            color: "white",
                            fontSize: "14px",
                            fontWeight: "bold",
                            height: "20px",
                            textAlign: "center",
                            lineHeight: "20px",
                            margin: "0",
                            padding: "0 6px",
                        }}>{props.numberOfNewMsg}</p>
                    )
                ) : (
                    < p style={{
                        backgroundColor: "transparent",
                        borderRadius: "10px",
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "bold",
                        height: "20px",
                        textAlign: "center",
                        lineHeight: "20px",
                        margin: "0",
                        padding: "0 6px",
                    }}></p>
                )
            }
        </Box >
    )
}

export default NewMsgMarker