import React, { FC } from 'react'
import { Card, Text, Flex, Drawer, useDisclosure, DrawerBody, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, Box, Stack, WrapItem, Avatar, Spacer } from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'

type Props = {}

const AboutUsTile: FC<Props> = (props: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const openAboutUs = () => {
    // console.log("openAboutUs")
    onOpen()
  }

  return (
    <>
      <Card
        w={"100%"}
        h={"100%"}
        borderRadius={"10px"}
        boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
        backgroundColor={"green.UC"}
        onClick={openAboutUs}
      >
        <Flex
          w={"100%"}
          h={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Text
            variant={"UpdateTitle"}
            alignContent={"center"}
            textAlign={"center"}
          >
            About us
          </Text>
        </Flex>
      </Card>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        size={"full"}
      >
        <DrawerOverlay bg="white" />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Text variant={"soft"}>About us</Text>
          </DrawerHeader>

          <DrawerBody
            padding={0}
          >
            <Box
              backgroundColor="#7B919C"
              width={"100%"}
              p={"4%"}
            >
              <Text
                variant={"soft"}
                fontSize={"18px"}
                margin={"0px 0px 1rem 0px"}
              >
                Why we are here?
              </Text>
              <Text
                variant={"soft"}
                fontFamily={"roboto"}
                fontSize={"14px"}
                fontStyle={"normal"}
              >
                We founded UniConnect to revolutionize the way people engage with social media and each other. Our platform is dedicated to solving the problems inherent in traditional social media platforms—mainly the lack of identity segmentation and its indirect contribution to social isolation. We believe that technology should serve as a facilitator to real-life connections, not a barrier. UniConnect aims to provide you with intuitive, customizable profiles and features that help you connect more genuinely, both online and in-person.
              </Text>
            </Box>

            <Box
              backgroundColor="#C5BAC4"
              width={"100%"}
              p={"4%"}
            >
              <Text
                variant={"soft"}
                fontSize={"18px"}
                margin={"0px 0px 1rem 0px"}
              >
                What we value?
              </Text>

              <Text
                variant={"soft"}
                fontSize={"14px"}
                fontStyle={"normal"}
                marginBottom={"1rem"}
              >
                <Flex
                  alignItems={"center"}
                  margin={"0px 0px 10px 0px"}
                >
                  <CheckIcon marginRight={"0.5rem"} />
                  <Text
                    variant={"soft"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                  >
                    Privacy:
                  </Text>
                </Flex>
                <Text fontFamily={"roboto"}>
                  We believe that your data is yours and yours alone. We will never sell your data to third parties, and we will never use your data to target you with ads.
                </Text>
              </Text>

              <Text
                variant={"soft"}
                fontSize={"14px"}
                fontStyle={"normal"}
                marginBottom={"1rem"}
              >
                <Flex
                  alignItems={"center"}
                  margin={"0px 0px 10px 0px"}
                >
                  <CheckIcon marginRight={"0.5rem"} />
                  <Text
                    variant={"soft"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                  >
                    Real connections:
                  </Text>
                </Flex>
                <Text fontFamily={"roboto"}>
                  We prioritize real-life interactions and aim to enhance them with our online platform.
                </Text>
              </Text>

              <Text
                variant={"soft"}
                fontSize={"14px"}
                fontStyle={"normal"}
                marginBottom={"1rem"}
              >
                <Flex
                  alignItems={"center"}
                  margin={"0px 0px 10px 0px"}
                >
                  <CheckIcon marginRight={"0.5rem"} />
                  <Text
                    variant={"soft"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                  >
                    User experience:
                  </Text>
                </Flex>
                <Text fontFamily={"roboto"}>
                  We constantly monitor user feedback and analyze behaviors to improve your experience. Whether it's a user interface tweak or a brand-new feature, your ease of use and satisfaction drive our innovations.
                </Text>
              </Text>

              <Text
                variant={"soft"}
                fontSize={"14px"}
                fontStyle={"normal"}
                marginBottom={"1rem"}
              >
                <Flex
                  alignItems={"center"}
                  margin={"0px 0px 10px 0px"}
                >
                  <CheckIcon marginRight={"0.5rem"} />
                  <Text
                    variant={"soft"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                  >
                    Social responsibility:
                  </Text>
                </Flex>
                <Text fontFamily={"roboto"}>
                  Our commitment to social responsibility means that we aim to make technology safe and accessible for everyone. It's our way of giving back to the community and setting a standard for what technology, when used responsibly, can accomplish.
                </Text>
              </Text>

              <Text
                variant={"soft"}
                fontSize={"14px"}
                fontStyle={"normal"}
                marginBottom={"1rem"}
              >
                <Flex
                  alignItems={"center"}
                  margin={"0px 0px 10px 0px"}
                >
                  <CheckIcon marginRight={"0.5rem"} />
                  <Text
                    variant={"soft"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                  >
                    Integrity and Transparency:
                  </Text>
                </Flex>
                <Text fontFamily={"roboto"}>
                  Openness forms the backbone of our operation. Whether it's a clear explanation of how we use your data or candid communication about our business operations, we believe in full transparency with our users.
                </Text>
              </Text>
            </Box>

            <Box
              backgroundColor="green.UC"
              width={"100%"}
              p={"4%"}
            >
              <Text
                variant={"soft"}
                fontSize={"14px"}
                fontStyle={"normal"}
              >
                <Text
                  variant={"soft"}
                  fontSize={"18px"}
                  margin={"0px 0px 0.5rem 0px"}
                >
                  Who are we?
                </Text>
                <Text fontFamily={"roboto"}>
                  We are a team of young, energetic tech enthusiasts who get what the younger generation needs and wants. Grounded in the realities of modern digital life, our collective mission at UniConnect is to enhance real-world interactions through meaningful tech solutions. With our fresh perspectives and commitment to user-centric innovation, we're not just developing an app; we're building a community.
                </Text>
                <Text
                  variant={"soft"}
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  marginTop={"1rem"}
                  textAlign={"right"}
                >
                  ~ The UniConnect Team
                </Text>
              </Text>

              <Flex
                marginTop={"0.5rem"}
                gap={"0.5rem"}
              >
                <Spacer />
                <WrapItem>
                  <Avatar name='Giovanni Marcolla' src='https://zzwxyobwfvjshssazycj.supabase.co/storage/v1/object/public/images/Giovanni%20Marcolla.jpg' />
                </WrapItem>
                <WrapItem>
                  <Avatar name='Ruiwen Hu' src='https://zzwxyobwfvjshssazycj.supabase.co/storage/v1/object/public/images/Ruiwen%20Hu.png' />
                </WrapItem>
                <WrapItem>
                  <Avatar name='Vrajang Parikh' src='https://zzwxyobwfvjshssazycj.supabase.co/storage/v1/object/public/images/Vrajang%20Parikh.jpg' />
                </WrapItem>
                <WrapItem>
                  <Avatar name='Yunjie Zhang' src='https://zzwxyobwfvjshssazycj.supabase.co/storage/v1/object/public/images/Yunjie%20Zhang.jpg' />
                </WrapItem>
              </Flex>
            </Box>

            <Box
              backgroundColor="purple.UC"
              width={"100%"}
              p={"4%"}
            >
              <Text
                variant={"soft"}
                fontSize={"14px"}
                fontStyle={"normal"}
                color={"white"}
              >
                <Text
                  variant={"soft"}
                  fontSize={"18px"}
                  margin={"0.5rem 0px"}
                >
                  A letter from founders:
                </Text>
                <Text
                  color={"white.UC"}
                  fontFamily={"roboto"}
                  fontWeight={"normal"}
                >
                  <Text
                    marginTop={"1rem"}
                  >
                    Dear UniConnect Community,
                  </Text>
                  <Text
                    marginTop={"1rem"}
                  >
                    When we began our journey as students at the University of Waterloo, we found ourselves in a sea of incredible talent and potential. Yet, despite being surrounded by such vibrant energy and fascinating individuals, truly connecting with our peers remained a surprising challenge. We were all in the same classes, walking the same hallways, and even sharing the same major life experiences, but the opportunities to genuinely get to know one another were few and far between.
                  </Text>
                  <Text
                    marginTop={"1rem"}
                  >
                    We realized that this wasn't just a problem confined to our campus. In an age where technology has made us more connected than ever, it's ironic how disconnected we can feel from the people right next to us. Whether it's the person you sit beside in class for an entire semester without speaking to or the talented individuals across other faculties that you never get a chance to meet, the lack of real connection felt almost paradoxical.
                  </Text>
                  <Text
                    marginTop={"1rem"}
                  >
                    That's why we created UniConnect. We envisioned a platform that goes beyond algorithms and friend suggestions. A tool that genuinely understands the needs and lifestyles of modern students, enhancing real-world interactions with meaningful tech solutions. Our commitment to user privacy, social responsibility, and continuous innovation is aimed at ensuring that your experience is not just secure, and private, but also genuinely rewarding.
                  </Text>
                  <Text
                    marginTop={"1rem"}
                  >
                    As we continue to evolve and grow, we encourage you to join us in making real connections, both online and offline. Thank you for being a part of our journey. Together, let's redefine what it means to connect in the digital age.
                  </Text>
                  <Text
                    marginTop={"1rem"}
                  >
                    Warm welcome to UniConnect,
                  </Text>
                  <Text
                    textAlign={"right"}
                    marginTop={"1rem"}
                  >
                    Gio, Ruiwen, Vrajang, and Yunjie
                  </Text>
                  <Text
                    textAlign={"right"}
                  >
                    Founders, UniConnect
                  </Text>
                </Text>
              </Text>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default AboutUsTile