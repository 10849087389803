import React, { FC } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'

type Props = {
  title: string
}

const DefaultTitle:FC<Props> = (props: Props) => {
  return (
    <Flex
      w="100%"
      h="70px"
      alignItems="center"
      p={4}
      borderBottom="1px solid #CDCDCD"
      background={"white"}
    >
      <Text variant={"tileTitle"}>
        {props.title}
      </Text>
      <ChevronRightIcon 
        ml="auto"
        color="black.UC"
        h={6}
        w={6}
      />
    </Flex>
  )
}

export default DefaultTitle