import React, { Dispatch, SetStateAction, useEffect, useState, FC } from "react";
import Select from "react-select";
import { useRecoilState } from "recoil";
import { profilesState } from "recoilStore/store";

interface OptionType {
    label: string;
    value: string;
}

interface Props {
    setMyProfileId: Dispatch<SetStateAction<string>>;
    myProfileId: string;
}

const SelectMyProfile: FC<Props> = ({ setMyProfileId, myProfileId }: Props) => {
    const [profile, setProfiles] = useRecoilState(profilesState);
    const [selectedOptions, setSelectedOptions] = useState<OptionType | null>(null);

    const myProfileOption: OptionType[] = profile.map((me) => ({
        label: me.name_on_profile,
        value: me.id,
    }));

    const handleChange = (selectedOption: OptionType | null) => {
        setSelectedOptions(selectedOption);

        if (selectedOption) {
            setMyProfileId(selectedOption.value);
        } else {
            setMyProfileId("");
        }
    };

    return (
        <Select
            value={selectedOptions}
            onChange={handleChange as any}  // Cast to 'any' to silence type mismatch error
            options={myProfileOption}
            placeholder="Select Profile"
        />
    );
};

export default SelectMyProfile;
