import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Image,
    Button,
    useToast,
} from "@chakra-ui/react";
import { supabase } from "Services/supabase";
import { useState, ChangeEventHandler, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

interface Props {}

const ForgotPasswordPage: React.FC<Props> = ({}: Props) => {
    const [email, setEmail] = useState("");

    const toast = useToast();
    const navigate = useNavigate();

    const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (
        e: ChangeEvent<HTMLInputElement>
    ) => {
        e.preventDefault();
        setEmail(e.target.value);
    };

    const handleSubmit = async () => {
        const { error } = await supabase.auth.resetPasswordForEmail(email, {
            redirectTo: "https://uniconnect.ca/updatepassword",
        });

        if (error) {
            toast({
                title: "Error",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        navigate("/");
    };

    return (
        <Flex
            flexDir="column"
            justifyContent="space-between"
            h="100vh"
            p="10vw"
        >
            <Flex flexGrow={1} flexDir="column" justifyContent="center">
                <Box alignSelf="center" boxSize="40vw" marginBottom="15vw">
                    <Image
                        src="https://zzwxyobwfvjshssazycj.supabase.co/storage/v1/object/public/images/logoUC.png"
                        alt="logo"
                        boxSize="40vw"
                        alignSelf="center"
                    />
                </Box>

                <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                    />
                </FormControl>
                <Button mt="4vw" onClick={handleSubmit}>
                    Send reset email
                </Button>
            </Flex>
        </Flex>
    );
};

export default ForgotPasswordPage;
