import React, { Dispatch, SetStateAction, FC } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Box,
  Stack,
} from '@chakra-ui/react';
import ComponentMultiselect from "Components/ComponentMultiselect";
import SelectMyProfile from "Components/SelectMyProfile";

type Props = {
  groupName: string;
  setGroupName: Dispatch<SetStateAction<string>>;
  groupDescription: string;
  setGroupDescription: Dispatch<SetStateAction<string>>;
  selectedFriends: string[];
  setSelectedFriends: Dispatch<SetStateAction<string[]>>;
  myProfileId: string;
  setMyProfileId: Dispatch<SetStateAction<string>>;
};

const GroupForm: FC<Props> = ({
  groupName, setGroupName,
  groupDescription, setGroupDescription,
  selectedFriends, setSelectedFriends,
  myProfileId, setMyProfileId }: Props) => {
  const handleGroupNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(e.target.value);
  };

  const handleGroupDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setGroupDescription(e.target.value);
  };

  return (
    <Box p={4}>
      <Stack spacing={4}>
        <FormControl isRequired>
          <FormLabel>Group Name</FormLabel>
          <Input placeholder='Your group name' onChange={handleGroupNameChange} />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Group Description</FormLabel>
          <Textarea
            placeholder='Your group description'
            onChange={handleGroupDescriptionChange}
            size="md"
            h={40}
          />
        </FormControl >

        <FormControl isRequired>
          <FormLabel>Select My Profile</FormLabel>
          <SelectMyProfile
            setMyProfileId={setMyProfileId}
            myProfileId={myProfileId} />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Select Friends</FormLabel>
          <ComponentMultiselect setSelectedFriends={setSelectedFriends} selectedFriends={selectedFriends} />
        </FormControl>
      </Stack>
    </Box>
  );
}

export default GroupForm;
