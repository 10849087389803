// All the store variables includes:
// 1. profilesIDs: RecoilState<Array<string>>
// 2. userID: RecoilState<string>
// 3. roomProfileMappingState: RecoilState<RoomProfileMapping>
// 4. profilesState: RecoilState<Profile[]>
// 5. friendsState: RecoilState<FriendType[]>
// 6. tmpAvatarState: RecoilState<tmpAvatarType>
// 7. roomsState: RecoilState<Room[]>
// 8. notFriendsState: RecoilState<NotFriendType[]>
// 9. roomProfilesState: RecoilState<RoomProfilesType>

import { RecoilState, atom } from "recoil";

export const profilesIDs: RecoilState<Array<string>> = atom<Array<string>>({
    key: "profilesIDs",
    default: []
});

export const userID: RecoilState<string> = atom<string>({
    key: "userID",
    default: ""
});

export const userNameState: RecoilState<string> = atom<string>({
    key: "userName",
    default: ""
});

export const userIDState = atom<string>({
    key: 'userIDState',
    default: '',
});

export const userEmail = atom<string>({
    key: 'userEmail',
    default: '',
});

type RoomProfileMapping = {
    [room_id: string]: string; // room_id maps to profile_id
};

export const roomProfileMappingState = atom<RoomProfileMapping>({
    key: 'roomProfileMappingState',
    default: {}, // Default to an empty object of the defined type
});

type Profile = {
    avatar: string;
    created_at: string;
    description: string;
    id: string;
    name_on_profile: string;
    owner: string;
    profile_name: string;
    background_color: string;
    text_identifier: string;
    num_identifier: string;
};

export const profilesState = atom<Profile[]>({
    key: 'profilesState',
    default: [],
});

type FriendType = {
    profile_id: string;
    friend_profile_id: string;
    status: string;
    friend_avatar: string;
    friend_name_on_profile: string;
    friend_description: string;
    friend_background_color: string;
    friend_text_identifier: string;
    friend_num_identifier: string;
    room_id: string;
};

export const friendsState = atom<FriendType[]>({
    key: 'friendsState',
    default: [],
});

type tmpAvatarType = {
    [tmpProfileId: string]: string;
};

export const tmpAvatarState = atom<tmpAvatarType>({
    key: 'tmpAvatarState',
    default: {},
});

type Room = {
    room_id: string;
    room_name: string;
    room_description: string;
    is_muted: boolean;
    profile_id: string;
    last_message_time: string;
    unread_messages: number;
};

export const roomsState = atom<Room[]>({
    key: 'roomsState',
    default: [],
});

type NotFriendType = {
    profile_id: string;
    friend_profile_id: string;
    status: string;
    friend_avatar: string;
    friend_name_on_profile: string;
    friend_description: string;
    friend_background_color: string;
    friend_text_identifier: string;
    friend_num_identifier: string;
    room_id: string;
    initiator: string;
};

export const notFriendsState = atom<NotFriendType[]>({
    key: 'notFriendsState',
    default: [],
});

type RoomProfileType = {
    profile_id: string,
    avatar: string,
    name_on_profile: string,
    description: string,
    background_color: string,
    text_identifier: string,
    num_identifier: string
}

type RoomProfilesType = {
    [room_id: string]: RoomProfileType[]
}

export const roomProfilesState = atom<RoomProfilesType>({
    key: 'roomProfilesState',
    default: {},
});

type ProfileEventType = {
    profile_id: string
    event_id: string
    chat_id: string
    place_id: string
    event_name: string
    start_time: string
    end_time: string
    event_discription: string
    create_time: string
    is_muted: boolean
    location_name: string
}

type ProfileEventMappingType = {
    [profile_id: string]: ProfileEventType[]
}

export const profileEventMappingState = atom<ProfileEventMappingType>({
    key: 'profileEventMappingState',
    default: {},
});

export const newMSgIdState = atom<string>({
    key: 'newMSgState',
    default: '',
});

type LocationStateType = {
    location_id: string;
    location_name: string;
    place_id: string;
    chat_id: string;
    profile_id: string;
    sign_in: string;
}

export const myLocationState = atom<LocationStateType[]>({
    key: 'myLocationState',
    default: [],
});

type GroupType = {
    room_id: string;
    room_name: string;
    room_description: string;
    creator_id: string;
    profile_id: string;
}

export const myGroupState = atom<GroupType[]>({
    key: 'myGroupState',
    default: [],
});

export const oneSignal = atom<boolean>({
    key: 'oneSignal',
    default: false,
});

export const msgNotificationNum = atom<number>({
    key: 'msgNotificationNum',
    default: 0,
});

export const friendNotificationNum = atom<number>({
    key: 'friendNotificationNum',
    default: 0,
});

type profileEventType = {
    [profile_id: string]: string
}

export const profileLocation = atom<profileEventType>({
    key: 'profileLocation',
    default: {},
});

type unread_messagesType = {
    friends_unread: number;
    groups_unread: number;
    events_unread: number;
    location_unread: number;
}

export const unread_messages = atom<unread_messagesType>({
    key: 'unread_messages',
    default: {
        friends_unread: 0,
        groups_unread: 0,
        events_unread: 0,
        location_unread: 0,
    },
});