import React, { FC, useEffect } from "react";
import {
  Flex,
  IconButton,
  Box,
  Icon,
  Tab,
  TabList,
  TabIndicator,
} from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { StatusHeader } from "Components";

import { faEllipsis as ellipsis } from "@fortawesome/free-solid-svg-icons";

import {
  faComment as regularComment,
  faUser as regularUser,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useRecoilState } from "recoil";
import { roomsState, profilesIDs, roomProfileMappingState, myLocationState, userIDState, profileLocation, friendsState } from "recoilStore/store";
import { supabase } from "Services/supabase";

type Props = {
  onClose: Function;
  chatId: string;
  profileId: string;
};

// const user = {
//   id: "14",
//   name: "User 2",
//   status: "active",
//   location: "SCH",
// };

type LocationStateType = {
  location_id: string;
  location_name: string;
  place_id: string;
  chat_id: string;
  profile_id: string;
  sign_in: string;
}

const ChatRoomHeader: FC<Props> = ({ chatId, onClose, profileId }: Props) => {
  const [rooms, setRooms] = useRecoilState(roomsState);
  const [profileIDsState, setProfileIDsState] = useRecoilState(profilesIDs);
  const [roomProfileMapping, setRoomProfileMapping] = useRecoilState(roomProfileMappingState);
  const [myLocation, setMyLocation] = useRecoilState(myLocationState);
  const [userID, setUserID] = useRecoilState(userIDState);
  const [profileLocationState, setProfileLocationState] = useRecoilState(profileLocation);
  const [friends, setFriends] = useRecoilState(friendsState);

  const ChatIcon = (
    <Icon
      as={FontAwesomeIcon}
      icon={regularComment}
      color={"#5B5081"}
      boxSize={5}
    />
  );

  const UserIcon = (
    <Icon
      as={FontAwesomeIcon}
      icon={regularUser}
      color={"#5B5081"}
      boxSize={5}
    />
  );

  const EllipsisIcon = (
    <Icon as={FontAwesomeIcon} icon={ellipsis} color={"#5B5081"} boxSize={5} />
  );

  const updateRoomUnreadMsg = (roomId: string, newUnreadMsg: number) => {
    const updatedRooms = rooms.map((room) => {
      if (room.room_id === roomId && room.profile_id === profileId) {
        return {
          ...room,
          unread_messages: newUnreadMsg,
        };
      }
      return room;
    });

    setRooms(updatedRooms);
  };

  const handleOnClick = async () => {
    onClose()
  }

  const thisRoom = rooms.find((room) => room.room_id === chatId);

  type Room = {
    room_id: string;
    room_name: string;
    room_description: string;
    is_muted: boolean;
    profile_id: string;
    last_message_time: string;
    unread_messages: number;
  };

  type RoomProfileMapping = {
    [room_id: string]: string;
  };

  const fetchMyLocations = async () => {
    if (!userID) {
      return;
    }

    const { data, error } = await supabase.rpc('fetch_all_locations', { p_user_id: userID });

    if (error) {
      console.log(error);
      return;
    }

    // update myLocation state
    const mapping: LocationStateType[] = [...myLocation];
    data.forEach((item: any) => {
      if (!mapping.find((location) => location.location_id === item.location_id))
        mapping.push({
          location_id: item.location_id,
          location_name: item.location_name,
          place_id: item.place_id,
          chat_id: item.chat_id,
          profile_id: item.profile_id,
          sign_in: item.sign_in
        })
    })

    setMyLocation(mapping);
  }

  const fetchRooms = async () => {
    const { data, error } = await supabase.rpc('fetch_rooms', { profile_ids: profileIDsState });

    if (error) {
      console.log(error);
      return;
    }

    // console.log("data: ", data)

    const transformedRooms: Room[] = [];
    const mapping: RoomProfileMapping = { ...roomProfileMapping };

    data.forEach((item: any) => {
      transformedRooms.push({
        room_id: item.room_id,
        room_name: item.room_name,
        room_description: item.room_description,
        is_muted: item.is_muted,
        profile_id: item.profile_id,
        last_message_time: item.last_msg_time,
        unread_messages: item.unread_messages,
      });
      mapping[item.room_id] = item.profile_id; // Map room_id to profile_id
    });

    if (JSON.stringify(mapping) !== JSON.stringify(roomProfileMapping)) {
      setRoomProfileMapping(mapping); // Update only if there's a change
    }

    setRooms(transformedRooms);

    // update myLocation
    const newMyLocation = myLocation.map((loc) => {
      const newLoc = { ...loc };
      newLoc.chat_id = loc.place_id;
      return newLoc;
    });

    setMyLocation(newMyLocation);
  };

  useEffect(() => {
    // if (thisRoom) {
    //   updateRoomUnreadMsg(chatId, 0);
    // }

    if (thisRoom) {
      // updateRoomUnreadMsg(chatId, 0);
    } else {
      fetchRooms()
      fetchMyLocations()
    }
  }, [chatId]);

  return (
    <Flex
      w={"100%"}
      h={"80px"}
      justifyContent={"space-between"}
      alignItems={"center"}
      pos={"sticky"}
      p={"1rem 1rem"}
      borderBottom={"1px solid #CDCDCD"}
      bg={"white"}
      top={"0"}
      zIndex={"2"}
    >
      <IconButton
        aria-label="Back to chatlist"
        icon={<ChevronLeftIcon boxSize={8} />}
        onClick={handleOnClick}
        backgroundColor={"white"}
      />
      <Box flexGrow={5}>
        <StatusHeader
          id={thisRoom?.room_id || ""}
          name={thisRoom?.room_name ? (thisRoom?.room_name.length > 12 ? thisRoom?.room_name.slice(0, 12) + "..." : thisRoom?.room_name) : ""}
          // status={user.status || ""}
          location={friends && profileLocationState ? profileLocationState[friends.find(friend => friend.room_id === chatId)?.friend_profile_id as string] : ""}
        />
      </Box>
      <Flex w={"35%"}>
        <TabList
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          w={"100%"}
          h={"100%"}
        >
          <Tab width={"40px"}>
            <IconButton
              aria-label="Chat"
              icon={ChatIcon}
              backgroundColor={"white"}
            />
          </Tab>
          <Tab width={"40px"}>
            <IconButton
              aria-label="User"
              icon={UserIcon}
              backgroundColor={"white"}
            />
          </Tab>
          <Tab width={"40px"}>
            <IconButton
              aria-label="More"
              icon={EllipsisIcon}
              backgroundColor={"white"}
            />
          </Tab>
        </TabList>
      </Flex>
      <TabIndicator
        mt="-1.5px"
        height="2px"
        bg="purple.UC"
        borderRadius="1px"
        position={"relative"}
        bottom={"15px"}
      />
    </Flex>
  );
};

export default ChatRoomHeader;
