import { Box } from "@chakra-ui/react";
import { FullProfile } from "Components";
import Carousel from "framer-motion-carousel";
import React, { FC, useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { profilesState } from "recoilStore/store";

type Props = {};
type Profiles = {
  avatar: string;
  created_at: string;
  description: string;
  id: string;
  name_on_profile: string;
  owner: string;
  profile_name: string;
  background_color: string;
  text_identifier: string;
  num_identifier: string;
};

const FullProfileView: FC<Props> = (props: Props) => {
  const [profiles, changeProfiles] = useState<Profiles[]>([]);
  const [myProfiles, setmyProfiles] = useRecoilState(profilesState);

  useEffect(() => {
    const getProfiles = () => {
      changeProfiles(myProfiles); 
    };

    if (profiles.length <= 0) getProfiles();

    // set local state to myProfiles
    changeProfiles(myProfiles);
    // console.log(profiles);
  }, [myProfiles, profiles]);

  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      align-items="center"
      h={"80vh"}
      margin={"auto"}
    >
      <Carousel
        interval={0}
        autoPlay={false}
        loop={true}
        renderArrowLeft={() => <div></div>}
        renderArrowRight={() => <div></div>}
        renderDots={({ activeIndex, setActiveIndex }) => <div style={
          {
            position: "absolute",
            bottom: "0",
            width: "100%",
            height: "3rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }
        }>
          {
            profiles.map((profile, i) => (
              <div
                key={i}
                style={{
                  width: "1rem",
                  height: "1rem",
                  borderRadius: "50%",
                  backgroundColor:
                    activeIndex === i
                      ? "rgba(0, 0, 0, 0.7)"
                      : "rgba(0, 0, 0, 0.3)",
                }}
                onClick={() => setActiveIndex(i)}></div>
            ))
          }
        </div>}
      >
        {profiles.map((profile, i) => (
          <FullProfile key={i} profileData={profile}/>
        ))}
      </Carousel>
    </Box>
  );
};

export default FullProfileView;
export { type Profiles };
