import React, { FC, useEffect, useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Modal,
  ModalBody,
  useDisclosure,
  ModalOverlay,
  ModalContent
} from "@chakra-ui/react";
import { DefaultTitle, ContactTile, FullProfile } from "Components";
import { useRecoilState } from "recoil";
import { friendsState, profilesState, roomProfileMappingState } from "recoilStore/store";

type Props = {
  handleClick: Function;
};

interface Friend {
  id: string;
  name: string;
  room_id: string;
  status: string;
  location: string;
}

interface FriendsList {
  id: string;
  name: string;
  friends: Friend[];
}

type RoomProfileMapping = {
  [room_id: string]: string;
};

type Profiles = {
  avatar: string;
  created_at: string;
  description: string;
  id: string;
  name_on_profile: string;
  owner: string;
  profile_name: string;
  background_color: string;
  text_identifier: string;
  num_identifier: string;
};

const ContactsLists: FC<Props> = ({ handleClick }) => {
  const [friends, setFriends] = useRecoilState(friendsState);
  const [profiles, setProfiles] = useRecoilState(profilesState);
  const [friendsList, setFriendsList] = useState<FriendsList[]>([]);
  const [roomProfileMapping, setRoomProfileMapping] = useRecoilState(roomProfileMappingState);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const newMapping: RoomProfileMapping = { ...roomProfileMapping };
    const newFriendsList = profiles.map((profile) => {
      const profileFriends = friends.filter((friend) => friend.profile_id === profile.id).map((friend) => {
        if (!newMapping[friend.room_id]) {
          newMapping[friend.room_id] = profile.id;
        }
        return {
          id: friend.friend_profile_id,
          name: friend.friend_name_on_profile,
          room_id: friend.room_id,
          status: "active",
          location: "Somewhere",
        };
      });

      return {
        id: profile.id,
        name: profile.name_on_profile,
        friends: profileFriends,
      };
    });

    setFriendsList(newFriendsList);
  }, [friends]);

  return (
    <Tabs mt={4} borderTop="1px solid #CDCDCD" backgroundColor={"white"} p={"0.3rem 0 0 0"} size="lg">
      <TabList overflowX="scroll" overflowY="hidden" position={"sticky"} top={"80px"} backgroundColor={"white"} zIndex={1} paddingBottom={"2px"}>
        {profiles.map((profileList) => {
          return <Tab key={profileList.id} _selected={{ color: "green.UC", borderBottomColor: "green.UC" }} style={{ fontFamily: "roboto", fontSize: "1rem" }} >
            {profileList.profile_name}
          </Tab>
        })}
      </TabList>

      <TabPanels>
        {friendsList.map((profileList) => {
          let thisProfile = profiles.find((profile) => profile.id === profileList.id) as Profiles;
          return <>
            <TabPanel key={profileList.id} p={0}>
              <Button width="100%" h={"80px"} p={"0"} m={"0"} variant={"unstyled"} textAlign={"left"} onClick={onOpen}>
                <DefaultTitle title={"Check this profile"} />
              </Button>
              <Modal
                isOpen={isOpen}
                onClose={onClose}
                isCentered={true}
                size={"80vh"}
              >
                <ModalOverlay
                  bg="#00000060"
                  padding={"0"}
                />
                <ModalContent
                  height={"80vh"}
                  padding={"0"}
                >
                  <FullProfile key={profileList.id} profileData={thisProfile} />
                </ModalContent>
              </Modal>
              {profileList.friends.map((friend) => (
                <Button width="100%" h={"80px"} p={"0"} m={"0"} variant={"unstyled"} textAlign={"left"} onClick={() => handleClick(friend.room_id)}>
                  <ContactTile key={friend.id} id={friend.id} name={friend.name} status={friend.status} location={friend.location} />
                </Button>
              ))}
            </TabPanel>
          </>
        })}
      </TabPanels>
    </Tabs>
  );
};

export default ContactsLists;
