import { Card, Text, Flex, Icon, Box, Drawer, useDisclosure, DrawerBody, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from '@chakra-ui/react'
import React, { FC } from 'react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { useRecoilState } from 'recoil'
import { userNameState, userEmail, profilesIDs, friendsState, userIDState, userID } from 'recoilStore/store'
import { supabase } from 'Services/supabase'
import { useNavigate } from "react-router-dom";
import { TermsOfUse, PrivacyPolicy } from 'Components';

type Props = {}

const SettingTile: FC<Props> = (props: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: termOfUseIsOpen, onOpen: onRermOfUseOpen, onClose: onTermOfUseClose } = useDisclosure()
  const { isOpen: privacyPolicyIsOpen, onOpen: onPrivacyPolicyOpen, onClose: onPrivacyPolicyClose } = useDisclosure()
  const [userName, setUserName] = useRecoilState(userNameState)
  const [userEmailValue, setUserEmailValue] = useRecoilState(userEmail)
  const [profilesIDsValue, setProfilesIDsValue] = useRecoilState(profilesIDs)
  const [friendsValue, setFriendsValue] = useRecoilState(friendsState)
  const [userIdA, setUserIdA] = useRecoilState(userIDState)
  const [userId, setUserId] = useRecoilState(userID)

  const navigate = useNavigate();

  const openAboutUs = () => {
    // console.log("openAboutUs")
    onOpen()
  }

  const logOutUser = async () => {
    const { error } = await supabase.auth.signOut()

    if (error) {
      console.log(error)
    } else {
      // console.log("User logged out successfully")
      setUserIdA("")
      setUserId("")
      setUserName("")
      setUserEmailValue("")
      setProfilesIDsValue([])
      setFriendsValue([])

      navigate("/welcome");
    }
  }

  return (
    <>
      <Card
        w={"100%"}
        h={"100%"}
        borderRadius={"10px"}
        boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
        backgroundColor={"white"}
        onClick={openAboutUs}
      >
        <Flex
          w={"100%"}
          h={"80%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Icon
            viewBox="0 0 60 60"
            w={"60%"}
            h={"60%"}
          >
            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M30 40.5937C24.1875 40.5937 19.4062 35.8125 19.4062 30C19.4062 24.1875 24.1875 19.4062 30 19.4062C35.8125 19.4062 40.5938 24.1875 40.5938 30C40.5938 35.8125 35.8125 40.5937 30 40.5937ZM30 21.6562C25.4063 21.6562 21.6563 25.4062 21.6563 30C21.6563 34.5937 25.4063 38.3437 30 38.3437C34.5938 38.3437 38.3437 34.5937 38.3437 30C38.3437 25.4062 34.5938 21.6562 30 21.6562Z" fill="#5B5081" />
              <path d="M33.1875 52.4063H26.8125L25.0313 46.9688C23.8125 46.5938 22.6875 46.125 21.5625 45.5625L16.4063 48.1875L11.9063 43.6875L14.5313 38.5313C13.9688 37.4063 13.4063 36.2813 13.125 35.0625L7.6875 33.2813V26.9063L13.125 25.125C13.5 23.9063 13.9688 22.7813 14.5313 21.6563L11.9063 16.5L16.4063 12L21.5625 14.625C22.6875 14.0625 23.8125 13.5 25.0313 13.2188L26.8125 7.78125H33.1875L34.9688 13.2188C36.1875 13.5938 37.3125 14.0625 38.4375 14.625L43.5938 12L48.0938 16.5L45.4688 21.6563C46.0313 22.7813 46.5938 23.9063 46.875 25.125L52.3125 26.9063V33.2813L46.875 35.0625C46.5 36.2813 46.0313 37.4063 45.4688 38.5313L48.0938 43.6875L43.5938 48.1875L38.4375 45.5625C37.3125 46.125 36.1875 46.6875 34.9688 46.9688L33.1875 52.4063ZM28.4063 50.25H31.5938L33.2813 45.1875L33.8438 45C35.25 44.625 36.5625 44.0625 37.875 43.3125L38.4375 43.0313L43.2188 45.4688L45.4688 43.2188L43.0312 38.4375L43.3125 37.875C44.0625 36.6563 44.625 35.25 45 33.8438L45.1875 33.2813L50.25 31.5938V28.4063L45.1875 26.7188L45 26.1563C44.625 24.75 44.0625 23.3438 43.3125 22.125L43.0312 21.5625L45.4688 16.7813L43.2188 14.5313L38.4375 16.9688L37.875 16.6875C36.6562 15.9375 35.25 15.375 33.8438 15L33.2813 14.8125L31.5938 9.75001H28.4063L26.7188 14.8125L26.1562 15C24.75 15.375 23.4375 15.9375 22.125 16.6875L21.5625 16.9688L16.7812 14.5313L14.5313 16.7813L16.9688 21.5625L16.6875 22.125C15.9375 23.3438 15.375 24.75 15 26.1563L14.8125 26.7188L9.75 28.4063V31.5938L14.8125 33.2813L15 33.8438C15.375 35.25 15.9375 36.6563 16.6875 37.875L16.9688 38.4375L14.5313 43.2188L16.7812 45.4688L21.5625 43.0313L22.125 43.3125C23.3438 44.0625 24.75 44.625 26.1562 45L26.7188 45.1875L28.4063 50.25Z" fill="#5B5081" />
            </svg>
          </Icon>
        </Flex>
        <Flex
          textAlign={"center"}
          flexDirection={"column"}
          alignItems={"center"}
          position={"relative"}
          bottom={"0.5rem"}
        >
          <Text
            variant={"SettingTitle"}
          >
            Settings
          </Text>
        </Flex>
      </Card>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        size={"full"}
      >
        <DrawerOverlay bg="white" />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            borderBottomWidth="1px"
            backgroundColor={"white"}
          >
            <Text variant={"soft"}>Settings</Text>
          </DrawerHeader>

          <DrawerBody
            padding={0}
            backgroundColor={"white.UC"}
          >
            <Box
              backgroundColor={"white"}
              h={"70px"}
            >
              <Flex
                w="100%"
                h="70px"
                alignItems="center"
                p={4}
                borderBottom="1px solid #CDCDCD"
                background={"white"}
              >
                <Text variant={"tileTitle"}>
                  Username
                </Text>

                <Text
                  ml={"auto"}
                  color={"black"}
                >
                  {userName}
                </Text>
              </Flex>
            </Box>

            <Box
              backgroundColor={"white"}
              h={"70px"}
            >
              <Flex
                w="100%"
                h="70px"
                alignItems="center"
                p={4}
                borderBottom="1px solid #CDCDCD"
                background={"white"}
              >
                <Text variant={"tileTitle"}>
                  Email
                </Text>

                <Text
                  ml={"auto"}
                  color={"black"}
                >
                  {userEmailValue}
                </Text>
              </Flex>
            </Box>

            <Box
              backgroundColor={"white"}
              h={"70px"}
              marginTop={"1rem"}
            >
              <Flex
                w="100%"
                h="70px"
                alignItems="center"
                p={4}
                borderBottom="1px solid #CDCDCD"
                background={"white"}
              >
                <Text
                  variant={"tileTitle"}
                  color={"green.UC"}
                >
                  Number of profiles
                </Text>

                <Text
                  ml={"auto"}
                  color={"green.UC"}
                >
                  {profilesIDsValue.length}
                </Text>
              </Flex>
            </Box>

            <Box
              backgroundColor={"white"}
              h={"70px"}
            >
              <Flex
                w="100%"
                h="70px"
                alignItems="center"
                p={4}
                borderBottom="1px solid #CDCDCD"
                background={"white"}
              >
                <Text
                  variant={"tileTitle"}
                  color={"green.UC"}
                >
                  Number of friends
                </Text>

                <Text
                  ml={"auto"}
                  color={"green.UC"}
                >
                  {friendsValue.length}
                </Text>
              </Flex>
            </Box>

            <Box
              backgroundColor={"white"}
              h={"70px"}
              marginTop={"1rem"}
              onClick={onRermOfUseOpen}
            >
              <Modal
                isOpen={termOfUseIsOpen}
                onClose={onTermOfUseClose}
                isCentered={true}
                size={"sm"}
              >
                <ModalOverlay bg="#00000060" />
                <ModalContent
                  backgroundColor="white"
                >
                  <ModalHeader textAlign={"center"}>Terms of Use Agreement</ModalHeader>
                  <ModalBody>
                    <TermsOfUse />
                  </ModalBody>

                  <ModalFooter p={0} h={"4rem"} borderTop={"1px black solid"}>
                    <Button width="50%" height={"3rem"} backgroundColor={"white"} borderRight={"1px black solid"} borderRadius={0} color={"red"} disabled>
                      Cancel
                    </Button>
                    <Button width="50%" height={"3rem"} backgroundColor={"white"} onClick={onTermOfUseClose} color={"green.UC"}>
                      OK
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
              <Flex
                w="100%"
                h="70px"
                alignItems="center"
                p={4}
                borderBottom="1px solid #CDCDCD"
                background={"white"}
              >
                <Text
                  variant={"tileTitle"}
                  color={"purple.UC"}
                >
                  Term of use agreement
                </Text>

                <ChevronRightIcon
                  ml={"auto"}
                  w={6}
                  h={6}
                />
              </Flex>
            </Box>

            <Box
              backgroundColor={"white"}
              h={"70px"}
              onClick={onPrivacyPolicyOpen}
            >
              <Modal
                isOpen={privacyPolicyIsOpen}
                onClose={onPrivacyPolicyClose}
                isCentered={true}
                size={"sm"}
              >
                <ModalOverlay bg="#00000060" />
                <ModalContent
                  backgroundColor="white"
                >
                  <ModalHeader textAlign={"center"}>Privacy Policy</ModalHeader>
                  <ModalBody>
                    <PrivacyPolicy />
                  </ModalBody>

                  <ModalFooter p={0} h={"4rem"} borderTop={"1px black solid"}>
                    <Button width="50%" height={"3rem"} backgroundColor={"white"} borderRight={"1px black solid"} borderRadius={0} color={"red"} disabled>
                      Cancel
                    </Button>
                    <Button width="50%" height={"3rem"} backgroundColor={"white"} onClick={onPrivacyPolicyClose} color={"green.UC"}>
                      OK
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
              <Flex
                w="100%"
                h="70px"
                alignItems="center"
                p={4}
                borderBottom="1px solid #CDCDCD"
                background={"white"}
              >
                <Text
                  variant={"tileTitle"}
                  color={"purple.UC"}
                >
                  Privacy Policy
                </Text>

                <ChevronRightIcon
                  ml={"auto"}
                  w={6}
                  h={6}
                  color={"black"}
                />
              </Flex>
            </Box>

            <Box
              backgroundColor={"white"}
              h={"70px"}
              marginTop={"1rem"}
              onClick={logOutUser}
            >
              <Flex
                w="100%"
                h="70px"
                alignItems="center"
                p={4}
                borderBottom="1px solid #CDCDCD"
                background={"white"}
              >
                <Text
                  variant={"tileTitle"}
                  color={"red"}
                  textAlign={"center"}
                  margin={"auto"}
                >
                  Log out
                </Text>
              </Flex>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default SettingTile