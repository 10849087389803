import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Image,
    Button,
    useToast,
} from "@chakra-ui/react";
import { supabase } from "Services/supabase";
import { useState, ChangeEventHandler, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

interface Props {}

const UpdatePasswordPage: React.FC<Props> = ({}: Props) => {
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");

    const toast = useToast();
    const navigate = useNavigate();

    const handleNewPasswordChange: ChangeEventHandler<HTMLInputElement> = (
        e: ChangeEvent<HTMLInputElement>
    ) => {
        e.preventDefault();
        setNewPassword(e.target.value);
    };

    const handleNewPasswordConfirmationChange: ChangeEventHandler<
        HTMLInputElement
    > = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setNewPasswordConfirmation(e.target.value);
    };

    const handleSubmit = async () => {
        const { error } = await supabase.auth.updateUser({
            password: newPassword,
        });

        if (error) {
            toast({
                title: "Error",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            supabase.auth.signOut();
            return;
        }

        navigate("/");
    };

    return (
        <Flex
            flexDir="column"
            justifyContent="space-between"
            h="100vh"
            p="10vw"
        >
            <Flex flexGrow={1} flexDir="column" justifyContent="center">
                <Box alignSelf="center" boxSize="40vw" marginBottom="15vw">
                    <Image
                        src="https://zzwxyobwfvjshssazycj.supabase.co/storage/v1/object/public/images/logoUC.png"
                        alt="logo"
                        boxSize="40vw"
                        alignSelf="center"
                    />
                </Box>

                <FormControl>
                    <FormLabel>New Password</FormLabel>
                    <Input
                        type="password"
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Confirm New Password</FormLabel>
                    <Input
                        type="password"
                        value={newPasswordConfirmation}
                        onChange={handleNewPasswordConfirmationChange}
                    />
                </FormControl>
                <Button
                    mt="4vw"
                    onClick={handleSubmit}
                    isActive={newPassword === newPasswordConfirmation}
                >
                    Set new password!
                </Button>
            </Flex>
        </Flex>
    );
};

export default UpdatePasswordPage;
