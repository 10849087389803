import "@fontsource/sansita/700.css";
import "@fontsource/roboto";
import { extendTheme } from "@chakra-ui/react";
import type { StyleFunctionProps } from "@chakra-ui/styled-system";
import { MultiSelectTheme } from "chakra-multiselect";

type ChatProps = {
  mine: Boolean;
};

type BubbleProps = StyleFunctionProps & ChatProps;

const theme = extendTheme({
  colors: {
    // Need the color pallet from Bill, at leats 4 shades per color
    green: {
      UC: "#73B3A3",
      light: "#DBEBE7",
      grey: "#B2C3AE",
      dark: "#114135",
    },
    red: {
      UC: "#C88181",
      light: "#EED1CC",
      grey: "#BC9696",
      dark: "#770000",
    },
    purple: {
      UC: "#5B5081",
      light: "#8D93BA",
      grey: "#9B9DA8",
      dark: "#2E2353",
    },
    blue: {
      UC: "#5C7BA1",
      light: "#C1D9F8",
      grey: "#C5CCD6",
      dark: "#1F395A",
    },
    white: {
      UC: "#EFEFEF",
      grey: "#CDCDCD",
    },
    black: {
      UC: "#1D1D1D",
      light: "#CDCDCD",
      grey: "#ACACAC",
      dark: "#000000",
    },
  },
  components: {
    Text: {
      variants: {
        soft: (props: StyleFunctionProps) => ({
          fontFamily: "sansita",
          fontWeight: "bolder",
          fontStyle: "Italic",
        }),
        uni: (props: StyleFunctionProps) => ({
          fontFamily: "sansita",
          fontWeight: "bolder",
          fontStyle: "Italic",
          color: "green.UC",
          fontSize: "2xl",
          letterSpacing: "1px",
          marginRight: "1px",
        }),
        connect: (props: StyleFunctionProps) => ({
          fontFamily: "sansita",
          fontWeight: "bolder",
          fontStyle: "Italic",
          color: "purple.UC",
          fontSize: "2xl",
          letterSpacing: "2px",
        }),
        cardNum: (props: StyleFunctionProps) => ({
          fontFamily: "roboto",
          color: "black.grey",
          fontSize: "18px",
          letterSpacing: "1px",
          position: "relative",
          top: "-2px",
        }),
        amount: (props: StyleFunctionProps) => ({
          fontFamily: "roboto",
          color: "white.UC",
          fontSize: "2xl",
          fontWeight: "bolder",
          letterSpacing: "1px",
          // position: "absolute",
          // bottom: "2rem"
        }),
        ucd: (props: StyleFunctionProps) => ({
          fontFamily: "roboto",
          color: "white.UC",
          fontSize: "1rem",
          fontWeight: "normal",
          letterSpacing: "1px",
          position: "relative",
          bottom: "0.2rem",
          left: "0.2rem",
        }),
        profilePreview: (props: StyleFunctionProps) => ({
          fontFamily: "sansita",
          fontWeight: "bolder",
          fontStyle: "Italic",
          color: "white.UC",
          marginTop: "0.5rem",
        }),
        chatTileName: (props: StyleFunctionProps) => ({
          fontFamily: "roboto",
          fontWeight: "bold",
          fontStyle: "normal",
          color: "black.UC",
          fontSize: "14px",
          letterSpacing: "1px",
        }),
        chatSignIn: (props: StyleFunctionProps) => ({
          fontFamily: "roboto",
          fontWeight: "normal",
          fontStyle: "normal",
          color: "purple.light",
          fontSize: "14px",
        }),
        accordianTitle: (props: StyleFunctionProps) => ({
          fontFamily: "sansita",
          fontStyle: "italic",
          color: "purple.grey",
          fontSize: "14px",
        }),
        tileTitle: (props: StyleFunctionProps) => ({
          fontFamily: "sansita",
          fontWeight: "bolder",
          color: "black.UC",
          fontSize: "16px",
          letterSpacing: "1px",
        }),
        TimelineTitle: (props: StyleFunctionProps) => ({
          fontFamily: "sansita",
          fontWeight: "bolder",
          color: "red.UC",
          fontSize: "1.5rem",
          letterSpacing: "1px",
        }),
        TimelineSubtitle: (props: StyleFunctionProps) => ({
          fontFamily: "sansita",
          fontWeight: "bolder",
          color: "white.UC",
          fontSize: "1rem",
          letterSpacing: "1px",
        }),
        RateTitle: (props: StyleFunctionProps) => ({
          fontFamily: "sansita",
          fontWeight: "bolder",
          color: "white.UC",
          fontSize: "1rem",
          letterSpacing: "1px",
        }),
        SettingTitle: (props: StyleFunctionProps) => ({
          fontFamily: "sansita",
          fontWeight: "bolder",
          color: "purple.UC",
          fontSize: "1rem",
          letterSpacing: "1px",
        }),
        UpdateTitle: (props: StyleFunctionProps) => ({
          fontFamily: "sansita",
          fontWeight: "bolder",
          color: "white.UC",
          fontSize: "1rem",
          letterSpacing: "1px",
        }),
        AboutusTitle: (props: StyleFunctionProps) => ({
          fontFamily: "sansita",
          fontWeight: "bolder",
          color: "white.UC",
          fontSize: "1rem",
          letterSpacing: "1px",
        }),
        Welcome: (props: StyleFunctionProps) => ({
          fontFamily: "sansita",
          fontWeight: "normal",
          color: "black.UC",
          fontSize: "1rem",
          letterSpacing: "1.5px",
          marginBottom: "1rem",
        }),
        chatTileTime: (props: StyleFunctionProps) => ({
          fontFamily: "roboto",
          fontWeight: "black.grey",
          fontStyle: "normal",
          color: "black.UC",
          fontSize: "12px",
          letterSpacing: "1px",
        }),
        chatBubble: (props: BubbleProps) => ({
          fontWeight: "normal",
          fontStyle: "normal",
          color: "#000000",
          fontSize: "14px",
        }),
        timestamp: (props: StyleFunctionProps) => ({
          fontWeight: "normal",
          fontStyle: "normal",
          color: "black.UC",
          fontSize: "12px",
          opacity: "65%",
        }),
        chatSignedIn: (props: StyleFunctionProps) => ({
          fontWeight: "bold",
          fontStyle: "normal",
          color: "black.UC",
          fontSize: "12px",
          opacity: "65%",
        }),
      },
    },
    Container: {
      variants: {
        chatBubble: (props: BubbleProps) => ({
          backgroundColor:
            props.mine ? "blue.light" : "green.light",
          borderRadius: "0.5rem",
          flexGrow: 1,
          padding: "0.5rem 1rem",
          boxShadow: "1px 1px 3px #0006",
        }),
      },
    },
    MultiSelect: MultiSelectTheme,
  },
});

export default theme;
