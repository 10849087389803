
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Button } from "@chakra-ui/react";
import { supabase } from "Services/supabase";
import React, { FC, ReactNode, useState, useEffect } from "react";

type ProfileEventType = {
    profile_id: string;
    event_id: string;
    chat_id: string;
    place_id: string;
    event_name: string;
    start_time: string;
    end_time: string;
    event_discription: string;
    create_time: string;
    is_muted: boolean;
    location_name: string;
};

interface AttendanceModalProps {
  event: ProfileEventType;
  isOpen: boolean;
  onClose: () => void;
}



const AttendanceModal: React.FC<AttendanceModalProps> = ({ event, isOpen, onClose }) => {
  const  handleMarkAttendance = async () => {
    // call supbase rpc function add_attendance_entry(p_event_id, p_profile_id) to mark attendance
    let {data, error} =await supabase.rpc('add_attendance_entry', {
        p_event_id: event.event_id,
        p_profile_id: event.profile_id
        });
    if (error) {
        console.log("error: ", error);
    }
    console.log("Success Attendance: ", data);
    onClose();
  };
  
  return (
    
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg="#00000060" />
      <ModalContent backgroundColor="white">
        <ModalHeader>Mark Attendance</ModalHeader>
        <ModalBody>
          <p>Would  you like to mark attendance for the event {event.event_name} ?</p>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="green" mr={3} onClick={handleMarkAttendance}>
            Mark Attendance
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AttendanceModal;
