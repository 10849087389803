import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import { ChatRoomHeader, ConversationViewer, ProfileViewer, ChatSettingViewer } from "Components";
import { ChatListView } from "Views";
import React, { FC } from "react";
import { useRecoilState } from "recoil";
import { roomsState, profilesIDs, msgNotificationNum, userID, unread_messages } from "recoilStore/store";
import { supabase } from "Services/supabase";

type Props = {};

const ChatRoomView: FC<Props> = (props: Props) => {
  const [rooms, setRooms] = useRecoilState(roomsState);
  const [profileIDsState, setProfileIDsState] = useRecoilState(profilesIDs);
  const [userId, setUserId] = useRecoilState(userID);
  const [msgNotification, setMsgNotification] = useRecoilState(msgNotificationNum);
  const [unreadMsg, setUnreadMsg] = useRecoilState(unread_messages);

  const [chatId, setChatId] = React.useState("");
  const [profile_id, setProfileId] = React.useState("");
  const [tabIndex, setTabIndex] = React.useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [typeOfChats, setTypeOfChats] = React.useState(0); // ["friends", "groups", "events", "locations"

  const handleClose = async () => {
    handleTabsChange(0);
    const room = rooms.find((room) => room.room_id === chatId && room.profile_id === profile_id);

    const newMsg = room?.unread_messages || 0;
    setMsgNotification(msgNotification - newMsg);

    updateRoomUnreadMsg(chatId, 0);
    setChatId(chatId);
    setProfileId(profile_id);

    const { data, error } = await supabase.rpc("read_unread", {
      p_room_id: chatId,
      p_profile_ids: [profile_id]
    });

    let friends_unreadMSG = unreadMsg.friends_unread;
    let groups_unreadMSG = unreadMsg.groups_unread;
    let events_unreadMSG = unreadMsg.events_unread;
    let location_unreadMSG = unreadMsg.location_unread;

    if (typeOfChats === 0) {
      friends_unreadMSG -= newMsg;
    } else if (typeOfChats === 1) {
      groups_unreadMSG -= newMsg;
    } else if (typeOfChats === 2) {
      events_unreadMSG -= newMsg;
    } else if (typeOfChats === 3) {
      location_unreadMSG -= newMsg;
    }

    const updatedUnreadMsg = {
      friends_unread: friends_unreadMSG,
      groups_unread: groups_unreadMSG,
      events_unread: events_unreadMSG,
      locations_unread: location_unreadMSG,
    }

    setUnreadMsg(updatedUnreadMsg as any);

    onClose();
  }

  const ht = window.innerHeight;

  const updateRoomUnreadMsg = (roomId: string, newUnreadMsg: number) => {
    const updatedRooms = rooms.map((room) => {
      if (room.room_id === roomId && room.profile_id === profile_id) {
        return {
          ...room,
          unread_messages: newUnreadMsg,
        };
      }
      return room;
    });

    setRooms(updatedRooms);
  };

  const handleClick = async (newId: string, profile_id: string) => {
    setChatId(newId);
    setProfileId(profile_id);

    onOpen();
  };

  const handleTabsChange = (index: number) => {
    console.log("index: ", index)
    setTabIndex(index);
  };

  const switchTabs = (index: number) => {
    setTypeOfChats(index);
  }

  return (
    <Box backgroundColor="white.UC" p={0} m={0} marginBottom={0}>
      <Tabs
        variant="unstyled"
        p={0}
        index={tabIndex}
        onChange={handleTabsChange}
        marginBottom={0}
      >
        <ChatListView handleClick={handleClick} handleTabChange={switchTabs}/>
        <Drawer
          onClose={handleClose}
          isOpen={isOpen}
          size={"full"}
          isFullHeight={true}
          id={"chat" + chatId}
        >
          <DrawerOverlay bg={"white"} />
          <DrawerContent>
            <ChatRoomHeader onClose={handleClose} chatId={chatId} profileId={profile_id} />
            <TabPanels>
              <TabPanel p={0}>
                <ConversationViewer chatId={chatId} profileId={profile_id} />
              </TabPanel>
              <TabPanel p={0}>
                <ProfileViewer chatId={chatId} profileId={profile_id} />
              </TabPanel>
              <TabPanel p={0}>
                <ChatSettingViewer chatId={chatId} profileId={profile_id} />
              </TabPanel>
            </TabPanels>
          </DrawerContent>
        </Drawer>
      </Tabs>
    </Box>
  );
};

export default ChatRoomView;
