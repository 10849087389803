import React, { FC } from "react";
import { Flex, Box, Avatar, Stack, Text } from "@chakra-ui/react";
import { StatusHeader } from "Components";
import { useNavigate } from "react-router-dom";

type Props = {};

const WelcomePage: FC<Props> = () => {
  const navigate = useNavigate()
  return (
    <Box >
      <div>
        <Stack backgroundColor={"black"} p={"3rem 1rem 3rem 1rem"}>
          <Box textAlign={"center"} mb={"3rem"}>
            <Text variant={"soft"} fontSize={"1.8rem"} color={"white"} pb={"0.5rem"}>Multiple Profiles</Text>
            <Text variant={"soft"} fontSize={"1.2rem"} color={"green.UC"}>Keep your different social circles separate</Text>
          </Box>
          <Box>
            <img
              src="/asset/multiple_profiles_img.png"
              alt="Multiple Profiles"
              style={{
                margin: "auto",
              }}
            />
          </Box>
        </Stack >
      </div>

      <div>
        <Stack backgroundColor={"green.UC"} p={"3rem 1rem 3rem 1rem"}>
          <Box textAlign={"center"} mb={"2rem"}>
            <Text variant={"soft"} fontSize={"1.8rem"} color={"white"} pb={"0.5rem"}>Nearby Events with Group</Text>
            <Text variant={"soft"} fontSize={"1.2rem"} color={"purple.UC"}>So you never miss out on the fun</Text>
          </Box>
          <Box>
            <img
              src="/asset/events_nearby_img.png"
              alt="Multiple Profiles"
              style={{
                margin: "auto",
              }}
            />
          </Box>
        </Stack >
      </div>

      <div>
        <Stack backgroundColor={"purple.UC"} p={"3rem 1rem 3rem 1rem"}>
          <Box textAlign={"center"} mb={"2rem"}>
            <Text variant={"soft"} fontSize={"1.8rem"} color={"white"} pb={"0.5rem"}>Sign in to specific locations</Text>
            <Text variant={"soft"} fontSize={"1.2rem"} color={"black"}>To socialize and expect friends' coming</Text>
          </Box>
          <Box>
            <img
              src="/asset/location_chats_img.png"
              alt="Multiple Profiles"
              style={{
                margin: "auto",
              }}
            />
          </Box>
        </Stack >
      </div>

      <div>
        <Stack backgroundColor={"white.UC"} p={"3rem 1rem 3rem 1rem"}>
          <Box textAlign={"center"} mb={"2rem"}>
            <Text variant={"soft"} fontSize={"1.8rem"} color={"black"} pb={"0.5rem"}>Sign up with a profile now</Text>
            <Text variant={"soft"} fontSize={"1.2rem"} color={"grey"}>To live in the moment & safely connect with your surroundings</Text>
          </Box>
          <Box>
            <img
              src="/asset/with_all_img.png"
              alt="Multiple Profiles"
              style={{
                margin: "auto",
              }}
            />
          </Box>
        </Stack >
      </div>
    </Box>
  );
};

export default WelcomePage;
