import React, { FC, Dispatch, SetStateAction, useState, useEffect } from "react";

import {
    Box,
    Text,
    Flex,
    Avatar,
    Heading,
    IconButton,
    Button,
    AvatarGroup,
    useDisclosure,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    useToast,
} from "@chakra-ui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLocationDot
} from '@fortawesome/free-solid-svg-icons'

import { useRecoilState } from "recoil";
import { supabase } from "Services/supabase";

import { roomProfilesState, myLocationState } from "recoilStore/store";

import { SelectMyProfile } from "Components";

type Props = {
    location: any;
    handleTopLeftButtonClick?: () => Promise<void>;
    handleJoinClick?: () => Promise<void>;
    handleSignOut?: () => Promise<void>;
    myProfileId?: string;
    newMsg?: number;
    setProfileId: Dispatch<SetStateAction<string>>;
    refreshCount?: number;
};

type LocationType = {
    chat_id: string;
    dist_meters: number;
    location_geo: string;
    location_name: string;
    place_id: string;
}

type RoomProfileType = {
    profile_id: string,
    avatar: string,
    name_on_profile: string,
    description: string,
    background_color: string,
    text_identifier: string,
    num_identifier: string
}

const LocationCard: FC<Props> = ({ location, handleTopLeftButtonClick, handleJoinClick, handleSignOut, myProfileId, setProfileId, refreshCount }: Props) => {
    const [chatId, setChatId] = React.useState("");
    const [roomProfiles, setRoomProfiles] = useRecoilState(roomProfilesState);
    const [myLocation, setMyLocation] = useRecoilState(myLocationState);
    const [isMyLocation, setIsMyLocation] = useState<boolean>(false);

    const toast = useToast();

    // const [rooms, setRooms] = useRecoilState(roomsState);
    // const [signedInLoc, setSignedInLoc] = useState<string[]>([]);
    // const [locCreator, setLocCreator] = useState<string>("");

    const ht = window.innerHeight;

    const handleClick = async () => {
        if (!myProfileId) {
            toast({
                title: "Please select a profile to sign in",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        handleTopLeftButtonClick && await handleTopLeftButtonClick();
    }

    useEffect(() => {
        // // check if user is signed in at this location
        // let newLocList = [...signedInLoc];
        // rooms.map((room) => {
        //     if (room.room_id == location.chat_id && newLocList.indexOf(room.room_id) == -1) {
        //         newLocList.push(room.room_id);
        //     }  
        // })
        // console.log("refreshing profiles in locations chat", refreshCount)

        // check if user is signed in at this location
        const locChatIds = myLocation.map((loc) => loc.chat_id);

        if (locChatIds.indexOf(location.chat_id) > -1) {
            setIsMyLocation(true);
        } else {
            setIsMyLocation(false);
        }

        getProfiles();
    }, [location.chat_id, refreshCount, myLocation])

    const getProfiles = async () => {
        const { data, error } = await supabase.rpc("get_users_in_location", {
            p_place_id: location.place_id,
        });

        if (error) {
            console.log(error);
            return;
        }

        const roomProfiles: RoomProfileType[] = data;

        setRoomProfiles((prevRoomProfiles) => {
            return {
                ...prevRoomProfiles,
                [location.chat_id]: roomProfiles  // Updating profiles for the current chatId
            };
        })
    }

    return (
        <Box
            width={"100%"}
            height={"100%"}
            // display={"flex"}
            justifyContent={"center"}
        >
            <Card
                width={"100%"}
                backgroundColor={"green.UC"}
                color={"white.UC"}
            >
                <CardHeader
                    borderBottom={"1px white solid"}
                >
                    <Flex>
                        <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                            <Box>
                                <Heading size='md'>
                                    <Text variant={"soft"} fontSize={"1rem"}>{location.location_name}</Text>
                                </Heading>
                                {
                                    location.dis_meter ? <Text
                                        marginTop={"1rem"}
                                        color={"grey.UC"}
                                    >
                                        <FontAwesomeIcon icon={faLocationDot} style={{ marginRight: "0.5rem" }} />
                                        {"Less than " + Math.ceil(location.dist_meters) + " meters away"}
                                    </Text> : <></>
                                }
                            </Box>
                        </Flex>
                    </Flex>
                </CardHeader>
                <CardBody>
                    <Text>Users currently signed-in at this location</Text>
                    <AvatarGroup
                        size='lg'
                        max={3}
                        mt={"1rem"}
                        color={"purple.UC"}
                    >
                        {roomProfiles[location.chat_id] && roomProfiles[location.chat_id].map((profile, index) => {
                            return (
                                <Avatar
                                    key={index}
                                    name={profile.name_on_profile}
                                    src={"https://zzwxyobwfvjshssazycj.supabase.co/storage/v1/object/public/avatars/" + profile.avatar}
                                    color={"purple.UC"}
                                    backgroundColor={"white.UC"}
                                    fontWeight={"bold"}
                                />
                            );
                        })}
                    </AvatarGroup>
                    {
                        isMyLocation ? <></> : <Box
                            mt={"1rem"}
                            color={"black"}
                        >
                            <Text color={"white"}>
                                Choose a profile to sign into this location
                            </Text>
                            <SelectMyProfile
                                setMyProfileId={setProfileId}
                                myProfileId={myProfileId || ""} />
                        </Box>
                    }
                </CardBody>
                <CardFooter
                    borderTop={"1px white solid"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    padding={"0.5rem"}
                >
                    {
                        isMyLocation ?
                            <>
                                <Button width="50%" color={"red"} onClick={handleSignOut} height={"3rem"} backgroundColor={"green.UC"} borderRight={"1px white solid"} borderRadius={0}>
                                    Sign Out
                                </Button>
                                <Button width="50%" height={"3rem"} backgroundColor={"green.UC"} onClick={handleJoinClick} color={"white"}>
                                    Enter
                                </Button>
                            </>
                            :
                            <Button
                                width={"100%"}
                                height={"3rem"}
                                backgroundColor={"green.UC"}
                                color={"white"}
                                onClick={handleClick}
                            >
                                Sign In
                            </Button>
                    }
                </CardFooter>
            </Card>
        </Box >
    )
}

export default LocationCard;