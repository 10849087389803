import { Box, Text } from '@chakra-ui/react';

const TermsOfUse = () => {
  return (
    <Box p={3} paddingTop={0} height={"60vh"} overflowY={"scroll"}>
      <Text mt={4}>Last Updated: September 29, 2023</Text>

      <Text mt={4} fontWeight="semibold">Welcome to UniConnect</Text>
      <Text mt={2}>Thank you for using UniConnect. These Terms of Use ("Terms") govern your access to and use of UniConnect's services, including our mobile applications, websites, and any related services (collectively, the "Services"). By accessing or using the Services, you agree to comply with these Terms.</Text>

      <Text mt={4} fontWeight="semibold">1. Acceptance of Terms</Text>
      <Text mt={2}>Your use of the Services constitutes your agreement to these Terms. If you do not agree to these Terms, please do not use the Services.</Text>

      <Text mt={4} fontWeight="semibold">2. Modifications</Text>
      <Text mt={2}>UniConnect reserves the right to modify these Terms at any time. We will make an effort to inform users of any changes, but it is ultimately your responsibility to review these Terms regularly.</Text>

      <Text mt={4} fontWeight="semibold">3. Registration and Account Security</Text>
      <Text mt={2}>To use the Services, you may be required to sign up for an account. You agree to:</Text>
      <Text ml={4}>- Provide accurate, current, and complete information.</Text>
      <Text ml={4}>- Safeguard your username and password.</Text>
      <Text ml={4}>- Not authorize others to use your account.</Text>
      <Text ml={4}>- Notify us immediately of any unauthorized use.</Text>

      <Text mt={4} fontWeight="semibold">4. Privacy Policy</Text>
      <Text mt={2}>Your use of the Services is also governed by our Privacy Policy.</Text>

      <Text mt={4} fontWeight="semibold">5. User Content</Text>
      <Text mt={2}>By submitting or posting content to the Services, you grant UniConnect a non-exclusive, transferable, sub-licensable, royalty-free, global license to use, modify, distribute, and create derivative works based on your content.</Text>

      <Text mt={4} fontWeight="semibold">6. Prohibited Activities</Text>
      <Text mt={2}>You agree not to engage in any of the following:</Text>
      <Text ml={4}>- Submitting unlawful, harmful, or abusive content.</Text>
      <Text ml={4}>- Harassing or stalking any user.</Text>
      <Text ml={4}>- Using the Services in any way that violates any laws or regulations.</Text>

      <Text mt={4} fontWeight="semibold">7. Disclaimers</Text>
      <Text mt={2}>The Services are provided "as is" and "as available" without any warranties of any kind, either express or implied.</Text>

      <Text mt={4} fontWeight="semibold">8. Limitation of Liability</Text>
      <Text mt={2}>UniConnect shall not be liable for any indirect, incidental, or consequential damages resulting from your use of the Services.</Text>

      <Text mt={4} fontWeight="semibold">9. Termination</Text>
      <Text mt={2}>We reserve the right to suspend or terminate your account and your access to the Services at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users of the Services, UniConnect, or third parties, or for any other reason.</Text>

      <Text mt={4} fontWeight="semibold">10. Governing Law</Text>
      <Text mt={2}>These Terms shall be governed by the laws of the Province of Ontario, Canada, without regard to its conflict of law principles.</Text>

      <Text mt={4} fontWeight="semibold">11. Contact Information</Text>
      <Text mt={2}>For any questions about these Terms, please contact us at "contact@uniconnect.ca".</Text>
    </Box>
  );
};

export default TermsOfUse;
