import {
  Box,
  Button,
  Flex,
  Icon,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Select,
  Textarea,
  Stack,
  IconButton,
  FormControl,
  useToast,
} from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import React, { FC, useEffect, useState } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { supabase } from "Services/supabase";
import { FullProfile } from "Components";
import { EventsListView } from "Views";
import { useRecoilState } from "recoil";
import { tmpAvatarState, roomsState, roomProfileMappingState, profileEventMappingState, myGroupState } from "recoilStore/store";
import SelectMyProfile from "Components/SelectMyProfile";
import GroupCard from "Components/GroupCard";

type Props = {};

type ProfileType = {
  id: string
  avatar: string
  description: string
  name_on_profile: string
  background_color: string
  text_identifier: string
  num_identifier: string
};

type Profiles = {
  avatar: string;
  created_at: string;
  description: string;
  id: string;
  name_on_profile: string;
  owner: string;
  profile_name: string;
  background_color: string;
  text_identifier: string;
  num_identifier: string;
};

type GroupType = {
  room_id: string;
  created_at: string;
  room_name: string;
  room_description: string;
  num_of_members: number;
  error_message: string;
}

type TmpUserMapping = {
  [tmpProfileId: string]: string;
};

type RoomProfileMapping = {
  [room_id: string]: string; // room_id maps to profile_id
};

type Room = {
  room_id: string;
  room_name: string;
  room_description: string;
  is_muted: boolean;
  profile_id: string;
  last_message_time: string;
  unread_messages: number;
};

type EventType = {
  id: string
  event_name: string
  created_at: string
  event_discription: string
  start_time: string
  end_time: string
  chat_id: string
  place_id: string
  location_name: string
}

type ProfileEventType = {
  profile_id: string;
  event_id: string;
  chat_id: string;
  place_id: string;
  event_name: string;
  start_time: string;
  end_time: string;
  event_discription: string;
  create_time: string;
  is_muted: boolean;
  location_name: string;
};

const SearchButton: FC<Props> = (props: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchText, setSearchText] = useState("");
  const [profileSearchResults, setprofileSearchResults] = useState<Profiles[] | null>(null);
  const [groupSearchResults, setGroupSearchResults] = useState<GroupType[] | null>(null);
  const [tmpAvatar, setTmpAvatar] = useRecoilState(tmpAvatarState);
  const [rooms, setRooms] = useRecoilState(roomsState);
  const [roomProfileMapping, setRoomProfileMapping] = useRecoilState(roomProfileMappingState);
  const [searchType, setSearchType] = useState<"profile" | "group">("profile");
  const [eventSearchResults, setEventSearchResults] = useState<EventType[] | null>(null);
  const [profileEventMapping, setProfileEventMappingState] = useRecoilState(profileEventMappingState);

  const [myGroup, setMyGroup] = useRecoilState(myGroupState);

  const [myProfileId, setMyProfileId] = useState<string>("");
  const [isSearched, setIsSearched] = useState<boolean>(false);

  const toast = useToast();

  const searchEvent = async () => {
    const { data, error } = await supabase.rpc("search_events", { search_text: searchText });

    if (error) {
      console.error("Error searching events:", error);
      return;
    }

    const results = data as EventType[];

    // console.log("results", results)

    if (results.length > 0) {
      setIsSearched(true)
    }

    setEventSearchResults(results);
  }

  const searchProfile = async () => {
    const { data, error } = await supabase.rpc("search_profiles", { input_identifier: searchText });

    if (error) {
      console.error("Error searching profiles:", error);
      return;
    }

    const results = data as ProfileType[];

    if (results.length > 0) {
      setIsSearched(true)
    }

    const mapping: TmpUserMapping = { ...tmpAvatar }
    mapping[data[0].id] = data[0].avatar;

    if (JSON.stringify(mapping) !== JSON.stringify(tmpAvatar)) {
      setTmpAvatar(mapping);
    }

    // cast results to Profiles type
    const profile = results.map((profile) => {
      const profileData: Profiles = {
        avatar: profile.avatar,
        created_at: "",
        description: profile.description,
        id: profile.id,
        name_on_profile: profile.name_on_profile,
        owner: "",
        profile_name: "",
        background_color: profile.background_color,
        text_identifier: profile.text_identifier,
        num_identifier: profile.num_identifier,
      };

      return profileData;
    });

    setprofileSearchResults(profile);
  };

  const searchGroup = async () => {
    const { data, error } = await supabase.rpc("search_group", { p_room_id: searchText });

    if (error) {
      console.error("Error searching group:", error);
      return;
    }

    // console.log("data", data)

    const results = data as GroupType[];

    if (results.length > 0) {
      setIsSearched(true)
    }

    const group = results.map((group) => {
      const groupData: GroupType = {
        room_id: group.room_id,
        created_at: group.created_at,
        room_name: group.room_name,
        room_description: group.room_description,
        num_of_members: group.num_of_members,
        error_message: group.error_message,
      };

      return groupData;
    });

    setGroupSearchResults(group);
  }

  const handleSearch = () => {
    if (searchText.length <= 0) {
      console.error("Search text is empty");
      return;
    }

    // console.log("searchText", searchText.length)

    searchGroup();
    searchProfile();
    searchEvent();
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    setprofileSearchResults(null);
    setGroupSearchResults(null);
    setEventSearchResults(null);
    setIsSearched(false);
  }

  const addFriend = async () => {
    if (!profileSearchResults || profileSearchResults.length <= 0) {
      console.error("No search results");
      return;
    }

    if (!myProfileId) {
      toast({
        position: "top",
        title: "Please select a profile to add",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const { data, error } = await supabase.rpc('add_friend', { initiator_id: myProfileId, receiver_id: profileSearchResults[0].id });

    if (error) {
      toast({
        position: "top",
        title: "Error adding friend",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    } else {
      toast({
        position: "top",
        title: "Your request has been sent",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // clear state
      setprofileSearchResults(null);
      setGroupSearchResults(null);
      setEventSearchResults(null);
      setSearchText("");
      setMyProfileId("");
      setIsSearched(false);
      onClose();
    }
  }

  const addEvent = async (event_room_id: string, event_my_profile: string) => {
    if (!eventSearchResults) {
      console.error("No search results");
      return;
    }

    const thisevent = eventSearchResults.find((event) => event.chat_id === event_room_id);

    if (!thisevent) {
      console.error("No event found");
      return;
    }

    if (!eventSearchResults || eventSearchResults.length <= 0) {
      console.error("No search results");
      return;
    }

    if (!myProfileId) {
      toast({
        position: "top",
        title: "Please select a profile to add",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const { data, error } = await supabase.rpc('join_group_chat', {
      p_room_id: event_room_id,
      p_profile_id: event_my_profile
    });

    if (error) {
      toast({
        position: "top",
        title: "Error joining event",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    } else {
      toast({
        position: "top",
        title: "You have joined the event chat",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // update rooms
      const room: Room = {
        room_id: event_room_id,
        room_name: thisevent.event_name,
        room_description: thisevent.event_discription,
        is_muted: false,
        profile_id: event_my_profile,
        last_message_time: "",
        unread_messages: 0,
      };

      setRooms((prevRooms) => {
        return [...prevRooms, room];
      });

      // update roomProfileMapping
      setRoomProfileMapping((prevRoomProfileMapping) => {
        return {
          ...prevRoomProfileMapping,
          [event_room_id]: myProfileId,
        };
      });

      // update profileEventMapping
      const mapping = JSON.parse(JSON.stringify(profileEventMapping));

      const profileEvent: ProfileEventType = {
        profile_id: myProfileId,
        event_id: thisevent.id,
        chat_id: event_room_id,
        place_id: thisevent.place_id,
        event_name: thisevent.event_name,
        start_time: thisevent.start_time,
        end_time: thisevent.end_time,
        event_discription: thisevent.event_discription,
        create_time: thisevent.created_at,
        is_muted: false,
        location_name: thisevent.location_name,
      };

      if (profileEventMapping[myProfileId]) {
        setProfileEventMappingState({
          ...profileEventMapping,
          [myProfileId]: [...profileEventMapping[myProfileId], profileEvent]
        });
      } else {
        setProfileEventMappingState({
          ...profileEventMapping,
          [myProfileId]: [profileEvent]
        });
      }

      setProfileEventMappingState(mapping);

      // clear state
      setprofileSearchResults(null);
      setGroupSearchResults(null);
      setEventSearchResults(null);
      setSearchText("");
      setMyProfileId("");
      setIsSearched(false);
      onClose();
    }
  }

  const addGroup = async () => {
    if (!groupSearchResults || groupSearchResults.length <= 0) {
      console.error("No search results");
      return;
    }

    if (!myProfileId) {
      toast({
        position: "top",
        title: "Please select a profile to add",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const { data, error } = await supabase.rpc('join_group_chat', { p_room_id: groupSearchResults[0].room_id, p_profile_id: myProfileId });

    if (error) {
      toast({
        position: "top",
        title: "Error joining group",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    } else {
      toast({
        position: "top",
        title: "You have joined the group",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // update rooms
      const room: Room = {
        room_id: groupSearchResults[0].room_id,
        room_name: groupSearchResults[0].room_name,
        room_description: groupSearchResults[0].room_description,
        is_muted: false,
        profile_id: myProfileId,
        last_message_time: "",
        unread_messages: 0,
      };

      setRooms((prevRooms) => {
        return [...prevRooms, room];
      });

      // update roomProfileMapping
      setRoomProfileMapping((prevRoomProfileMapping) => {
        return {
          ...prevRoomProfileMapping,
          [groupSearchResults[0].room_id]: myProfileId,
        };
      });

      // clear state
      setprofileSearchResults(null);
      setGroupSearchResults(null);
      setEventSearchResults(null);
      setSearchText("");
      setMyProfileId("");
      setIsSearched(false);
      onClose();
    }
  }

  return (
    <Box as={Flex} >
      <Button bgColor={"transparent"} onClick={onOpen}>
        <Icon as={FontAwesomeIcon} icon={faSearch} />
      </Button>

      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={() => {
          // clear state
          setprofileSearchResults(null);
          setGroupSearchResults(null);
          setEventSearchResults(null);
          setSearchText("");
          setMyProfileId("");
          setIsSearched(false);
          onClose()
        }}
        size={"full"}
        isFullHeight={true}
      >
        <DrawerOverlay bg={"white"} />
        <DrawerContent>
          <DrawerHeader borderBottomWidth='1px' display={"flex"} paddingLeft={"0.5rem"}>
            <IconButton
              aria-label="Back to chatlist"
              icon={<ChevronLeftIcon boxSize={8} />}
              onClick={() => {
                // clear state
                setprofileSearchResults(null);
                setGroupSearchResults(null);
                setEventSearchResults(null);
                setSearchText("");
                setMyProfileId("");
                onClose()
              }}
              backgroundColor={"white"}
            />
            <InputGroup size="md">
              <Input
                placeholder="Search"
                value={searchText}
                onChange={(e) => handleOnChange(e)}
              />
              <InputRightAddon children={<Icon as={FontAwesomeIcon} icon={faSearch} />} onClick={handleSearch} />
            </InputGroup>
          </DrawerHeader>

          <DrawerBody>
            <Box
              zIndex={"10"}
              position={"relative"}
            >
              <FormControl isRequired>
                <FormLabel>Select a profile to add</FormLabel>
                <SelectMyProfile
                  setMyProfileId={setMyProfileId}
                  myProfileId={myProfileId} />
              </FormControl>
            </Box>
            <Box
              position={"relative"}
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              h={"85vh"}
              margin={"auto"}

            >
              {
                profileSearchResults && profileSearchResults.length > 0 ? <FullProfile
                  profileData={profileSearchResults[0]}
                  handleTopLeftButtonClick={addFriend}
                  myProfileId={myProfileId}
                /> : <></>
              }
              {
                groupSearchResults && groupSearchResults.length > 0 ? <GroupCard
                  groupData={groupSearchResults[0]}
                  handleTopLeftButtonClick={addGroup}
                  myProfileId={myProfileId}
                /> : <></>
              }
              {
                eventSearchResults && eventSearchResults.length > 0 ? <Box
                  width={"100%"}
                  height={"100%"}
                  // display={"flex"}
                  justifyContent={"center"}
                  paddingTop={"2rem"}
                  paddingBottom={"2rem"}
                >
                  <EventsListView
                    events={eventSearchResults}
                    addToEvent={addEvent}
                    myProfileId={myProfileId}
                  />
                </Box> : <></>
              }
              {
                !isSearched ? <Box
                  width={"100%"}
                  textAlign={"center"}
                >
                  <p>No results found.</p>
                  <p>Profiles need to be an exact match.</p>
                  <p>The best way to find people is to join events and locations!</p>
                </Box> : <></>
              }
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default SearchButton;
