import { Box, Flex, useToast } from "@chakra-ui/react";
import { Menu, TopBar } from "Components";
import React, { FC, ReactNode, useState, useEffect } from "react";
import { supabase } from "Services/supabase";
import { useNavigate } from "react-router-dom";
import { User } from "@supabase/supabase-js";
import { useRecoilState } from "recoil";
import { FirstTimePage } from "Pages";
import {
    userIDState,
    userID,
    profilesIDs,
    profilesState,
    friendsState,
    roomProfileMappingState,
    roomsState,
    notFriendsState,
    userEmail,
    profileEventMappingState,
    newMSgIdState,
    myLocationState,
    userNameState,
    myGroupState,
    msgNotificationNum,
    friendNotificationNum,
    profileLocation,
    unread_messages,
} from "recoilStore/store";
import { pushNotification } from "Utils/UCNotificationHelper";
import OneSignal from "react-onesignal";
import {AttendanceModal} from "Components";

type Props = {
    children: ReactNode;
};

type Profile = {
    avatar: string;
    created_at: string;
    description: string;
    id: string;
    name_on_profile: string;
    owner: string;
    profile_name: string;
    background_color: string;
    text_identifier: string;
    num_identifier: string;
};

type FriendType = {
    profile_id: string;
    friend_profile_id: string;
    status: string;
    friend_avatar: string;
    friend_name_on_profile: string;
    friend_description: string;
    friend_background_color: string;
    friend_text_identifier: string;
    friend_num_identifier: string;
    room_id: string;
};

type NotFriendType = {
    profile_id: string;
    friend_profile_id: string;
    status: string;
    friend_avatar: string;
    friend_name_on_profile: string;
    friend_description: string;
    friend_background_color: string;
    friend_text_identifier: string;
    friend_num_identifier: string;
    room_id: string;
    initiator: string;
};

interface PayloadNew {
    content: string;
    created_at: string;
    id: string;
    initiator_profile_id: string;
    profile_id: string;
    room_id: string;
    type: string;
    updated_at: string;
    status: string;
}

type unread_messagesType = {
    friends_unread: number;
    groups_unread: number;
    events_unread: number;
    location_unread: number;
}

const AppLayout: FC<Props> = ({ children }: Props) => {
    const [authUser, setAuthUser] = useState<User>();
    const [userIDA, setUserIDA] = useRecoilState(userIDState);
    const [cUser, setCUser] = useRecoilState(userID);
    const [profile_ids, setprofile_ids] = useRecoilState(profilesIDs);
    const [profiles, setProfiles] = useRecoilState(profilesState);
    const [recoilFriends, setRecoilFriends] = useRecoilState(friendsState);
    const [roomProfileMapping, setRoomProfileMapping] = useRecoilState(
        roomProfileMappingState
    );
    const [rooms, setRooms] = useRecoilState(roomsState);
    const [notFriends, setNotFriends] = useRecoilState(notFriendsState);
    const [userEmailA, setUserEmailA] = useRecoilState(userEmail);
    const [profileEventMapping, setProfileEventMapping] = useRecoilState(
        profileEventMappingState
    );
    const [newMsgId, setNewMsgId] = useRecoilState(newMSgIdState);

    const [myLocation, setMyLocation] = useRecoilState(myLocationState);

    const [myGroup, setMyGroup] = useRecoilState(myGroupState);

    const [unread_messagesState, setUnread_messagesState] = useRecoilState(unread_messages);

    const [notificationsRes, setNotificationsRes] =
        useState<ServiceWorkerRegistration>();

    const [msgNotification, setMsgNotification] =
        useRecoilState(msgNotificationNum);
    const [friendNotification, setFriendNotification] = useRecoilState(
        friendNotificationNum
    );

    const [profileLocationState, setProfileLocationState] =
        useRecoilState(profileLocation);
    const [userName, setUserName] = useRecoilState(userNameState);

    const [events_now, setEvents_now] = useState<any[]>([]); // list of event happening now
    const [shouldShowAttendanceModal, setShouldShowAttendanceModal] = useState<boolean>(false); // list of event happening now

    const navigate = useNavigate();

    const toast = useToast();

    useEffect(() => {
        supabase.auth
            .getSession()
            .then((res) => {
                const session = res?.data?.session;
                const user = session?.user;

                // console.log(user, authUser)
                if (user && !authUser) {
                    setUserIDA(user.id);
                    setCUser(user.id);
                    setUserEmailA(user.email as string);

                    setAuthUser(user);
                } else if (!user && !authUser) {
                    setAuthUser(undefined);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [navigate]);

    const updatedProfiles = (profiles: Array<any>) => {
        return profiles
            .map((profile) => {
                if (profile.avatar !== null) {
                    const avatarURL = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/avatars/${profile.avatar}`;
                    return {
                        ...profile,
                        avatar: avatarURL,
                    };
                }
                return null;
            })
            .filter(Boolean) as Profile[];
    };

    const getProfiles = (userId: string) => {
        supabase
            .from("profiles")
            .select("*")
            .eq("owner", userId)
            .then((resSQL) => {
                if (resSQL.data) {
                    console.log(resSQL.data);
                    setprofile_ids(resSQL.data.map((profile) => profile.id));
                    setProfiles(updatedProfiles(resSQL.data));
                }
            });
    };

    useEffect(() => {
        supabase.auth.getUser().then((res) => {
            if (res.data?.user) {
                const userId = res.data.user?.id;

                setCUser(userId);
                if (userId) {
                    getProfiles(userId);
                    setUserName(res.data?.user.user_metadata.name);
                }
            }
        });
    }, []);

    const fetchFriends = () => {
        if (!profile_ids) return;

        const newFriends: { [key: string]: FriendType } = {};

        for (let profile_id of profile_ids) {
            supabase
                .rpc("get_all_friends_of_profile", {
                    input_profile_id: profile_id,
                })
                .then((res) => {
                    if (res.error) return;
                    if (res.data) {
                        res.data.forEach((value) => {
                            const friend: FriendType = value as FriendType;
                            const uniqueId = `${friend.profile_id}-${friend.friend_profile_id}`;
                            newFriends[uniqueId] = friend;
                        });
                    }
                    // Convert the object to an array and set the Recoil state
                    setRecoilFriends(Object.values(newFriends));
                });
        }
    }

    const fetchNotFriends = () => {
        if (!profile_ids) return;

        const newFriends: { [key: string]: NotFriendType } = {};

        for (let profile_id of profile_ids) {
            supabase
                .rpc("get_all_r", {
                    input_profile_id: profile_id,
                })
                .then((res) => {
                    if (res.error) return;
                    if (res.data) {
                        res.data.forEach((value) => {
                            const notFriend: NotFriendType =
                                value as NotFriendType;
                            const uniqueId = `${notFriend.profile_id}-${notFriend.friend_profile_id}`;
                            newFriends[uniqueId] = notFriend;
                        });
                    }
                    // Convert the object to an array and set the Recoil state
                    setNotFriends(Object.values(newFriends));
                });
        }
    }

    const fetch_unread = () => {
        if (!profile_ids) return;

        supabase
            .rpc("fetch_unread_msgs", {
                profileids: profile_ids,
            })
            .then((res) => {
                if (res.error) {
                    console.log(res.error);
                    return;
                }

                console.log(res.data)

                if (res.data && res.data.length > 0) {
                    const unread_messages: unread_messagesType = res.data[0] as unread_messagesType;
                    setUnread_messagesState(unread_messages);
                }
            });
    }

    type Room = {
        room_id: string;
        room_name: string;
        room_description: string;
        is_muted: boolean;
        profile_id: string;
        last_message_time: string;
        unread_messages: number;
    };

    const fetchRooms = () => {
        supabase
            .rpc("fetch_rooms", {
                profile_ids: profile_ids,
            })
            .then((res) => {
                if (res.error) {
                    console.log(res.error);
                    return;
                }

                const transformedRooms: Room[] = [];
                const mapping: RoomProfileMapping = { ...roomProfileMapping };

                res.data.forEach((item: any) => {
                    transformedRooms.push({
                        room_id: item.room_id,
                        room_name: item.room_name,
                        room_description: item.room_description,
                        is_muted: item.is_muted,
                        profile_id: item.profile_id,
                        last_message_time: item.last_msg_time,
                        unread_messages: item.unread_messages,
                    });
                    mapping[item.room_id] = item.profile_id; // Map room_id to profile_id
                });

                if (
                    JSON.stringify(mapping) !==
                    JSON.stringify(roomProfileMapping)
                ) {
                    setRoomProfileMapping(mapping); // Update only if there's a change
                }

                setRooms(transformedRooms);
            });
    };

    type ProfileEventType = {
        profile_id: string;
        event_id: string;
        chat_id: string;
        place_id: string;
        event_name: string;
        start_time: string;
        end_time: string;
        event_discription: string;
        create_time: string;
        is_muted: boolean;
        location_name: string;
    };

    const fetchMyEvents = () => {
        supabase
            .rpc("get_events_by_profiles", {
                profile_ids: profile_ids,
            })
            .then((res) => {
                if (res.error) {
                    console.log(res.error);
                    return;
                }

                const mapping: { [key: string]: ProfileEventType[] } = {
                    ...profileEventMapping,
                };

                res.data.forEach((item: any) => {
                    if (!mapping[item.profile_id]) {
                        mapping[item.profile_id] = [item];
                    } else {
                        let found = false;
                        mapping[item.profile_id].forEach((event) => {
                            if (event.event_id === item.event_id) {
                                found = true;
                            }
                        });
                        if (!found) {
                            mapping[item.profile_id] = [
                                ...mapping[item.profile_id],
                                item,
                            ];
                        }
                    }
                });

                setProfileEventMapping(mapping);
            });
    };

    type LocationStateType = {
        location_id: string;
        location_name: string;
        place_id: string;
        chat_id: string;
        profile_id: string;
        sign_in: string;
    };

    const fetchMyLocations = () => {
        if (!userIDA) {
            return;
        }

        supabase
            .rpc("fetch_all_locations", {
                p_user_id: userIDA,
            })
            .then((res) => {
                if (res.error) {
                    console.log(res.error);
                    return;
                }

                // update myLocation state
                const mapping: LocationStateType[] = [...myLocation];
                res.data.forEach((item: any) => {
                    if (
                        !mapping.find(
                            (location) =>
                                location.location_id === item.location_id
                        )
                    )
                        mapping.push({
                            location_id: item.location_id,
                            location_name: item.location_name,
                            place_id: item.place_id,
                            chat_id: item.chat_id,
                            profile_id: item.profile_id,
                            sign_in: item.sign_in,
                        });
                });

                setMyLocation(mapping);
            });
    };

    type GroupType = {
        room_id: string;
        room_name: string;
        room_description: string;
        creator_id: string;
        profile_id: string;
    };

    const fetchMyGroup = () => {
        if (!profile_ids) {
            return;
        }

        supabase
            .rpc("fetch_group_chats", {
                profiles_list: profile_ids,
            })
            .then((res) => {
                if (res.error) {
                    console.log(res.error);
                    return;
                }

                const mapping: GroupType[] = [...myGroup];
                res.data.forEach((item: any) => {
                    if (
                        !mapping.find(
                            (group) =>
                                group.room_id === item.room_id &&
                                group.profile_id === item.profile_id
                        )
                    )
                        mapping.push({
                            room_id: item.room_id,
                            room_name: item.room_name,
                            room_description: item.room_description,
                            creator_id: item.creator_id,
                            profile_id: item.profile_id,
                        });
                });

                setMyGroup(mapping);
            });
    };

    const get_unread = () => {
        if (!profile_ids) return;

        supabase
            .rpc("get_aggregated_unread_for_profiles", {
                p_profile_ids: profile_ids,
            })
            .then((res) => {
                if (res.error) {
                    console.log(res.error);
                    return;
                }

                let msgNum = 0;
                let friendNum = 0;

                res.data.forEach((item: any) => {
                    msgNum += item.msg_num;
                    friendNum += item.friend_request_num;
                });

                setMsgNotification(msgNum);
                setFriendNotification(friendNum);
            });
    };

    useEffect(() => {
        fetchFriends();
        fetchNotFriends();
        fetchRooms();
        fetchMyEvents();
        fetchMyLocations();
        fetchMyGroup();
        get_unread();
        fetch_unread();
    }, [profile_ids, userID]);


    const get_locations = () => {
        if (!recoilFriends) return;

        const p_ids = recoilFriends.map((friend) => friend.friend_profile_id);

        supabase
            .rpc("get_profiles_signed_in_locations", {
                profile_ids: p_ids,
            })
            .then((res) => {
                if (res.error) {
                    console.log(res.error);
                    return;
                }

                const mapping: { [key: string]: string } = {
                    ...profileLocationState,
                };

                res.data.forEach((item: any) => {
                    mapping[item.profile_id] = item.location_name;
                });

                setProfileLocationState(mapping);
            });
    };

    type RoomProfileMapping = {
        [room_id: string]: string;
    };

    useEffect(() => {
        const newMapping: RoomProfileMapping = { ...roomProfileMapping };
        const newFriendsList = profiles.map((profile) => {
            const profileFriends = recoilFriends
                .filter((friend) => friend.profile_id === profile.id)
                .map((friend) => {
                    if (!newMapping[friend.room_id]) {
                        newMapping[friend.room_id] = profile.id;
                    }
                    return {
                        id: friend.friend_profile_id,
                        name: friend.friend_name_on_profile,
                        room_id: friend.room_id,
                        status: "active",
                        location: "Somewhere",
                    };
                });

            return {
                id: profile.id,
                name: profile.name_on_profile,
                friends: profileFriends,
            };
        });

        if (JSON.stringify(newMapping) !== JSON.stringify(roomProfileMapping)) {
            setRoomProfileMapping(newMapping);
        }

        get_locations();
    }, [recoilFriends]);

    const updateRoomTime = (roomId: string, profileID: string, newTime: string) => {
        const updatedRooms = rooms.map((room) => {
            if (room.room_id === roomId && room.profile_id === profileID) {
                return {
                    ...room,
                    unread_messages: room.unread_messages + 1,
                    last_message_time: newTime,
                };
            }
            return room;
        });

        setRooms(updatedRooms);
    };

    useEffect(() => {
        const profileIds = profile_ids;
        const queryCondition = profileIds.map((id) => `${id}`).join(", ");

        const notificationSubscription = supabase
            .channel(`notifications:profile_id=in.(${queryCondition})`)
            .on(
                "postgres_changes",
                {
                    event: "*",
                    schema: "public",
                    table: "notifications",
                    filter: `profile_id=in.(${queryCondition})`,
                },
                (payload) => {
                    const payloadNew: PayloadNew = payload.new as PayloadNew;

                    if (payloadNew.status === "unread") {
                        if (payloadNew.type === "dm" || payloadNew.type === "gm") {
                            // check if the chat room is muted
                            const roomId = payloadNew.room_id;
                            const isMuted = rooms.find(
                                (room) => room.room_id === roomId
                            )?.is_muted;

                            // update rooms state
                            updateRoomTime(roomId, payloadNew.profile_id, payloadNew.created_at);
                            setNewMsgId(roomId);

                            if (!isMuted) {
                                setMsgNotification(msgNotification + 1);

                                const isFriend = payloadNew.type === "dm";
                                const isGroup = payloadNew.type === "gm" && myGroup.find((group) => group.room_id === roomId);
                                const isLocation = payloadNew.type === "gm" && myLocation.find((location) => location.chat_id === roomId);
                                const isEvent = payloadNew.type === "gm" && profileEventMapping[payloadNew.profile_id]?.find((event) => event.chat_id === roomId);

                                let newUnreadMsg = {
                                    friends_unread: unread_messagesState.friends_unread,
                                    groups_unread: unread_messagesState.groups_unread,
                                    events_unread: unread_messagesState.events_unread,
                                    location_unread: unread_messagesState.location_unread,
                                };

                                if (isFriend) {
                                    newUnreadMsg.friends_unread += 1;

                                    toast({
                                        position: "top",
                                        title: "Friend Notification",
                                        description: payloadNew.content,
                                        status: "info",
                                        duration: 3000,
                                        isClosable: true,
                                    });
                                } else if (isGroup) {
                                    newUnreadMsg.groups_unread += 1;

                                    toast({
                                        position: "top",
                                        title: "Group Notification",
                                        description: payloadNew.content,
                                        status: "info",
                                        duration: 3000,
                                        isClosable: true,
                                    });
                                } else if (isLocation) {
                                    newUnreadMsg.location_unread += 1;

                                    toast({
                                        position: "top",
                                        title: "Location Notification",
                                        description: payloadNew.content,
                                        status: "info",
                                        duration: 3000,
                                        isClosable: true,
                                    });
                                } else if (isEvent) {
                                    newUnreadMsg.events_unread += 1;

                                    toast({
                                        position: "top",
                                        title: "Event Notification",
                                        description: payloadNew.content,
                                        status: "info",
                                        duration: 3000,
                                        isClosable: true,
                                    });
                                }

                                setUnread_messagesState(newUnreadMsg);
                            }
                        } else if (payloadNew.type === "friend_request") {
                            const newFriends: { [key: string]: NotFriendType } = {};

                            for (let profile_id of profile_ids) {
                                supabase
                                    .rpc("get_all_r", {
                                        input_profile_id: profile_id,
                                    })
                                    .then((res) => {
                                        if (res.error) return;
                                        if (res.data) {
                                            res.data.forEach((value) => {
                                                const notFriend: NotFriendType =
                                                    value as NotFriendType;
                                                const uniqueId = `${notFriend.profile_id}-${notFriend.friend_profile_id}`;
                                                newFriends[uniqueId] = notFriend;
                                            });
                                        }
                                        // Convert the object to an array and set the Recoil state
                                        setNotFriends(Object.values(newFriends));
                                    });
                            }

                            toast({
                                position: "top",
                                title: "UniConnect Notification",
                                description: payloadNew.content,
                                status: "info",
                                duration: 3000,
                                isClosable: true,
                            });

                            setFriendNotification(friendNotification + 1);
                        } else if (payloadNew.type === "friend_request_accepted") {
                            // copy all the friends from the recoil state
                            const newFriendList = [...recoilFriends];

                            // check if the friend already exits
                            const friend = newFriendList.find((friend) => {
                                return (
                                    friend.profile_id === payloadNew.profile_id && friend.friend_profile_id === payloadNew.initiator_profile_id ||
                                    friend.profile_id === payloadNew.initiator_profile_id && friend.friend_profile_id === payloadNew.profile_id
                                )
                            })

                            if (!friend) {
                                supabase
                                    .rpc("get_specific_friendship", {
                                        input_profile_id1: payloadNew.profile_id,
                                        input_profile_id2: payloadNew.initiator_profile_id
                                    })
                                    .then((res) => {
                                        if (res.error) return;
                                        if (res.data) {
                                            const friend: FriendType = res.data[0] as FriendType;
                                            newFriendList.push(friend);
                                            setRecoilFriends(newFriendList);
                                        }

                                        fetchRooms();

                                        toast({
                                            position: "top",
                                            title: "UniConnect Notification",
                                            description: payloadNew.content,
                                            status: "info",
                                            duration: 3000,
                                            isClosable: true,
                                        });
                                    })
                            }
                        } else if (payloadNew.type === "friend_request_rejected") {
                            toast({
                                position: "top",
                                title: "UniConnect Notification",
                                description: payloadNew.content,
                                status: "info",
                                duration: 3000,
                                isClosable: true,
                            });
                        }
                    }
                }
            )
            .subscribe();

        return () => {
            supabase
                .channel(`notifications:profile_id=eq.${queryCondition}`)
                .unsubscribe();
            supabase.removeChannel(notificationSubscription);
        };
    }, [profile_ids, roomProfileMapping, rooms]);

    useEffect(() => {
        console.log(profile_ids);
        const events_now: any[] = []; // list of event happening now

        // iterate through all events in profileEventMapping
        for (let profile_id in profileEventMapping) {
            const events = profileEventMapping[profile_id];

            // iterate through all events in each profile
            events.forEach((event) => {
                const start_time = new Date(event.start_time);
                const end_time = new Date(event.end_time);
                const now = new Date();

                if (start_time <= now && now <= end_time) {
                    events_now.push(event);
                }
            });
        }

        setEvents_now(events_now);

        console.log("events happening now: ")
        console.log(events_now);


        // create model for e
        setShouldShowAttendanceModal(events_now.length > 0);

    

    },[profileEventMapping]);

    const ht = window.innerHeight;

    return (
        <Flex direction={"column"} backgroundColor={"white.UC"} minH={ht}>
            {profile_ids && profile_ids.length === 0 && userIDA ? (
                <FirstTimePage userId={userIDA || ""} />
            ) : (
            <>
                <TopBar />
                <Box flex="1" backgroundColor="white.UC">
                    {children}
                </Box>
                <Menu />
                {events_now.length > 0 &&
                <AttendanceModal event={events_now[0]}  isOpen={shouldShowAttendanceModal} onClose={() => setShouldShowAttendanceModal(false)}/>}
                
            </>
           
            )}
        </Flex>
    );
};

export default AppLayout;
