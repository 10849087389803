import { Box, Grid, GridItem } from "@chakra-ui/react";
import { TimelineTile, AboutUsTile, UpdateTile, RateTile, SettingTile } from "Components";
import React, { FC } from "react";

type Props = {};

const HomeBottomView: FC<Props> = (props: Props) => {
  const dw = window.outerWidth; // dw - 12% /2 => dw * 0.44
  return (
    <Box>
      <Grid
        gap={dw * 0.04 + "px"}
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(1, 1fr)"
        // px={"4%"}
        w={"100%"}
        h={dw * 0.92 + "px"}
      >
        {/* <GridItem>
          <TimelineTile />
        </GridItem> */}
        <GridItem h={"100%"}>
          <Grid
            templateRows={"repeat(2, 1fr)"}
            templateColumns={"repeat(2, 1fr)"}
            h={"100%"}
            w={"100%"}
            gap={dw * 0.44 * 0.05 + "px"}
          >
            <GridItem>
              <AboutUsTile />
            </GridItem>
            <GridItem>
              <UpdateTile />
            </GridItem>
            <GridItem>
              <RateTile />
            </GridItem>
            <GridItem>
              <SettingTile />
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default HomeBottomView;