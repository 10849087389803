import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalContent, ModalOverlay, ModalHeader, ModalFooter, useToast, Text, Box, Button, Tabs, TabList, Tab, TabPanel, TabPanels, Tooltip, useDisclosure, IconButton } from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";
import { ChatTile, LocationCard, SelectMyProfile, EventCard, GroupTile, NewMsgMarker } from "Components";
import { supabase } from "Services/supabase";
import { useRecoilState, useResetRecoilState } from "recoil";
import { profilesIDs, unread_messages, userID } from "recoilStore/store";
import { myLocationState, friendsState, roomsState, newMSgIdState, profileEventMappingState, myGroupState } from "recoilStore/store";

type Props = {
  handleClick: Function;
  handleTabChange: Function;
};

type Room = {
  room_id: string;
  room_name: string;
  is_muted: boolean;
  last_message_time: string;
  unread_messages: number;
  profile_id: string;
};

type ProfileEventType = {
  profile_id: string
  event_id: string
  chat_id: string
  place_id: string
  event_name: string
  start_time: string
  end_time: string
  event_discription: string
  create_time: string
  is_muted: boolean
}

type RoomProfileMapping = {
  [room_id: string]: string; // room_id maps to profile_id
};

type unread_messagesType = {
  friends_unread: number;
  groups_unread: number;
  events_unread: number;
  location_unread: number;
}

const ChatListView = ({ handleClick, handleTabChange }: Props) => {
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [rooms, setRooms] = useRecoilState(roomsState);
  const [newMsgId, setNewMsgId] = useRecoilState(newMSgIdState);
  const [friends, setFriends] = useRecoilState(friendsState);

  const [sortedRooms, setSortedRooms] = useState<Room[]>([]);
  const [profileEventMapping, setProfileEventMappingState] = useRecoilState(profileEventMappingState);

  const [friendChats, setFriendChats] = useState<string[]>([]);
  const [groupChats, setGroupChats] = useState<string[]>([]);
  const [eventChats, setEventChats] = useState<string[]>([]);
  const [locationChats, setLocationChats] = useState<string[]>([]);

  const [location, setLocation] = useState<{ latitude: number, longitude: number } | null>(null);
  const [nearLocations, setnearLocations] = useState<Array<any>>([]);

  const [profileId, setProfileId] = useState<string>("");
  const [userIDState, setUserIDState] = useRecoilState(userID);

  const [joinMsg, setJoinMsg] = useState<string>("");
  const [refreshCount, setRefreshCount] = useState<number>(0);

  const [myLocation, setMyLocation] = useRecoilState(myLocationState);
  const [myGroup, setMyGroup] = useRecoilState(myGroupState);

  const [unread_messagesState, setUnread_messagesState] = useRecoilState(unread_messages);

  useEffect(() => {
    if ("geolocation" in navigator || location == null) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setLocation({ latitude, longitude });
      }, (error) => {
        console.log("Error getting geolocation: ", error);
      });
    } else {
      toast({
        title: "Error",
        description: "Geolocation is not available in this browser, or please go to settings to allow location access",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }, []);


  useEffect(() => {
    const newSortedRooms = [...rooms] as any;

    newSortedRooms.sort((a: any, b: any) => {
      return new Date(b.last_message_time).getTime() - new Date(a.last_message_time).getTime();
    });

    setSortedRooms(newSortedRooms);
  }, [rooms, newMsgId]);

  const getNearbyLocations = async () => {
    if (!location) return;

    const { latitude, longitude } = location;

    const { data, error } = await supabase.rpc("fetch_locations", {
      long: latitude,
      lat: longitude,
      radius: 500,
    });

    if (error) {
      toast({
        title: "Error",
        description: "Error getting nearby locations",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }

    if (!data) return;

    if (data.length === 0) {
      toast({
        title: "No registered locations found",
        description: "No locations found near you",
        status: "info",
        duration: 2000,
        isClosable: true,
      });
    }

    setnearLocations(data as any);

    if (!data) return;
    setLocationChats(data.map((location: any) => location.chat_id));

    setRefreshCount(refreshCount + 1);
  }

  useEffect(() => {
    const newFriendChats = friends.map(friend => friend.room_id);
    const newEventChats = Object.values(profileEventMapping).flat().map(event => event.chat_id);
    // const newLocationChats = nearLocations.map((location: any) => {if (location.chat_id === myLocation?.chat_id) return location.chat_id});
    const newGroupChats = myGroup.map(group => group.room_id);

    setFriendChats(newFriendChats);
    setEventChats(newEventChats);
    setGroupChats(newGroupChats);

  }, [sortedRooms, friends, profileEventMapping, locationChats, rooms, myLocationState, unread_messagesState]);

  const generateFriendTiles = () => {
    const friendChatsList = sortedRooms.filter((room) => friendChats.includes(room.room_id));

    return friendChatsList.map((chat) => {
      return <Button
        key={chat.room_id}
        width="100%"
        h={"80px"}
        p={"0"}
        m={"0"}
        variant={"unstyled"}
        textAlign={"left"}
        onClick={() => handleClick(chat.room_id, chat.profile_id)}
        backgroundColor={"white"}
      >
        <ChatTile
          id={chat.room_id}
          name={chat.room_name}
          // other props
          numberOfNewMsg={chat.unread_messages}
          lastMsgTime={chat.last_message_time}
        />
      </Button>
    });
  }

  const generateEventTiles = () => {
    const eventTiles: JSX.Element[] = [];

    const newProfileEvent = Object.values(profileEventMapping).flat();

    sortedRooms.map((room) => {
      newProfileEvent.map((event) => {
        if (event.chat_id === room.room_id && event.profile_id === room.profile_id) {
          eventTiles.push(
            <>
              <Box marginBottom={"0.5rem"}>
                <EventCard
                  event={event}
                  myProfileId={event.profile_id}
                  handleTopLeftButtonClick={() => handleClick(event.chat_id, event.profile_id)}
                  newMsg={room.unread_messages}
                  isChatList={true}
                />
              </Box>
            </>
          )
        }
      })
    })

    return eventTiles;
  }

  const generateGroupTiles = () => {
    const groupTilesJSX: JSX.Element[] = [];

    const groupChatsList = sortedRooms.filter((room) => groupChats.includes(room.room_id));

    for (const chat in groupChatsList) {
      groupTilesJSX.push(
        <Button
          key={groupChatsList[chat].room_id}
          width="100%"
          h={"80px"}
          p={"0"}
          m={"0"}
          variant={"unstyled"}
          textAlign={"left"}
          onClick={() => handleClick(groupChatsList[chat].room_id, groupChatsList[chat].profile_id)}
          backgroundColor={"white"}
        >
          <GroupTile
            id={groupChatsList[chat].room_id}
            name={groupChatsList[chat].room_name}
            numberOfNewMsg={groupChatsList[chat].unread_messages}
            lastMsgTime={groupChatsList[chat].last_message_time}
            profileId={groupChatsList[chat].profile_id}
          />
        </Button>
      )
    }

    // console.log("groupTilesJSX: ", groupTilesJSX)

    return groupTilesJSX;
  }

  type LocationStateType = {
    location_id: string;
    location_name: string;
    place_id: string;
    chat_id: string;
    profile_id: string;
    sign_in: string;
  }

  const fetchMyLocations = async () => {
    if (!userIDState) {
      return;
    }

    const { data, error } = await supabase.rpc('fetch_all_locations', { p_user_id: userIDState });

    if (error) {
      console.log(error);
      return;
    }

    // update myLocation state
    const mapping: LocationStateType[] = [...myLocation];
    data.forEach((item: any) => {
      if (!mapping.find((location) => location.location_id === item.location_id))
        mapping.push({
          location_id: item.location_id,
          location_name: item.location_name,
          place_id: item.place_id,
          chat_id: item.chat_id,
          profile_id: item.profile_id,
          sign_in: item.sign_in
        })
    })

    setMyLocation(mapping);
  }

  const handleLocationClick = async (chat_id: string, place_id: string) => {
    const { data, error } = await supabase.rpc("join_location", {
      p_user_id: userIDState,
      p_profile_id: profileId,
      p_place_id: place_id
    });

    if (error) {
      console.log("error")
      console.log(error);
      return;
    } else {
      // console.log(data);

      // console.log("data: ", data)
      if (data === "Successfully joined the location") {
        onClose();
        handleClick(chat_id, profileId);

        fetchMyLocations();
      } else {
        setJoinMsg(data);

        setForceChatId(chat_id);
        setForcePlaceId(place_id);

        onOpen();
      }
    }
  }

  const [forcePlaceId, setForcePlaceId] = useState<string>("");
  const [forceChatId, setForceChatId] = useState<string>("");

  const handleModalContinue = async () => {
    const { data, error } = await supabase.rpc("sign_out_from_all_locations", {
      p_user_id: userIDState,
    });

    if (error) {
      console.log("error: ", error);
      return;
    } else {
      onClose();
      resetLocation();
      // console.log("data: ", data)

      handleLocationClick(forceChatId, forcePlaceId)
    }
  }

  const resetLocation = useResetRecoilState(myLocationState);

  const handleSignOut = async (place_id: string) => {
    const { data, error } = await supabase.rpc("sign_out_from_location", {
      p_user_id: userIDState,
      p_place_id: place_id,
    });

    if (error) {
      console.log("error: ", error);
      return;
    } else {
      resetLocation();
    }
  }

  const generateLocationTiles = () => {
    const locationtiles: JSX.Element[] = [];

    const curLocation = nearLocations.find((location) => {
      if (myLocation[0]) {
        return location.chat_id === myLocation[0].chat_id;
      }
    });

    const myLocationThis = myLocation[0];

    if (myLocationThis) {
      locationtiles.push(
        <>
          <Box
            marginBottom={"1rem"}
          >
            <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size={"xs"}>
              <ModalOverlay bg={"#00000060"} />
              <ModalContent bg={"white"} margin={"auto"}>
                <ModalHeader textAlign={"center"}>Sign into location</ModalHeader>
                <ModalBody paddingBottom={"2rem"}>
                  <Text
                    variant={"body1"}
                  >
                    {joinMsg}
                  </Text>
                </ModalBody>

                <ModalFooter p={0} h={"4rem"} borderTop={"1px black solid"}>
                  <Button width="50%" onClick={onClose} height={"3rem"} backgroundColor={"white"} borderRight={"1px black solid"} borderRadius={0}>
                    Cancel
                  </Button>
                  <Button width="50%" height={"3rem"} backgroundColor={"white"} onClick={() => handleModalContinue()} color={"red"}>
                    Continue
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
            <LocationCard
              location={myLocationThis}
              handleTopLeftButtonClick={() => handleLocationClick(myLocationThis.chat_id, myLocationThis.place_id)}
              myProfileId={profileId}
              setProfileId={setProfileId}
              refreshCount={refreshCount}
              handleJoinClick={() => handleClick(myLocationThis.chat_id, myLocation[0].profile_id)}
              handleSignOut={() => handleSignOut(myLocationThis.place_id)}
            />
          </Box>
        </>
      )
    }

    nearLocations.map((location) => {
      if (myLocation[0] && location.chat_id === myLocation[0].chat_id) {
        return;
      }

      locationtiles.push(
        <>
          <Box
            marginBottom={"1rem"}
          >
            <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size={"xs"}>
              <ModalOverlay bg={"#00000060"} />
              <ModalContent bg={"white"} margin={"auto"}>
                <ModalHeader textAlign={"center"}>Sign into location</ModalHeader>
                <ModalBody paddingBottom={"2rem"}>
                  <Text
                    variant={"body1"}
                  >
                    {joinMsg}
                  </Text>
                </ModalBody>

                <ModalFooter p={0} h={"4rem"} borderTop={"1px black solid"}>
                  <Button width="50%" onClick={onClose} height={"3rem"} backgroundColor={"white"} borderRight={"1px black solid"} borderRadius={0}>
                    Cancel
                  </Button>
                  <Button width="50%" height={"3rem"} backgroundColor={"white"} onClick={() => handleModalContinue()} color={"red"}>
                    Continue
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
            <LocationCard
              location={location}
              handleTopLeftButtonClick={() => handleLocationClick(location.chat_id, location.place_id)}
              myProfileId={profileId}
              setProfileId={setProfileId}
              refreshCount={refreshCount}
              handleJoinClick={() => handleClick(location.chat_id, profileId)}
              handleSignOut={() => handleSignOut(location.place_id)}
            />
          </Box>
        </>
      )
    });

    return locationtiles;
  }

  useEffect(() => {
    // console.log("nearLocations: ", nearLocations)
  }, [nearLocations])

  const vh = window.innerHeight;

  return (
    <Box
      width={"100%"}
      height={"100%"}
    // overflowY={"scroll"}
    >
      {
        !userIDState ? <>
          <Box
            width={"100%"}
            height={"100%"}
            position={"fixed"}
            textAlign={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            top={"50%"}
          >
            <Text>
              Please sign in to view chats
            </Text>
          </Box>
        </> : <></>
      }
      <Tabs isFitted variant='soft-rounded' colorScheme="green" height={"100%"}>
        <TabList
          overflowX={"scroll"}
        >
          <Tab position={"relative"} onClick={() => {handleTabChange(0)}}>
            Friends
            <Box
              position={"absolute"}
              top={"0rem"}
              right={"0.5rem"}
            >
              <NewMsgMarker numberOfNewMsg={unread_messagesState.friends_unread || 0} />
            </Box>
          </Tab>
          <Tab position={"relative"} onClick={() => {handleTabChange(1)}}>
            Groups
            <Box
              position={"absolute"}
              top={"0rem"}
              right={"0.5rem"}
            >
              <NewMsgMarker numberOfNewMsg={unread_messagesState.groups_unread || 0} />
            </Box>
          </Tab>
          <Tab position={"relative"} onClick={() => {handleTabChange(2)}}>
            Events

            <Box
              position={"absolute"}
              top={"0rem"}
              right={"0.5rem"}
            >
              <NewMsgMarker numberOfNewMsg={unread_messagesState.events_unread || 0} />
            </Box>
          </Tab>
          <Tab position={"relative"} onClick={() => {handleTabChange(3)}}>
            Locations
            <Box
              position={"absolute"}
              top={"0rem"}
              right={"0.5rem"}
            >
              <NewMsgMarker numberOfNewMsg={unread_messagesState.location_unread || 0} />
            </Box>
          </Tab>
        </TabList>

        <TabPanels
          overflowY={"scroll"}
        >
          <TabPanel
            p={"0"}
            height={"100%"}
          >
            {friendChats && friendChats.length > 0 ? generateFriendTiles() : <></>}
          </TabPanel>
          <TabPanel
            p={"0"}
            height={"100%"}
          >
            {groupChats && groupChats.length > 0 ? generateGroupTiles() : <></>}
          </TabPanel>
          <TabPanel
            height={"100%"}
          >
            {eventChats && eventChats.length > 0 ? generateEventTiles() : <></>}
          </TabPanel>
          <TabPanel
            height={"100%"}
          >
            {
              nearLocations.length === 0 && myLocation.length === 0 && <Text textAlign={"center"} p={"2rem"}>
                No locations found nearby
              </Text>
            }
            {nearLocations && nearLocations.length > 0 || myLocation.length > 0 ? generateLocationTiles() : <>
              <Button
                width={"100%"}
                height={"3rem"}
                backgroundColor={"green.UC"}
                color={"white"}
                onClick={getNearbyLocations}
              >
                Get nearby locations
              </Button>
            </>}
            <Box
              position={"fixed"}
              bottom={"6rem"}
              right={"2rem"}
            >
              <Tooltip label="Click to load nearby locations!" aria-label='A tooltip'>
                <IconButton
                  isRound={true}
                  variant='solid'
                  colorScheme='teal'
                  aria-label='Done'
                  fontSize='1.5rem'
                  size={"lg"}
                  onClick={getNearbyLocations}
                  icon={<RepeatIcon />}
                />
              </Tooltip>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default ChatListView;
