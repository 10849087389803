import React, { Dispatch, SetStateAction, useEffect, useState, FC } from "react";
import Select from "react-select";
import { useRecoilState } from "recoil";
import { roomProfilesState } from "recoilStore/store";

interface OptionType {
  label: string;
  value: string;
}

interface Props {
  chatId: string;
  setselected: Dispatch<SetStateAction<string[]>>;
  selected: string[];
}

const MultiselectUC: FC<Props> = ({ chatId, setselected, selected }: Props) => {
  const [roomProfiles, setRoomProfiles] = useRecoilState(roomProfilesState);
  const [selectedOptions, setSelectedOptions] = useState<OptionType[] | null>(null);
  const [roomMemberOptions, setRoomMemberOptions] = useState<OptionType[]>([]);

  useEffect(() => {
    // Find profiles for the given chatId
    const roomProfilesForChat = roomProfiles[chatId] || [];
    
    // Convert them into OptionType
    const options: OptionType[] = roomProfilesForChat.map(profile => ({
      label: profile.name_on_profile,
      value: profile.profile_id,
    }));
    
    setRoomMemberOptions(options);
  }, [roomProfiles, chatId]);

  const handleChange = (selectedOptions: OptionType[] | null) => {
    setSelectedOptions(selectedOptions);
    const newselected = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setselected(newselected);
  };

  return (
    <Select
      isMulti
      value={selectedOptions}
      onChange={handleChange as any}  // Cast to 'any' to silence type mismatch error
      options={roomMemberOptions}
    />
  );
};

export default MultiselectUC;
