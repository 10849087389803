import React, { ChangeEvent, ChangeEventHandler, FC, useState, useEffect, useRef } from "react";
import {
  Flex,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  useToast
} from "@chakra-ui/react";
import { supabase } from "Services/supabase";
import { useRecoilState } from "recoil";
import { roomProfileMappingState, friendsState, roomsState } from "recoilStore/store";

type Props = {
  roomId: string;
  roomProfile?: string;
};

const ChatRoomFooter: FC<Props> = ({ roomId, roomProfile }: Props) => {
  const [msg, setMSG] = useState("");
  const [roomProfileMapping, setRoomProfileMapping] = useRecoilState(roomProfileMappingState);
  const [friends, setFriends] = useRecoilState(friendsState);
  const [rooms, setRooms] = useRecoilState(roomsState);
  // const [roomProfile, setRoomProfile] = useState("");
  const inputRef = useRef<HTMLInputElement>(null); // Create a ref

  const toast = useToast();

  useEffect(() => {
    // console.log("roomId: ", roomId);
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the input field when component mounts
    }
  }, []);

  // useEffect(() => {
  //   const profileId = roomProfileMapping[roomId];
  //   // console.log("roomProfileMapping: ", roomProfileMapping);
  //   setRoomProfile(profileId);
  // }, [roomId, roomProfileMapping]);

  const handleInput: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setMSG(e.target.value);
  };

  const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === "Enter") {
      handleSend();
      e.preventDefault();
    }
  };

  const updateRoomTime = (roomId: string, newTime: string) => {
    const updatedRooms = rooms.map((room) => {
      if (room.room_id === roomId) {
        return {
          ...room,
          last_message_time: newTime,
        };
      }
      return room;
    });

    setRooms(updatedRooms);
  };

  const handleSend = async () => {
    if (roomProfile && msg) {
      const { error } = await supabase.rpc("send_msg", {
        p_content: msg,
        p_room_id: roomId,
        p_sender_profile_id: roomProfile,
      });

      if (error) {
        console.error('Error sending message:', error);
        toast({
          position: "top",
          title: "Error",
          description: "Error sending message.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        setMSG('');
        updateRoomTime(roomId, new Date().toISOString());
      }
    }
  };

  return (
    <Flex
      w={"100%"}
      h={"80px"}
      justifyContent={"space-between"}
      alignItems={"center"}
      pos={"sticky"}
      p={"1rem 1.5rem"}
      borderTop={"2px var(--chakra-colors-black-light) solid"}
      bg={"white"}
      bottom={"0"}
      zIndex={"2"}
    >
      <InputGroup size="md">
        <Input
          pr="4.5rem"
          bgColor={"white"}
          borderColor={"green.UC"}
          borderWidth={"2px"}
          value={msg}
          _focus={{
            borderColor: "green.UC",
            boxShadow: "0 0 0 1px green.UC",
          }}
          onChange={handleInput}
          onKeyUp={handleKeyPress}
          // ref={inputRef}
        />
        <InputRightElement width="5rem">
          <Button
            h={"100%"}
            w={"100%"}
            bgColor={"green.UC"}
            color={"white"}
            onClick={handleSend}
          >
            Send
          </Button>
        </InputRightElement>
      </InputGroup>
    </Flex>
  );
};

export default ChatRoomFooter;
