import React, { FC, useEffect, useState } from "react";
import { Flex, Box, Text, HStack } from "@chakra-ui/react";
import { ProfileAvatar, StatusMarker, NewMsgMarker } from "Components";
import { useRecoilState } from "recoil";
import { friendsState, profileLocation } from "recoilStore/store";

type Props = {
  id: string;
  name: string;
  // status: string;
  // location: string;
  numberOfNewMsg: number;
  lastMsgTime: string;
};

const ChatTile: FC<Props> = ({ id, name, numberOfNewMsg, lastMsgTime }: Props) => {
  const [friends, setFriends] = useRecoilState(friendsState);
  const [location, setLocation] = useRecoilState(profileLocation);
  const [friendId, setFriendId] = useState("");
  const [myId, setMyId] = useState("")
  const [thisLocation, setThisLocation] = useState("")

  useEffect(() => {
    // find the friend with the given id
    const friend = friends.find((friend) => friend.room_id === id);
    // console.log("friend: ", friend)
    if (friend) {
      // update the friend's id
      // console.log("friend.friend_profile_id: ", friend.friend_profile_id)
      setFriendId(friend.friend_profile_id);
    }
    // console.log("friendId", friendId)

    const myProfile = friends.find((friend) => friend.room_id === id);
    if (myProfile) {
      setMyId(myProfile.profile_id);
    }

    // console.log("friends: ", friends)

  }, [friends]);

  useEffect(() => {
    if (friendId && location) {
      const newLoc = location[friendId];

      if (newLoc) {
        setThisLocation(newLoc);
      }
    }
  }, [location, friendId]);

  const formatDate = (lastMsgTime: string) => {
    const msgDate = new Date(lastMsgTime);
    const today = new Date();
    const yesterday = new Date(Date.now() - 86400000); // 86400000ms equals 1 day

    const isSameDay = (d1: Date, d2: Date) => (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );

    if (isSameDay(msgDate, today)) {
      return msgDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else if (isSameDay(msgDate, yesterday)) {
      return 'Yesterday';
    } else {
      return msgDate.toLocaleDateString([], { day: '2-digit', month: '2-digit', year: '2-digit' });
    }
  }

  return (
    <Flex w="100%" h="80px" align={"center"} p={"0 0.5rem"}>
      <HStack spacing="-2rem">
        <Box h="3.5rem" w="3.5rem" position={"relative"} top={"-0.3rem"} zIndex={2}>
          <ProfileAvatar userId={friendId} chatRoomName={name} gotBorder={true} />
        </Box>
        <Box h="3rem" w="3rem" position={"relative"} top={"0.3rem"}>
          <ProfileAvatar userId={myId} chatRoomName={name} />
        </Box>
      </HStack>
      <Flex
        ml={"1rem"}
        w={"100%"}
        h={"100%"}
        align={"center"}
        borderBottom={"1px solid #CDCDCD"}
      >
        <Box>
          <Text variant={"chatTileName"}>{name}</Text>
          <Flex alignItems={"center"} gap={"0.5rem"}>
            <StatusMarker currStatus={thisLocation.length > 0 ? "active" : "away"} />
            {/* {props.status === "offline" ? (
              <Text variant={"chatSignedIn"}>Offline</Text>
            ) : props.location === "" ? (
              <Text variant={"chatSignedIn"}>Somewhere</Text>
            ) : (
              <Text variant={"chatSignedIn"}>
                Signed-in at {props.location}
              </Text>
            )} */}
            {
              thisLocation.length > 0 ? (
                <Text variant={"chatSignedIn"}>
                  {thisLocation.slice(0, 27)}...
                </Text>
              ) : (
                <Text variant={"chatSignedIn"}>Is somewhere not registered</Text>
              )
            }

          </Flex>
        </Box>
        <Box ml={"auto"} mr={"1rem"}>
          {
            numberOfNewMsg > 0 ? (
              <NewMsgMarker numberOfNewMsg={numberOfNewMsg} />
            ) : (
              <Text variant={"chatTileTime"}>{formatDate(lastMsgTime)}</Text>
            )
          }
        </Box>
      </Flex>
    </Flex>
  );
};

export default ChatTile;
