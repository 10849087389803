import React, { FC, useEffect, useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Box,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  IconButton,
  Text,
  useToast,
} from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { DefaultTitle, ContactTile, FullProfile } from "Components";
import { useRecoilState } from "recoil";
import { notFriendsState, profilesState, roomProfileMappingState, friendsState } from "recoilStore/store";
import { supabase } from "Services/supabase";

type Props = {};

interface Friend {
  id: string;
  name: string;
  room_id: string;
  status: string;
  location: string;
}

interface FriendsList {
  id: string;
  name: string;
  friends: Friend[];
}

type RoomProfileMapping = {
  [room_id: string]: string;
};

type NotFriendType = {
  profile_id: string;
  friend_profile_id: string;
  status: string;
  friend_avatar: string;
  friend_name_on_profile: string;
  friend_description: string;
  friend_background_color: string;
  friend_text_identifier: string;
  friend_num_identifier: string;
  room_id: string;
  initiator: string;
};

type FriendType = {
  profile_id: string;
  friend_profile_id: string;
  status: string;
  friend_avatar: string;
  friend_name_on_profile: string;
  friend_description: string;
  friend_background_color: string;
  friend_text_identifier: string;
  friend_num_identifier: string;
  room_id: string;
};

type Profiles = {
  avatar: string;
  created_at: string;
  description: string;
  id: string;
  name_on_profile: string;
  owner: string;
  profile_name: string;
  background_color: string;
  text_identifier: string;
  num_identifier: string;
};

const RequestsLists: FC<Props> = ({ }) => {
  const toast = useToast();

  const [friends, setFriends] = useRecoilState(notFriendsState);
  const [profiles, setProfiles] = useRecoilState(profilesState);
  const [friendsList, setFriendsList] = useState<FriendsList[]>([]);
  const [roomProfileMapping, setRoomProfileMapping] = useRecoilState(roomProfileMappingState);
  const [friendsStateList, setFriendsState] = useRecoilState(friendsState);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedFriendID, setSelectedFriendID] = useState<string>("");
  const [selectedFriend, setSelectedFriend] = useState<NotFriendType | null>(null);
  const [selectedProfile, setSelectedProfile] = useState<Profiles | null>(null);

  const selectFriend = (friendId: string) => () => {
    setSelectedFriendID(friendId);
    friends.forEach((friend) => {
      if (friend.friend_profile_id === friendId) {
        setSelectedFriend(friend);

        // cast selectedFriend to selectedProfile
        const avatar = friend.friend_avatar;
        const created_at = "";
        const description = friend.friend_description;
        const id = friend.friend_profile_id;
        const name_on_profile = friend.friend_name_on_profile;
        const owner = "";
        const profile_name = "";
        const background_color = friend.friend_background_color;
        const text_identifier = friend.friend_text_identifier;
        const num_identifier = friend.friend_num_identifier;
        const profile: Profiles = {
          avatar,
          created_at,
          description,
          id,
          name_on_profile,
          owner,
          profile_name,
          background_color,
          text_identifier,
          num_identifier,
        };

        setSelectedProfile(profile);
      }
    })
    onOpen();
  }

  const removeFriendFromList = (friendId: string) => {
    // Update Recoil state
    const updatedFriends = friends.filter(f => f.friend_profile_id !== friendId);
    setFriends(updatedFriends);

    // Update local state
    const updatedFriendsList = friendsList.map(profileList => ({
      ...profileList,
      friends: profileList.friends.filter(friend => friend.id !== friendId)
    }));
    setFriendsList(updatedFriendsList);
  }

  const acceptFriend = async () => {
    if (!selectedFriend) {
      return;
    }

    const { data, error } = await supabase.rpc("accept_friend", {
      request_id: selectedFriend?.room_id,
      acceptor_id: selectedFriend?.profile_id,
    });

    if (error) {
      console.error("Error accepting friend:", error);
      return;
    }

    toast({
      position: "top",
      title: "Friend request accepted",
      description: `${selectedFriend?.friend_name_on_profile} will be notified.`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    removeFriendFromList(selectedFriend.friend_profile_id);

    // update roomProfileMapping
    const newMapping: RoomProfileMapping = { ...roomProfileMapping };
    newMapping[selectedFriend.room_id] = selectedFriend.profile_id;
    setRoomProfileMapping(newMapping);

    // update friendsStateList
    // change slectedFriend to Friend type
    const profile_id = selectedFriend.profile_id;
    const friend_profile_id = selectedFriend.friend_profile_id;
    const status = selectedFriend.status;
    const friend_avatar = selectedFriend.friend_avatar;
    const friend_name_on_profile = selectedFriend.friend_name_on_profile;
    const friend_description = selectedFriend.friend_description;
    const friend_background_color = selectedFriend.friend_background_color;
    const friend_text_identifier = selectedFriend.friend_text_identifier;
    const friend_num_identifier = selectedFriend.friend_num_identifier;
    const room_id = selectedFriend.room_id;

    const friend: FriendType = {
      profile_id,
      friend_profile_id,
      status,
      friend_avatar,
      friend_name_on_profile,
      friend_description,
      friend_background_color,
      friend_text_identifier,
      friend_num_identifier,
      room_id,
    };

    const updatedFriendsStateList = [...friendsStateList, friend];
    setFriendsState(updatedFriendsStateList);

    onClose();
  }

  const rejectFriend = async () => {
    if (!selectedFriend) {
      return;
    }

    const { data, error } = await supabase.rpc("reject_friend", {
      request_id: selectedFriend?.room_id,
      rejector_id: selectedFriend?.profile_id,
    });

    if (error) {
      console.error("Error rejecting friend:", error);
      return;
    }

    toast({
      position: "top",
      title: "Friend request rejected",
      description: `${selectedFriend?.friend_name_on_profile} will be notified.`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    removeFriendFromList(selectedFriend.friend_profile_id);

    onClose();
  }

  useEffect(() => {
    const newMapping: RoomProfileMapping = { ...roomProfileMapping };
    const newFriendsList = profiles.map((profile) => {
      const profileFriends = friends.filter((friend) => friend.profile_id === profile.id).map((friend) => {
        if (!newMapping[friend.room_id]) {
          newMapping[friend.room_id] = profile.id;
        }
        return {
          id: friend.friend_profile_id,
          name: friend.friend_name_on_profile,
          room_id: friend.room_id,
          status: "active",
          location: "Somewhere",
        };
      });

      return {
        id: profile.id,
        name: profile.name_on_profile,
        friends: profileFriends,
      };
    });

    setFriendsList(newFriendsList);
  }, [friends]);

  return (
    <Box>
      <Tabs mt={4} borderTop="1px solid #CDCDCD" backgroundColor={"white"} p={"0.3rem 0"} size="lg">
        <TabList overflowX="scroll" overflowY="hidden" position={"sticky"} top={"80px"} backgroundColor={"white"} zIndex={1} paddingBottom={"2px"}>
          {profiles.map((profileList) => (
            <Tab key={profileList.id} _selected={{ color: "green.UC", borderBottomColor: "green.UC" }} style={{ fontFamily: "roboto", fontSize: "1rem" }}>
              {profileList.profile_name}
            </Tab>
          ))}
        </TabList>
        <DefaultTitle title={"Check this profile"} />
        <TabPanels>
          {friendsList.map((profileList) => (
            <TabPanel key={profileList.id} p={0}>
              {profileList.friends.map((friend) => (
                <Button width="100%" h={"80px"} p={"0"} m={"0"} variant={"unstyled"} textAlign={"left"} onClick={selectFriend(friend.id)}>
                  <ContactTile key={friend.id} id={friend.id} name={friend.name} status={friend.status} location={friend.location} />
                </Button>
              ))}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        size={"full"}
        isFullHeight={true}
      >
        <DrawerOverlay bg="white" />
        <DrawerContent>
          <DrawerHeader
            borderBottomWidth='1px'
            display={"flex"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <IconButton
              aria-label="Back to chatlist"
              icon={<ChevronLeftIcon boxSize={8} />}
              onClick={() => onClose()}
              backgroundColor={"white"}
            />
            <Text variant={"tileTitle"}>
              View {selectedFriend?.friend_name_on_profile}'s Request
            </Text>
          </DrawerHeader>

          <DrawerBody>
            <Box
              position={"relative"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              h={"85vh"}
              margin={"auto"}
            >
              {
                selectedProfile ? <FullProfile
                  profileData={selectedProfile}
                /> : <></>
              }
            </Box>
          </DrawerBody>

          <DrawerFooter
            borderTopWidth='1px'
            padding={"1rem"}
          >
            {
              selectedFriend?.initiator === selectedFriend?.profile_id ? (
                <Text variant={"soft"} color={"#5e5e5e"}>
                  Waiting for {selectedFriend?.friend_name_on_profile} to accept your request
                </Text>
              ) : <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
                p={"0 0.5rem"}
              >
                <Button variant='outline' mr={3} onClick={rejectFriend} width={"50%"}>
                  Reject
                </Button>
                <Button colorScheme='blue' width={"50%"} onClick={acceptFriend}>
                  Accept
                </Button>
              </Box>
            }
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>

  );
};

export default RequestsLists;
