import {
  Box, Container, Flex, Text, Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import { FullProfile } from "Components";
import { getDeltaTime } from "Utils";
import React, { FC, ReactNode, useState } from "react";
import { useRecoilState } from "recoil";
import { profilesIDs, notFriendsState, friendsState } from "recoilStore/store";

type Props = {
  created_at: string;
  msg: string;
  authorId: string;
  avatar: ReactNode;
};

type ProfilesType = {
  avatar: string;
  created_at: string;
  description: string;
  id: string;
  name_on_profile: string;
  owner: string;
  profile_name: string;
  background_color: string;
  text_identifier: string;
  num_identifier: string;
}

const ChatBubble: FC<Props> = ({
  created_at,
  msg,
  authorId,
  avatar,
}: Props) => {
  const [profilesIDsValue] = useRecoilState(profilesIDs);
  const [notFriends] = useRecoilState(notFriendsState);
  const [friends] = useRecoilState(friendsState);
  const [thisNotFriend, setThisNotFriend] = useState({} as ProfilesType);
  const { isOpen, onOpen, onClose } = useDisclosure()

  const when: String = getDeltaTime(new Date(), new Date(created_at));
  const mine: Boolean = profilesIDsValue.includes(authorId);

  return (
    <Box maxW={"90%"} alignSelf={mine ? "flex-end" : "flex-start"}>
      <Flex flexDir={mine ? "row-reverse" : "row"} gap={"0.5rem"}>
        <Box
          onClick={() => {
            const thisNotFriend = notFriends.find(notfriend => notfriend.friend_profile_id === authorId);
            const thisFriend = friends.find(friend => friend.friend_profile_id === authorId);

            // cast any to ProfilesType
            const thisProfile = {} as ProfilesType;
            
            if (thisNotFriend) {
              thisProfile.avatar = thisNotFriend.friend_avatar;
              thisProfile.created_at = "";
              thisProfile.description = thisNotFriend.friend_description;
              thisProfile.id = thisNotFriend.friend_profile_id;
              thisProfile.name_on_profile = thisNotFriend.friend_name_on_profile;
              thisProfile.owner = "";
              thisProfile.profile_name = "";
              thisProfile.background_color = thisNotFriend.friend_background_color;
              thisProfile.text_identifier = thisNotFriend.friend_text_identifier;
              thisProfile.num_identifier = thisNotFriend.friend_num_identifier;

              setThisNotFriend(thisProfile)
            } else if (thisFriend) {
              thisProfile.avatar = thisFriend.friend_avatar;
              thisProfile.created_at = "";
              thisProfile.description = thisFriend.friend_description;
              thisProfile.id = thisFriend.friend_profile_id;
              thisProfile.name_on_profile = thisFriend.friend_name_on_profile;
              thisProfile.owner = "";
              thisProfile.profile_name = "";
              thisProfile.background_color = thisFriend.friend_background_color;
              thisProfile.text_identifier = thisFriend.friend_text_identifier;
              thisProfile.num_identifier = thisFriend.friend_num_identifier;

              setThisNotFriend(thisProfile)
            }

            onOpen()
          }}
        >
          {avatar}
        </Box>
        <Container variant={"chatBubble"} mine={mine}>
          <Text variant={"chatBubble"} textAlign={"left"}>
            {msg}
          </Text>
          <Text variant={"timestamp"} textAlign={"right"} mt={"0.5rem"}>
            {when}
          </Text>
        </Container>
      </Flex>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setThisNotFriend({} as ProfilesType)
          onClose()
        }}
        isCentered={true}
        size={"80vh"}
      >
        <ModalOverlay
          bg="#00000060"
          padding={"0"}
        />
        <ModalContent
          height={"80vh"}
          padding={"0"}
        >
          <FullProfile key={authorId} profileData={thisNotFriend} />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ChatBubble;
