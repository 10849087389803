import React, { FC, useEffect, useState } from "react";
import { Flex, Text, Box } from "@chakra-ui/react";
import { ProfileAvatar, StatusMarker } from "Components";
import { useRecoilState } from "recoil";
import { profileLocation } from "recoilStore/store";

type Props = {
  id: string;
  name: string;
  status: string;
  location: string;
};

const ContactTile: FC<Props> = ({ id, name }: Props) => {
  const [location, setLocation] = useRecoilState(profileLocation);
  const [thisLocation, setThisLocation] = useState("")

  useEffect(() => {
    if (id && location) {
      const newLoc = location[id];

      if (newLoc) {
        setThisLocation(newLoc);
      }
    }
  }, [location, id]);

  return (
    <Flex w="100%" h="80px" align={"center"} p={"0 0.5rem"}>
      <Box h="3rem" w="3rem">
        <ProfileAvatar userId={id} chatRoomName={name} />
      </Box>
      <Flex
        ml={"1rem"}
        w={"100%"}
        h={"100%"}
        align={"center"}
        borderBottom={"1px solid #CDCDCD"}
      >
        <Box>
          <Text variant={"chatTileName"}>{name}</Text>
          <Flex alignItems={"center"} gap={"0.5rem"}>
            <StatusMarker currStatus={thisLocation.length > 0 ? "active" : "away"} />
            {/* {props.status === "offline" ? (
              <Text variant={"chatSignedIn"}>Offline</Text>
            ) : props.location === "" ? (
              <Text variant={"chatSignedIn"}>Somewhere</Text>
            ) : (
              <Text variant={"chatSignedIn"}>
                Signed-in at {props.location}
              </Text>
            )} */}
            {
              thisLocation.length > 0 ? (
                <Text variant={"chatSignedIn"}>
                  {thisLocation.slice(0, 27)}...
                </Text>
              ) : (
                <Text variant={"chatSignedIn"}>Is somewhere not registered</Text>
              )
            }

          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default ContactTile;
