import { Box } from "@chakra-ui/react";
import { FullProfile } from "Components";
import Carousel from "framer-motion-carousel";
import React, { FC, useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { profilesState, friendsState, roomProfilesState, notFriendsState } from "recoilStore/store";
import { supabase } from "Services/supabase";

type Props = {
  chatId: string;
  profileId?: string;
};

type Profiles = {
  avatar: string;
  created_at: string;
  description: string;
  id: string;
  name_on_profile: string;
  owner: string;
  profile_name: string;
  background_color: string;
  text_identifier: string;
  num_identifier: string;
};

type RoomProfileType = {
  profile_id: string,
  avatar: string,
  name_on_profile: string,
  description: string,
  background_color: string,
  text_identifier: string,
  num_identifier: string
};

type FriendType = {
  profile_id: string;
  friend_profile_id: string;
  status: string;
  friend_avatar: string;
  friend_name_on_profile: string;
  friend_description: string;
  friend_background_color: string;
  friend_text_identifier: string;
  friend_num_identifier: string;
  room_id: string;
};

type NotFriendType = {
  profile_id: string;
  friend_profile_id: string;
  status: string;
  friend_avatar: string;
  friend_name_on_profile: string;
  friend_description: string;
  friend_background_color: string;
  friend_text_identifier: string;
  friend_num_identifier: string;
  room_id: string;
  initiator: string;
};

const ProfileViewer: FC<Props> = ({ chatId, profileId }: Props) => {
  const [profiles, changeProfiles] = useState<Profiles[]>([]);
  const [friends, setFriends] = useRecoilState(friendsState);
  const [isFriendChat, setIsFriendChat] = useState<boolean>(false);
  const [myProfiles, setProfilesState] = useRecoilState(profilesState);
  const [roomProfiles, setRoomProfiles] = useRecoilState(roomProfilesState);
  const [notFriends, setNotFriends] = useRecoilState(notFriendsState);

  const getFriendsProfile = (friend: FriendType) => {
    const myProfile: Profiles = myProfiles.find((profile) => profile.id === friend.profile_id) as Profiles;

    const friendProfiles: Profiles[] = [{
      avatar: friend.friend_avatar,
      created_at: "",
      description: friend.friend_description,
      id: friend.friend_profile_id,
      name_on_profile: friend.friend_name_on_profile,
      owner: "",
      profile_name: "",
      background_color: friend.friend_background_color,
      text_identifier: friend.friend_text_identifier,
      num_identifier: friend.friend_num_identifier,
    }, myProfile]

    changeProfiles(friendProfiles);
  };

  const saveToNotFriends = (profileData: RoomProfileType) => {
    const notFriendData: NotFriendType = {
      profile_id: profileId || "",
      friend_profile_id: profileData.profile_id,
      status: "not_friend",
      friend_avatar: profileData.avatar,
      friend_name_on_profile: profileData.name_on_profile,
      friend_description: profileData.description,
      friend_background_color: profileData.background_color,
      friend_text_identifier: profileData.text_identifier,
      friend_num_identifier: profileData.num_identifier,
      room_id: chatId,
      initiator: "system"
    };

    setNotFriends(prevNotFriends => {
      const existing = prevNotFriends.find(item => item.friend_profile_id === notFriendData.friend_profile_id && item.room_id === chatId);
      if (existing) {
        return prevNotFriends.map(item => item.friend_profile_id === notFriendData.friend_profile_id && item.room_id === chatId ? notFriendData : item);
      } else {
        return [...prevNotFriends, notFriendData];
      }
    });
  };

  const getProfiles = async () => {
    const { data, error } = await supabase.rpc("get_users_in_chatroom", {
      p_room_id: chatId,
    });

    if (error) {
      console.log(error);
      return;
    }

    const roomProfiles: RoomProfileType[] = data;

    // change the avatar of the roomProfiles
    roomProfiles.forEach((profile) => {
      profile.avatar = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/avatars/${profile.avatar}`
    });

    setRoomProfiles((prevRoomProfiles) => {
      return {
        ...prevRoomProfiles,
        [chatId]: roomProfiles
      };
    });

    const profiles: Profiles[] = roomProfiles.map((profile) => {
      // Check if the profile is not in the friends list
      const isNotFriend = !friends.some(friend => friend.friend_profile_id === profile.profile_id);
      if (isNotFriend) {
        saveToNotFriends(profile);
      }

      return {
        avatar: profile.avatar,
        created_at: "",
        description: profile.description,
        id: profile.profile_id,
        name_on_profile: profile.name_on_profile,
        owner: "",
        profile_name: "",
        background_color: profile.background_color,
        text_identifier: profile.text_identifier,
        num_identifier: profile.num_identifier,
      }
    });

    changeProfiles(profiles);
  };

  useEffect(() => {
    if (!chatId) return;

    const friend = friends.find((friend) => friend.room_id === chatId);

    if (friend) {
      getFriendsProfile(friend);
      setIsFriendChat(true);
    } else {
      getProfiles();
      setIsFriendChat(false);
    }

  }, [chatId, friends]);

  return (
    <Box backgroundColor={"white.UC"} height={"100vh"}>
      <Box
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        h={"90vh"}
        margin={"auto"}
      >
        <Carousel
          interval={0}
          autoPlay={false}
          loop={true}
          renderArrowLeft={() => <div></div>}
          renderArrowRight={() => <div></div>}
          renderDots={({ activeIndex, setActiveIndex }) => (
            <div
              style={{
                position: "absolute",
                bottom: "0",
                width: "100%",
                height: "3rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              {profiles.map((profile, i) => (
                <div
                  key={i}
                  style={{
                    width: "1rem",
                    height: "1rem",
                    borderRadius: "50%",
                    backgroundColor:
                      activeIndex === i
                        ? "rgba(0, 0, 0, 0.7)"
                        : "rgba(0, 0, 0, 0.3)",
                  }}
                  onClick={() => setActiveIndex(i)}
                ></div>
              ))}
            </div>
          )}
        >
          {profiles.map((profile, i) => (
            <FullProfile key={i} profileData={profile} />
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

export default ProfileViewer;

