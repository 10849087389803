import { Box } from "@chakra-ui/react";
import { FullProfileView } from "Views";
import React, { FC } from "react";

type Props = {};

const ProfilePage: FC<Props> = (props: Props) => {
  return (
    <Box flexGrow={1}>
      <FullProfileView />
    </Box>
  );
};

export default ProfilePage;
