import { Box, Flex, VStack } from "@chakra-ui/react";
import { ProfileAvatar, ChatBubble, ChatRoomFooter } from "Components";
import { supabase } from "Services/supabase";
import type { MessagesType } from "Types/types";
import React, { FC, useEffect, useState, useRef } from "react";
import { useToast } from "@chakra-ui/react";

type Props = {
    chatId: string;
    profileId?: string;
};

const ConversationViewer: FC<Props> = ({ chatId, profileId }: Props) => {
    const [chatMsgs, setChatMsgs] = useState<Array<MessagesType>>();
    const containerRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState(false);

    const toast = useToast();

    const scrollToPosition = (position: number) => {
        if (containerRef.current) {
            // Disable smooth scrolling
            containerRef.current.style.scrollBehavior = 'auto';

            // Set the position
            containerRef.current.scrollTop = position;
        }
    }

    const getMessages = async () => {
        if (isLoading) return;
        setIsLoading(true); // <-- Start loading

        const prevScrollHeight = containerRef.current?.scrollHeight;

        const { error, data } = await supabase
            .from("messages")
            .select("*")
            .eq("room_id", chatId)
            .order("created_at", { ascending: false }) // Fetch latest messages first
            .limit(20)
            .range(chatMsgs ? chatMsgs.length : 0, (chatMsgs ? chatMsgs.length : 0) + 19)

        if (error) {
            console.log(error);
            toast({
                position: "top",
                title: "Error",
                description: "You are not friends with this user.",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
            return
        }

        let newMsgs = [...data, ...(chatMsgs || [])].sort((a, b) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
            return dateA.getTime() - dateB.getTime();
        });
        setChatMsgs(newMsgs);


        setTimeout(() => {
            setIsLoading(false);
        }, 200);

        if (prevScrollHeight && containerRef.current) {
            const newScrollHeight = containerRef.current.scrollHeight;
            const scrollHeightDifference = newScrollHeight - prevScrollHeight + 1;

            scrollToPosition(scrollHeightDifference);
        }
    };

    if (!chatMsgs) {
        getMessages().then(() => {
            setTimeout(() => {
                scrollToPosition(containerRef.current?.scrollHeight || 0);
            }, 0);
        });
    }

    const handleScroll = (e: React.UIEvent<HTMLElement>) => {
        e.preventDefault()
        if (e.currentTarget.scrollTop === 0 &&
            !isLoading && chatMsgs) {
            getMessages();
        }
    };

    useEffect(() => {
        const newChannel = supabase
            .channel(`messages:room_id=eq.${chatId}`)
            .on(
                "postgres_changes",
                {
                    event: "*",
                    schema: "public",
                    table: "messages",
                    filter: `room_id=eq.${chatId}`,
                },
                (payload) => {
                    console.log("Change received!", payload);
                    const newMessage = payload.new as MessagesType;
                    setChatMsgs((prevMsgs) => [
                        ...(prevMsgs || []),
                        newMessage,
                    ]);
                    setTimeout(() => {
                        scrollToPosition(containerRef.current?.scrollHeight || 0);
                    }, 50);
                }
            )
            .subscribe();

        return () => {
            supabase.channel(`messages:room_id=eq.${chatId}`).unsubscribe();
            supabase.removeChannel(newChannel);
        };
    }, [chatMsgs]);

    const ht = window.innerHeight;

    return (
        <Flex
            flexDir={"column"}
            scrollBehavior={"smooth"}
            overflowY={"scroll"}
            h={ht - 80 + "px"}
            w={"100%"}
            backgroundColor={"white.UC"}
            ref={containerRef}
            flexGrow={1}
            onScroll={handleScroll}
        >
            {isLoading && (
                <Box textAlign="center" p="1rem">
                    Loading...
                </Box>
            )}
            <VStack spacing={"0.5rem"} p={"0.5rem"} flexGrow={1}>
                {chatMsgs?.map((msg, index) => (
                    <ChatBubble
                        key={index}
                        created_at={msg.created_at}
                        msg={msg.content}
                        authorId={msg.profile_id}
                        avatar={
                            <Box h="3rem" w="3rem">
                                <ProfileAvatar userId={msg.profile_id} chatRoomName="" />
                            </Box>
                        }
                    />
                ))}
            </VStack>
            <ChatRoomFooter roomId={chatId} roomProfile={profileId} />
        </Flex>
    );
};

export default ConversationViewer;
