import React, { FC, useEffect } from "react";
import { ProfileInfoView, UCAccountView, HomeBottomView } from "Views";
import { Box, Stack } from "@chakra-ui/react";

type Props = {};

const ht = window.outerHeight;

const HomePage: FC<Props> = (props: Props) => {
  const dw = window.outerWidth;
  return (
    <Stack
      gap={dw * 0.04 + "px"}
      py={dw * 0.04 + "px"}
      px={dw * 0.04 + "px"}
    // align={"stretch"}
    // spacing="2px"
    >
      <ProfileInfoView />
      {/* <UCAccountView /> */}
      <HomeBottomView />
    </Stack>
  );
};

export default HomePage;
