import { Box, Flex, Text } from "@chakra-ui/react";
import { StatusMarker } from "Components";
import React, { FC } from "react";

type Props = {
  id: string;
  name: string;
  status?: string;
  location?: string;
  welcome?: boolean;
};

const StatusHeader: FC<Props> = (props: Props) => {
  return (
    <Box p={"0 1rem"}>
      <Text variant={"soft"} fontSize={"lg"}>
        {props.name}
      </Text>
      <Flex
        alignItems={"center"}
        gap={"0.5rem"}
      >
        {
          props.location ? <Flex alignItems={"center"} gap={"0.5rem"} pt={"0.1rem"}>
            <StatusMarker currStatus={props.location.length > 0 ? "active" : "away"} />
            {
              props.location.length > 0 ? (
                <Text variant={"chatSignedIn"}>
                  {props.location.slice(0, 15)}...
                </Text>
              ) : (
                <Text variant={"chatSignedIn"}>Is somewhere not registered</Text>
              )
            }

          </Flex> : <></>
        }

        {
          props.welcome ? <Text variant={"soft"} fontSize={"15px"} color={"purple.light"} display={"flex"}>
            to
            <Text variant={"uni"} fontSize={"15px!important"} marginLeft={"5px"}>Uni</Text>
            <Text variant={"connect"} fontSize={"15px!important"}>Connect</Text>!
          </Text> : <></>
        }
      </Flex>
    </Box>
  );
};

export default StatusHeader;
