import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil'
import { friendsState, profilesState, roomProfileMappingState, roomsState, profileEventMappingState, myLocationState } from 'recoilStore/store'
import { supabase } from 'Services/supabase'
import { Editable, ButtonGroup, EditablePreview, Input, useEditableControls, EditableInput, Box, Text, Modal, ModalOverlay, ModalContent, ModalHeader, Textarea, Checkbox, Stack, ModalFooter, Switch, Button, ModalBody, Flex, Icon, IconButton, useDisclosure, useToast } from '@chakra-ui/react'
import { WarningTwoIcon, EditIcon, ChevronRightIcon, ExternalLinkIcon, CloseIcon, CheckIcon } from '@chakra-ui/icons'
import ComponentMultiselect from 'Components/ComponentMultiselect';
import MultiSelectUC from 'Components/MultiselectUC';
import AttendanceViewer from 'Components/AttendanceViewer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBellSlash
} from '@fortawesome/free-solid-svg-icons'

type Props = {
  chatId: string;
  profileId?: string
}

type Room = {
  room_id: string;
  room_name: string;
  is_muted: boolean;
};

const ChatSettingViewer: FC<Props> = ({ chatId, profileId }: Props) => {
  const navigate = useNavigate();
  const toast = useToast();

  const [chatType, setChatType] = useState<string>(""); // ["friends", "groups"]
  const [myType, setMyType] = useState<string>(""); // ["admin", "member"]
  const [myProfileId, setMyProfileId] = useState<string>("");
  const [muteState, setMuteState] = useState<boolean>(false); // [false, true
  const [removeMembers, setRemoveMembers] = useState<string[]>([]);
  const [chatInfo, setChatInfo] = useState<any>({}); // {room_name: "", room_description: ""}

  const [friends, setFriends] = useRecoilState(friendsState);
  const [profiles, setProfiles] = useRecoilState(profilesState);
  const [roomProfileMapping, setRoomProfileMapping] = useRecoilState(roomProfileMappingState);
  const [rooms, setRooms] = useRecoilState(roomsState);

  const [profileEventMapping, setProfileEventMappingState] = useRecoilState(profileEventMappingState);
  const [isEvent, setIsEvent] = useState<boolean>(false);

  const [curEvent, setCurEvent] = useState<any>({});

  const [startTime, setStartTime] = React.useState("");
  const [endTime, setEndTime] = React.useState("");

  const { isOpen: reportModalIsOpen, onOpen: onOpenReportModal, onClose: onCloseReportModal } = useDisclosure()
  const { isOpen: inviteFriendModalIsOpen, onOpen: onOpenInviteFriend, onClose: onCloseInviteFriend } = useDisclosure()
  const { isOpen: removeFriendModalisOpen, onOpen: onOpenRemoveFriend, onClose: onCloseRemoveFriend } = useDisclosure()
  const { isOpen: attendanceModalIsOpen, onOpen: onOpenAttendance, onClose: onCloseAttendance } = useDisclosure()

  const [editableValue, setEditableValue] = React.useState(chatInfo.room_description || "");
  const [editableValue2, setEditableValue2] = React.useState(chatInfo.room_name || "");

  const getGroupType = () => {
    const newEventChats = Object.values(profileEventMapping).flat().map(event => event.chat_id);

    if (newEventChats.includes(chatId)) {
      setIsEvent(true);

      const newCurEvent = Object.values(profileEventMapping).flat().find(event => event.chat_id === chatId);
      setCurEvent(newCurEvent);

      console.log(newCurEvent)

      // console.log(newCurEvent)
    } else {
      setIsEvent(false);
    }
  }

  // initiallize setting
  useEffect(() => {
    const getMyType = async () => {
      const myProfileId = roomProfileMapping[chatId]
      const { data, error } = await supabase.rpc('check_is_creator', {
        p_room_id: chatId,
        p_user_id: profileId || myProfileId
      });

      if (error) {
        console.log(error);
      } else {

        console.log(data)

        if (data) {
          setMyType("admin")
        } else {
          setMyType("member")
        }
      }
    }

    if (!chatId) return;

    const friend = friends.find((friend) => friend.room_id === chatId);

    if (friend) {
      setChatType("friends");
    } else {
      setChatType("groups");
      getMyType();
      getGroupType();
    }
  }, [chatId])


  // get chat info
  useEffect(() => {
    // fill chatInfo
    const thisRoom = rooms.find((room) => room.room_id === chatId);

    // console.log("thisRoom", thisRoom)

    setChatInfo({
      room_name: thisRoom?.room_name,
      room_description: thisRoom?.room_description
    })

    // console.log("chatInfo", chatInfo)
  }, [myType])

  // for mute state of groups
  useEffect(() => {
    // get muted state
    const isMuted = rooms.find((room) => room.room_id === chatId)?.is_muted;

    if (isMuted) {
      setMuteState(true);
    } else {
      setMuteState(false);
    }
  }, [rooms])

  const handleMute = async () => {
    const myProfileId = roomProfileMapping[chatId]

    const { data, error } = await supabase.rpc('toggle_mute_group_chat', {
      p_room_id: chatId,
      p_profile_id: myProfileId
    });

    if (error) {
      console.log(error);
    } else {
      // console.log("success");

      // Deep copy rooms
      let newRooms = JSON.parse(JSON.stringify(rooms));

      // Find the index of the room to modify
      const roomIndex = newRooms.findIndex((room: Room) => room.room_id === chatId);

      // Modify the is_muted property
      newRooms[roomIndex].is_muted = !muteState;

      // Update the rooms state
      setRooms(newRooms);

      // Update mute state
      setMuteState(!muteState);;
    }
  }

  // handle share group
  const handleCopyClick = () => {
    navigator.clipboard.writeText(chatId)
      .then(() => {
        toast({
          position: "top",
          title: "Copied",
          description: "Identifier for the group copied to clipboard",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch(err => {
        toast({
          position: "top",
          title: "Error",
          description: "Failed to copy text identifier",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  // for reports
  const [reportReasons, setReportReasons] = useState({
    harassing: false,
    fraud: false,
    spam: false,
    dislike: false,
    other: false,
  });
  const [customReportReason, setCustomReportReason] = useState<string>("");

  const handleReport = async () => {
    // Prepare report object
    const myProfileId = roomProfileMapping[chatId]

    const reportObject = {
      created_at: new Date().toISOString(),
      target_room_id: chatId,
      reporter_id: myProfileId,
      report_description: JSON.stringify({ reasons: reportReasons, customReason: customReportReason }),
    };

    // Insert report to the database
    const { error } = await supabase
      .from('reports')
      .insert([reportObject]);

    if (error) {
      console.error("Report failed:", error);
    } else {
      // console.log('Report succeeded');

      toast({
        position: "top",
        title: "Report submitted.",
        description: "Thank you for your report.",
        status: "success",
        duration: 9000,
        isClosable: true,
      })
    }

    onCloseReportModal();
  }

  // delete friend
  const deleteFriend = async () => {
    setFriends((prevFriends) => prevFriends.filter((friend) => friend.room_id !== chatId));
    setRooms((prevRooms) => prevRooms.filter((room) => room.room_id !== chatId));

    const { data, error } = await supabase.rpc('remove_friend', {
      row_id: chatId
    });

    if (error) {
      console.log(error);
    } else {
      // go to chat list
      navigateToChatList();
    }
  }

  // navigate to chat list
  const navigateToChatList = () => {
    navigate('/contacts');
  }

  // for adding friends to group
  const [selectedFriends, setSelectedFriends] = useState<string[]>([]);

  const addFriendToGroup = async () => {
    // console.log(selectedFriends)
    const { data, error } = await supabase.rpc('invite_to_group_chat', {
      p_room_id: chatId,
      p_profile_ids: selectedFriends
    });

    if (error) {
      toast({
        position: "top",
        title: "Error.",
        description: "Failed to add friends to the group.",
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    } else {
      // console.log("success");

      toast({
        position: "top",
        title: "Friends added.",
        description: "Friends added to the group.",
        status: "success",
        duration: 9000,
        isClosable: true,
      })

      onCloseInviteFriend();
    }
  }

  // handle leave group
  const handleLeaveGroup = async () => {
    const myProfileId = roomProfileMapping[chatId]

    const { data, error } = await supabase.rpc('leave_group_chat', {
      p_room_id: chatId,
      p_profile_id: myProfileId
    });

    if (error) {
      console.log(error);
    } else {
      // console.log("success");

      toast({
        position: "top",
        title: "Group left.",
        description: "You have left the group.",
        status: "success",
        duration: 9000,
        isClosable: true,
      })

      // remove from rooms
      setRooms((prevRooms) => prevRooms.filter((room) => room.room_id !== chatId));

      // remove from roomProfileMapping
      const newRoomProfileMapping = { ...roomProfileMapping };
      delete newRoomProfileMapping[chatId];
      setRoomProfileMapping(newRoomProfileMapping);

      navigateToChatList();
    }
  }

  // handle remove members
  const handleRemoveMembers = async () => {
    const { data, error } = await supabase.rpc('remove_from_group_chat', {
      p_room_id: chatId,
      p_profile_ids: removeMembers
    });

    if (error) {
      console.log(error);
    } else {
      // console.log("success");

      toast({
        position: "top",
        title: "Members removed.",
        description: "Members removed from the group.",
        status: "success",
        duration: 9000,
        isClosable: true,
      })

      onCloseRemoveFriend();
    }
  }

  // handle disband group
  const handleDisbandGroup = async () => {
    const { data, error } = await supabase.rpc('disband_group', {
      p_room_id: chatId
    });

    if (error) {
      console.log(error);
    } else {
      // console.log("success");

      toast({
        position: "top",
        title: "Group disbanded.",
        description: "The group has been disbanded.",
        status: "success",
        duration: 9000,
        isClosable: true,
      })

      // remove from rooms
      setRooms((prevRooms) => prevRooms.filter((room) => room.room_id !== chatId));

      // remove from roomProfileMapping
      const newRoomProfileMapping = { ...roomProfileMapping };
      delete newRoomProfileMapping[chatId];
      setRoomProfileMapping(newRoomProfileMapping);

      navigateToChatList();
    }
  }

  const changeDescription = async () => {
    // console.log("running here: ", editableValue)

    const { data, error } = await supabase.rpc('change_room_description', {
      p_room_id: chatId,
      p_new_description: editableValue
    });

    if (error) {
      console.log(error);
    } else {
      // console.log("success");

      toast({
        position: "top",
        title: "Group description changed.",
        description: "The group description has been changed.",
        status: "success",
        duration: 9000,
        isClosable: true,
      })
    }
  }

  const changeName = async () => {
    // console.log("running here: ", editableValue)

    const { data, error } = await supabase.rpc('change_room_name', {
      p_room_id: chatId,
      p_new_name: editableValue2
    });

    if (error) {
      console.log(error);
    } else {
      // console.log("success");

      toast({
        position: "top",
        title: "Group name changed.",
        description: "The group name has been changed.",
        status: "success",
        duration: 9000,
        isClosable: true,
      })
    }
  }

  const EditableControls1 = () => {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls()

    return isEditing ? (
      <ButtonGroup justifyContent='right' size='md'>
        <IconButton backgroundColor={"white"} aria-label='' icon={<CheckIcon onClick={changeName} />} {...getSubmitButtonProps()} />
        <IconButton backgroundColor={"white"} aria-label='' icon={<CloseIcon />} {...getCancelButtonProps()} />
      </ButtonGroup>
    ) : (
      <Flex marginLeft={"auto"} alignItems={"center"}>
        <IconButton backgroundColor={"white"} aria-label='' size='md' icon={<EditIcon />} {...getEditButtonProps()} />
      </Flex>
    )
  }

  const EditableControls2 = () => {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls()

    return isEditing ? (
      <ButtonGroup justifyContent='right' size='md'>
        <IconButton backgroundColor={"white"} aria-label='' icon={<CheckIcon onClick={changeDescription} />} {...getSubmitButtonProps()} />
        <IconButton backgroundColor={"white"} aria-label='' icon={<CloseIcon />} {...getCancelButtonProps()} />
      </ButtonGroup>
    ) : (
      <Flex marginLeft={"auto"} alignItems={"center"}>
        <IconButton backgroundColor={"white"} aria-label='' size='md' icon={<EditIcon />} {...getEditButtonProps()} />
      </Flex>
    )
  }

  const handleChangeTime = async () => {
    if (startTime && endTime) {
      // Format your start and end time here to match TIMESTAMPTZ if needed.
      // For instance, new Date(startTime).toISOString() could be used

      const { data, error } = await supabase
        .rpc('change_time_of_event', {
          p_chat_id: chatId,
          p_new_start: new Date(startTime).toISOString(),
          p_new_end: new Date(endTime).toISOString()
        });

      if (error) {
        console.error('Error updating event time:', error);
      } else {
        // console.log('Event time updated:', data);

        toast({
          position: "top",
          title: "Event time updated.",
          description: "The event time has been updated.",
          status: "success",
          duration: 9000,
          isClosable: true,
        })
      }
    }
  }

  return <Box
    height={"100vh"}
    backgroundColor={"white.UC"}
    overflowY={"scroll"}
  >
    {
      chatType === "friends" ?
        <Box>
          <Modal isOpen={reportModalIsOpen} onClose={onCloseReportModal} isCentered={true} size={"xs"}>
            <ModalOverlay bg="#00000060" />
            <ModalContent backgroundColor="white">
              <ModalHeader textAlign={"center"}>Select reasons for reporting</ModalHeader>
              <ModalBody paddingBottom={"10vh"}>
                <Stack spacing={3}>
                  <Checkbox size='lg' colorScheme='green' onChange={e => setReportReasons({ ...reportReasons, harassing: e.target.checked })}>
                    Harassing content
                  </Checkbox>
                  <Checkbox size='lg' colorScheme='green' onChange={e => setReportReasons({ ...reportReasons, fraud: e.target.checked })}>
                    Fraud
                  </Checkbox>
                  <Checkbox size='lg' colorScheme='green' onChange={e => setReportReasons({ ...reportReasons, spam: e.target.checked })}>
                    Spam
                  </Checkbox>
                  <Checkbox size='lg' colorScheme='green' onChange={e => setReportReasons({ ...reportReasons, dislike: e.target.checked })}>
                    I just do not like this guy
                  </Checkbox>
                  <Checkbox size='lg' colorScheme='green' onChange={e => setReportReasons({ ...reportReasons, other: e.target.checked })}>
                    Other
                  </Checkbox>
                  <Textarea isDisabled={!reportReasons.other} placeholder='Please explain' value={customReportReason} onChange={e => setCustomReportReason(e.target.value)} />
                </Stack>
              </ModalBody>

              <ModalFooter p={0} h={"4rem"} borderTop={"1px black solid"}>
                <Button width="50%" onClick={onCloseReportModal} height={"3rem"} backgroundColor={"white"} borderRight={"1px black solid"} borderRadius={0}>
                  Cancel
                </Button>
                <Button width="50%" height={"3rem"} backgroundColor={"white"} onClick={handleReport} color={"red"}>
                  Report
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Box
            backgroundColor={"white"}
            h={"70px"}
            onClick={onOpenReportModal}
          >
            <Flex
              w="100%"
              h="70px"
              alignItems="center"
              p={4}
              borderBottom="1px solid #CDCDCD"
              background={"white"}
            >
              <Text variant={"tileTitle"} marginRight="1rem"> Report </Text>
              <Icon mr="auto" as={WarningTwoIcon} />
              <Icon ml="auto" aria-label='Report' as={ChevronRightIcon} />
            </Flex>
          </Box>
          <Box
            backgroundColor={"white"}
            h={"70px"}
            onClick={deleteFriend}
            marginTop={"1rem"}
          >
            <Flex
              w="100%"
              h="70px"
              alignItems="center"
              p={4}
              borderBottom="1px solid #CDCDCD"
              borderTop="1px solid #CDCDCD"
              background={"white"}
            >
              <Text variant={"tileTitle"} margin="auto" color="red"> Delete </Text>
            </Flex>
          </Box>
        </Box>
        :
        <Box>
          {
            myType === "admin" ?
              <Box>
                <Modal isOpen={reportModalIsOpen} onClose={onCloseReportModal} isCentered={true} size={"xs"}>
                  <ModalOverlay bg="#00000060" />
                  <ModalContent backgroundColor="white">
                    <ModalHeader textAlign={"center"}>Select reasons for reporting</ModalHeader>
                    <ModalBody paddingBottom={"10vh"}>
                      <Stack spacing={3}>
                        <Checkbox size='lg' colorScheme='green' onChange={e => setReportReasons({ ...reportReasons, harassing: e.target.checked })}>
                          Harassing content
                        </Checkbox>
                        <Checkbox size='lg' colorScheme='green' onChange={e => setReportReasons({ ...reportReasons, fraud: e.target.checked })}>
                          Fraud
                        </Checkbox>
                        <Checkbox size='lg' colorScheme='green' onChange={e => setReportReasons({ ...reportReasons, spam: e.target.checked })}>
                          Spam
                        </Checkbox>
                        <Checkbox size='lg' colorScheme='green' onChange={e => setReportReasons({ ...reportReasons, dislike: e.target.checked })}>
                          There's something about this group that I dislike
                        </Checkbox>
                        <Checkbox size='lg' colorScheme='green' onChange={e => setReportReasons({ ...reportReasons, other: e.target.checked })}>
                          Other
                        </Checkbox>
                        <Textarea isDisabled={!reportReasons.other} placeholder='Please explain' value={customReportReason} onChange={e => setCustomReportReason(e.target.value)} />
                      </Stack>
                    </ModalBody>

                    <ModalFooter p={0} h={"4rem"} borderTop={"1px black solid"}>
                      <Button width="50%" onClick={onCloseReportModal} height={"3rem"} backgroundColor={"white"} borderRight={"1px black solid"} borderRadius={0}>
                        Cancel
                      </Button>
                      <Button width="50%" height={"3rem"} backgroundColor={"white"} onClick={handleReport} color={"red"}>
                        Report
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>


                <Modal isOpen={inviteFriendModalIsOpen} onClose={onCloseInviteFriend} isCentered={true} size={"xs"}>
                  <ModalOverlay bg="#00000060" />
                  <ModalContent backgroundColor="white">
                    <ModalHeader textAlign={"center"}>Select Friends</ModalHeader>
                    <ModalBody paddingBottom={"10vh"}>
                      <ComponentMultiselect setSelectedFriends={setSelectedFriends} selectedFriends={selectedFriends} />
                    </ModalBody>

                    <ModalFooter p={0} h={"4rem"} borderTop={"1px black solid"}>
                      <Button width="50%" onClick={onCloseInviteFriend} height={"3rem"} backgroundColor={"white"} borderRight={"1px black solid"} borderRadius={0}>
                        Cancel
                      </Button>
                      <Button width="50%" height={"3rem"} backgroundColor={"white"} onClick={addFriendToGroup} color={"red"}>
                        Add to group
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>



                <Modal isOpen={removeFriendModalisOpen} onClose={onCloseRemoveFriend} isCentered={true} size={"xs"}>
                  <ModalOverlay bg="#00000060" />
                  <ModalContent backgroundColor="white">
                    <ModalHeader textAlign={"center"}>Select Members</ModalHeader>
                    <ModalBody paddingBottom={"10vh"}>
                      <MultiSelectUC
                        chatId={chatId}
                        selected={removeMembers}
                        setselected={setRemoveMembers}
                      />
                      {/* <ComponentMultiselect setSelectedFriends={setSelectedFriends} selectedFriends={selectedFriends} /> */}
                    </ModalBody>

                    <ModalFooter p={0} h={"4rem"} borderTop={"1px black solid"}>
                      <Button width="50%" onClick={onCloseRemoveFriend} height={"3rem"} backgroundColor={"white"} borderRight={"1px black solid"} borderRadius={0}>
                        Cancel
                      </Button>
                      <Button width="50%" height={"3rem"} backgroundColor={"white"} onClick={handleRemoveMembers} color={"red"}>
                        Remove Members
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>

                <Modal isOpen={attendanceModalIsOpen} onClose={onCloseAttendance} isCentered={true} size={"xs"}>
                  <ModalOverlay bg="#00000060" />
                  <ModalContent backgroundColor="white">
                    <ModalHeader textAlign={"center"}>Attendance</ModalHeader>
                    <ModalBody paddingBottom={"10vh"}>
                      <AttendanceViewer event={curEvent} />
                    </ModalBody>

                    <ModalFooter p={0} h={"4rem"} borderTop={"1px black solid"}>
                      <Button width="100%" onClick={onCloseAttendance} height={"3rem"} backgroundColor={"white"} borderRadius={0}>
                        Close
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>

                <Box
                  backgroundColor={"white"}
                  p={4}
                  borderBottom={"1px solid #CDCDCD"}
                >
                  <Text variant={"soft"} mb={"1rem"}>Name:</Text>
                  <Editable
                    textAlign='left'
                    defaultValue={chatInfo.room_name}
                    fontSize='lg'
                    isPreviewFocusable={false}
                    display={"flex"}
                    onChange={(val) => setEditableValue2(val)}
                  >
                    <EditablePreview />
                    <Input size={"md"} as={EditableInput} />
                    <EditableControls1 />
                  </Editable>
                </Box>

                <Box
                  backgroundColor={"white"}
                  p={4}
                >
                  <Text variant={"soft"} mb={"1rem"}>Description:</Text>
                  <Editable
                    textAlign='left'
                    defaultValue={chatInfo.room_description || ""}
                    fontSize='lg'
                    isPreviewFocusable={false}
                    display={"flex"}
                    onChange={(val) => setEditableValue(val)}
                  >
                    <EditablePreview />
                    <Input size={"md"} as={EditableInput} />
                    <EditableControls2 />
                  </Editable>
                </Box>

                {
                  isEvent ? <>
                    <Box
                      backgroundColor={"white"}
                      marginTop={"1rem"}
                      borderBottom={"1px solid #CDCDCD"}
                      p={4}
                    >
                      <Text variant={"soft"} paddingBottom={"1rem"}>
                        Start time
                      </Text>
                      <Input
                        placeholder="Select Date and Time"
                        size="md"
                        type="datetime-local"
                        onChange={(e) => setStartTime(e.target.value)}
                      />
                    </Box>
                    <Box
                      backgroundColor={"white"}
                      p={4}
                    >
                      <Text variant={"soft"} paddingBottom={"1rem"}>
                        End time
                      </Text>
                      <Input
                        placeholder="Select Date and Time"
                        size="md"
                        type="datetime-local"
                        onChange={(e) => setEndTime(e.target.value)}
                      />
                    </Box>
                    <Button
                      width={"100%"}
                      height={"60px"}
                      borderRadius={"0px"}
                      backgroundColor={"green.UC"}
                      color={"white"}
                      onClick={handleChangeTime}
                    >
                      Change Time
                    </Button>
                  </> : <></>
                }

                <Box
                  backgroundColor={"white"}
                  h={"70px"}
                  marginTop={"1rem"}
                >
                  <Flex
                    w="100%"
                    h="70px"
                    alignItems="center"
                    p={4}
                    borderBottom="1px solid #CDCDCD"
                    background={"white"}
                  >
                    <Text
                      variant={"tileTitle"}
                      marginRight="1rem"
                    > Mute Notifications </Text>
                    <Icon
                      mr="auto"
                      size="lg"
                      as={FontAwesomeIcon}
                      icon={faBellSlash}
                    />
                    <Switch
                      colorScheme="green"
                      ml="auto"
                      size="lg"
                      isChecked={muteState}
                      onChange={handleMute}
                    />
                  </Flex>
                </Box>

                <Box
                  backgroundColor={"white"}
                  h={"70px"}
                  onClick={onOpenInviteFriend}
                >
                  <Flex
                    w="100%"
                    h="70px"
                    alignItems="center"
                    p={4}
                    borderBottom="1px solid #CDCDCD"
                    background={"white"}
                  >
                    <Text
                      variant={"tileTitle"}
                      marginRight="1rem"
                    > Invite Friends </Text>
                    <Icon ml="auto" aria-label='Report' as={ChevronRightIcon} />
                  </Flex>
                </Box>

                <Box
                  backgroundColor={"white"}
                  h={"70px"}
                  onClick={onOpenRemoveFriend}
                >
                  <Flex
                    w="100%"
                    h="70px"
                    alignItems="center"
                    p={4}
                    borderBottom="1px solid #CDCDCD"
                    background={"white"}
                  >
                    <Text
                      variant={"tileTitle"}
                      marginRight="1rem"
                    > Remove Members </Text>
                    <Icon ml="auto" aria-label='Report' as={ChevronRightIcon} />
                  </Flex>
                </Box>

                <Box
                  backgroundColor={"white"}
                  h={"70px"}
                  onClick={handleCopyClick}
                >
                  <Flex
                    w="100%"
                    h="70px"
                    alignItems="center"
                    p={4}
                    borderBottom="1px solid #CDCDCD"
                    background={"white"}
                  >
                    <Text
                      variant={"tileTitle"}
                      marginRight="1rem"
                    > Share With Others </Text>
                    <Icon ml="auto" aria-label='Report' as={ExternalLinkIcon} />
                  </Flex>
                </Box>

                {!isEvent ? <></> :
                <Box
                  backgroundColor={"white"}
                  h={"70px"}
                  onClick={onOpenAttendance}
                >
                  <Flex
                    w="100%"
                    h="70px"
                    alignItems="center"
                    p={4}
                    borderBottom="1px solid #CDCDCD"
                    background={"white"}
                  >
                    <Text
                      variant={"tileTitle"}
                      marginRight="1rem"
                    > Attendance </Text>
                    <Icon ml="auto" aria-label='Report' as={ChevronRightIcon} />
                  </Flex>
                </Box>
              }

                <Box
                  backgroundColor={"white"}
                  h={"70px"}
                  onClick={onOpenReportModal}
                >
                  <Flex
                    w="100%"
                    h="70px"
                    alignItems="center"
                    p={4}
                    borderBottom="1px solid #CDCDCD"
                    background={"white"}
                  >
                    <Text variant={"tileTitle"} marginRight="1rem"> Report </Text>
                    <Icon mr="auto" as={WarningTwoIcon} />
                    <Icon ml="auto" aria-label='Report' as={ChevronRightIcon} />
                  </Flex>
                </Box>
                {
                  isEvent ? <></> : <Box
                    backgroundColor={"white"}
                    h={"70px"}
                    onClick={handleDisbandGroup}
                    marginTop={"1rem"}
                  >
                    <Flex
                      w="100%"
                      h="70px"
                      alignItems="center"
                      p={4}
                      borderBottom="1px solid #CDCDCD"
                      borderTop="1px solid #CDCDCD"
                      background={"white"}
                    >
                      <Text variant={"tileTitle"} margin="auto" color="red"> Disband </Text>
                    </Flex>
                  </Box>
                }
              </Box>
              :
              <Box>
                <Modal isOpen={reportModalIsOpen} onClose={onCloseReportModal} isCentered={true} size={"xs"}>
                  <ModalOverlay bg="#00000060" />
                  <ModalContent backgroundColor="white">
                    <ModalHeader textAlign={"center"}>Select reasons for reporting</ModalHeader>
                    <ModalBody paddingBottom={"10vh"}>
                      <Stack spacing={3}>
                        <Checkbox size='lg' colorScheme='green' onChange={e => setReportReasons({ ...reportReasons, harassing: e.target.checked })}>
                          Harassing content
                        </Checkbox>
                        <Checkbox size='lg' colorScheme='green' onChange={e => setReportReasons({ ...reportReasons, fraud: e.target.checked })}>
                          Fraud
                        </Checkbox>
                        <Checkbox size='lg' colorScheme='green' onChange={e => setReportReasons({ ...reportReasons, spam: e.target.checked })}>
                          Spam
                        </Checkbox>
                        <Checkbox size='lg' colorScheme='green' onChange={e => setReportReasons({ ...reportReasons, dislike: e.target.checked })}>
                          There's something about this group that I dislike
                        </Checkbox>
                        <Checkbox size='lg' colorScheme='green' onChange={e => setReportReasons({ ...reportReasons, other: e.target.checked })}>
                          Other
                        </Checkbox>
                        <Textarea isDisabled={!reportReasons.other} placeholder='Please explain' value={customReportReason} onChange={e => setCustomReportReason(e.target.value)} />
                      </Stack>
                    </ModalBody>

                    <ModalFooter p={0} h={"4rem"} borderTop={"1px black solid"}>
                      <Button width="50%" onClick={onCloseReportModal} height={"3rem"} backgroundColor={"white"} borderRight={"1px black solid"} borderRadius={0}>
                        Cancel
                      </Button>
                      <Button width="50%" height={"3rem"} backgroundColor={"white"} onClick={handleReport} color={"red"}>
                        Report
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>

                
                <Modal isOpen={inviteFriendModalIsOpen} onClose={onCloseInviteFriend} isCentered={true} size={"xs"}>
                  <ModalOverlay bg="#00000060" />
                  <ModalContent backgroundColor="white">
                    <ModalHeader textAlign={"center"}>Select friends</ModalHeader>
                    <ModalBody paddingBottom={"10vh"}>
                      <ComponentMultiselect setSelectedFriends={setSelectedFriends} selectedFriends={selectedFriends} />
                    </ModalBody>

                    <ModalFooter p={0} h={"4rem"} borderTop={"1px black solid"}>
                      <Button width="50%" onClick={onCloseInviteFriend} height={"3rem"} backgroundColor={"white"} borderRight={"1px black solid"} borderRadius={0}>
                        Cancel
                      </Button>
                      <Button width="50%" height={"3rem"} backgroundColor={"white"} onClick={addFriendToGroup} color={"red"}>
                        Add to group
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>

                
                <Modal isOpen={attendanceModalIsOpen} onClose={onCloseAttendance} isCentered={true} size={"xs"}>
                  <ModalOverlay bg="#00000060" />
                  <ModalContent backgroundColor="white">
                    <ModalHeader textAlign={"center"}>Attendance</ModalHeader>
                    <ModalBody paddingBottom={"10vh"}>
                      <AttendanceViewer event={curEvent} />
                    </ModalBody>

                    <ModalFooter p={0} h={"4rem"} borderTop={"1px black solid"}>
                      <Button width="100%" onClick={onCloseAttendance} height={"3rem"} backgroundColor={"white"} borderRadius={0}>
                        Close
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>

                <Box
                  backgroundColor={"white"}
                  p={4}
                >
                  <Text variant={"soft"} mb={"1rem"}>Description:</Text>
                  <Text>{chatInfo.room_description}</Text>
                </Box>

                <Box
                  backgroundColor={"white"}
                  h={"70px"}
                  marginTop={"1rem"}
                >
                  <Flex
                    w="100%"
                    h="70px"
                    alignItems="center"
                    p={4}
                    borderBottom="1px solid #CDCDCD"
                    background={"white"}
                  >
                    <Text
                      variant={"tileTitle"}
                      marginRight="1rem"
                    > Mute Notifications </Text>
                    <Icon
                      mr="auto"
                      size="lg"
                      as={FontAwesomeIcon}
                      icon={faBellSlash}
                    />
                    <Switch
                      colorScheme="green"
                      ml="auto"
                      size="lg"
                      onChange={() => { }}
                    />
                  </Flex>
                </Box>
                <Box
                  backgroundColor={"white"}
                  h={"70px"}
                  onClick={onOpenInviteFriend}
                >
                  <Flex
                    w="100%"
                    h="70px"
                    alignItems="center"
                    p={4}
                    borderBottom="1px solid #CDCDCD"
                    background={"white"}
                  >
                    <Text
                      variant={"tileTitle"}
                      marginRight="1rem"
                    > Invite Friends </Text>
                    <Icon ml="auto" aria-label='Report' as={ChevronRightIcon} />
                  </Flex>
                </Box>
                <Box
                  backgroundColor={"white"}
                  h={"70px"}
                  onClick={handleCopyClick}
                >
                  <Flex
                    w="100%"
                    h="70px"
                    alignItems="center"
                    p={4}
                    borderBottom="1px solid #CDCDCD"
                    background={"white"}
                  >
                    <Text
                      variant={"tileTitle"}
                      marginRight="1rem"
                    > Share With Others </Text>
                    <Icon ml="auto" aria-label='Report' as={ExternalLinkIcon} />
                  </Flex>
                </Box>


                {!isEvent ? <></> :
                <Box
                  backgroundColor={"white"}
                  h={"70px"}
                  onClick={onOpenAttendance}
                >
                  <Flex
                    w="100%"
                    h="70px"
                    alignItems="center"
                    p={4}
                    borderBottom="1px solid #CDCDCD"
                    background={"white"}
                  >
                    <Text
                      variant={"tileTitle"}
                      marginRight="1rem"
                    > Attendance </Text>
                    <Icon ml="auto" aria-label='Report' as={ChevronRightIcon} />
                  </Flex>
                </Box>
              }


                <Box
                  backgroundColor={"white"}
                  h={"70px"}
                  onClick={onOpenReportModal}
                >
                  <Flex
                    w="100%"
                    h="70px"
                    alignItems="center"
                    p={4}
                    borderBottom="1px solid #CDCDCD"
                    background={"white"}
                  >
                    <Text variant={"tileTitle"} marginRight="1rem"> Report </Text>
                    <Icon mr="auto" as={WarningTwoIcon} />
                    <Icon ml="auto" aria-label='Report' as={ChevronRightIcon} />
                  </Flex>
                </Box>
                <Box
                  backgroundColor={"white"}
                  h={"70px"}
                  onClick={handleLeaveGroup}
                  marginTop={"1rem"}
                >
                  <Flex
                    w="100%"
                    h="70px"
                    alignItems="center"
                    p={4}
                    borderBottom="1px solid #CDCDCD"
                    borderTop="1px solid #CDCDCD"
                    background={"white"}
                  >
                    <Text variant={"tileTitle"} margin="auto" color="red"> Leave </Text>
                  </Flex>
                </Box>
              </Box>
          }
        </Box>
    }
  </Box>
}

export default ChatSettingViewer