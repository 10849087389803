import { Box, Grid, GridItem } from "@chakra-ui/react";
import React, { FC } from "react";
import { LevelProgress, ProfilesStack } from "Components";

type Props = {};

const ProfileInfoView: FC<Props> = (props: Props) => {
  const dw = window.outerWidth; // dw - 12% /2 => dw * 0.44
  return (
    <Box>
      <Grid
        // gap={dw * 0.04 + "px"}
        // templateRows="repeat(1, 1fr)"
        // templateColumns="repeat(1, 1fr)"
        // px={"4%"}
        // w={dw * 0.858 + "px"}
        // h={dw * 0.858 + "px"}
      >
        {/* <GridItem> */}
        <ProfilesStack></ProfilesStack>
        {/* </GridItem> */}
        {/* <GridItem>
          <LevelProgress></LevelProgress>
        </GridItem> */}
      </Grid>
    </Box>
  );
};

export default ProfileInfoView;