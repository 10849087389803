import React, { FC, useEffect, useState } from "react";
import { Box, Flex, Avatar } from "@chakra-ui/react";
import { SearchButton, StatusHeader, ProfileAvatar } from "Components";
import { useRecoilState } from "recoil";
import { userID, userNameState } from "recoilStore/store";
import { useNavigate } from "react-router-dom";

type Props = {};

// const user = {
//   id: "14",
//   name: "Night Owl",
//   status: "active",
//   location: "SCH",
// };

const TopBar: FC<Props> = (props: Props) => {
  const [userId, setUserId] = useRecoilState(userID);
  const [userName, setUserName] = useRecoilState(userNameState);
  const navigate = useNavigate()

  const goLogIn = () => {
    navigate('/login')
  }

  return (
    <Flex
      w={"100%"}
      justifyContent={"space-between"}
      alignItems={"center"}
      position={"sticky"}
      p={"1rem 1.5rem"}
      borderBottom={"1px solid #CDCDCD"}
      bg={"white"}
      top={"0"}
      // height={"5rem"}
      zIndex={1000}
    >
      <Box flexGrow={1}>
        <StatusHeader
            id={userId}
            name={"Welcome " + userName}
            // status={user.status}
            // location={user.location}
            welcome={true}
          />
      </Box>

      {
        userId ? <SearchButton /> : <Avatar backgroundColor={"green.UC"} onClick={goLogIn} />
      }

    </Flex>
  );
};

export default TopBar;
