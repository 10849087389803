import React, { FC, Dispatch, SetStateAction, useRef, useState, useEffect } from "react";
import {
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Box,
    Stack,
    Avatar,
    IconButton,
    Editable,
    EditablePreview,
    EditableTextarea,
    Tooltip
} from '@chakra-ui/react';
import { PlusSquareIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { useRecoilState } from "recoil";
import { profilesState } from "recoilStore/store";

type Props = {
    profileId: string;
    profileAvatar: File;
    setProfileAvatar: Dispatch<SetStateAction<File | undefined>>;
    profileName: string;
    setProfileName: Dispatch<SetStateAction<string>>;
    profileDescription: string;
    setProfileDescription: Dispatch<SetStateAction<string>>;
    profileBackground: string;
    setProfileBackground: Dispatch<SetStateAction<string>>;
    nameOnProfile: string;
    setNameOnProfile: Dispatch<SetStateAction<string>>;
};

const ProfileForm: React.FC<Props> = ({
    profileId,
    profileAvatar, setProfileAvatar,
    profileName, setProfileName,
    profileDescription, setProfileDescription,
    profileBackground, setProfileBackground,
    nameOnProfile, setNameOnProfile
}: Props) => {
    const [localAvatar, setLocalAvatar] = useState<string | null>(null);
    const [selectedColor, setSelectedColor] = useState<string>(profileBackground);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [profiles, setProfiles] = useRecoilState(profilesState);
    const [nameOfProfile, setNameOfProfile] = useState<string>(profileName);
    const [name, setName] = useState<string>(nameOnProfile);
    const [description, setDescription] = useState<string>(profileDescription);

    useEffect(() => {
        if (profileId) {
            const profile = profiles.find(profile => profile.id === profileId);
            if (profile) {
                setLocalAvatar(profile.avatar);

                setSelectedColor(profile.background_color);
                setProfileBackground(profile.background_color);

                setNameOfProfile(profile.profile_name);
                setProfileName(profile.profile_name);

                setName(profile.name_on_profile);
                setNameOnProfile(profile.name_on_profile);

                setDescription(profile.description);
                setProfileDescription(profile.description);
            }
        }
    }, [profileId]);

    const handleProfileNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNameOfProfile(e.target.value);
        setProfileName(e.target.value);
    };

    const handleNameOnProfileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
        setNameOnProfile(e.target.value);
    };

    const handleProfileDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(e.target.value);
        setProfileDescription(e.target.value);
    };

    const uploadAvatar = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            // console.log(file)
            const reader = new FileReader();
            reader.onloadend = () => {
                // console.log(reader.result)
                setLocalAvatar(reader.result as string);
                setProfileAvatar(file);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedColor(e.target.value);
        // console.log(e.target.value)
        setProfileBackground(e.target.value);
    };

    return (
        <Box p={4}>
            <Stack spacing={4}>
                <FormControl isRequired>
                    <FormLabel>Upload Avatar</FormLabel>
                    <Avatar
                        bg="green.UC"
                        size="lg"
                        src={localAvatar || ""}
                    >
                        <IconButton
                            aria-label="Upload avatar"
                            icon={<PlusSquareIcon />}
                            position={"absolute"}
                            bottom={"-0.5rem"}
                            right={"-0.5rem"}
                            size="sm"
                            onClick={uploadAvatar}
                            colorScheme="teal"
                            variant='outline'
                            backgroundColor='white'
                        />
                    </Avatar>
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                    />
                </FormControl>

                <FormControl isRequired>
                    <Box display={"flex"} alignItems={"center"} mb={"0.5rem"}>
                        <FormLabel margin={"0"}>Name of Profile</FormLabel>
                        <Tooltip label="This is for yourself to categorize your friends" aria-label='A tooltip'>
                            <InfoOutlineIcon ml={"auto"} mr={"2px"} />
                        </Tooltip>
                    </Box>
                    <Input
                        placeholder='For yourself to see'
                        value={nameOfProfile}
                        onChange={handleProfileNameChange}
                    />
                </FormControl>

                <FormControl isRequired>
                    <Box display={"flex"} alignItems={"center"} mb={"0.5rem"}>
                        <FormLabel margin={"0"}>Name</FormLabel>
                        <Tooltip label="Consider this a nickname of yours to friends with this profile" aria-label='A tooltip'>
                            <InfoOutlineIcon ml={"auto"} mr={"2px"} />
                        </Tooltip>
                    </Box>
                    <Input
                        placeholder='For others to recognize you'
                        value={name}
                        onChange={handleNameOnProfileChange}
                    />
                </FormControl>

                <FormControl isRequired>
                    <FormLabel>About me</FormLabel>
                    <Textarea
                        placeholder='Your group description'
                        size="md"
                        h={40}
                        value={description}
                        onChange={handleProfileDescriptionChange}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Background Color</FormLabel>
                    <Input
                        type="color"
                        value={selectedColor}
                        onChange={handleColorChange}
                        height={"10rem"}
                        width={"12rem"}
                    />
                </FormControl>
            </Stack>
        </Box>
    );
}

export default ProfileForm;