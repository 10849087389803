import { FormLabel, Input, Box, Button, FormControl, Image, Stack, Flex, useToast, Text, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@chakra-ui/react";
import { supabase } from "Services/supabase";
import React, { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PrivacyPolicy, TermsOfUse } from "Components";

type Props = {
};

// interface SignUpForm extends HTMLFormElement {
//   email: HTMLInputElement;
//   password: HTMLInputElement;
//   name: HTMLInputElement;
// }

const SignUpPage: FC<Props> = () => {
  const toast = useToast();
  const { isOpen: termOfUseIsOpen, onOpen: termOfUseOnOpen, onClose: termofUseOnClose } = useDisclosure();
  const { isOpen: privacyPolicyIsOpen, onOpen: privacyPolicyOnOpen, onClose: privacyPolicyOnClose } = useDisclosure();

  const [email, setEmail] = useState<string>("");
  const handleEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const [password, setPassword] = useState<string>("");
  const handlePasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");
  const handlePasswordConfirmationInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordConfirmation(event.target.value);
  };

  const [name, setName] = useState<string>("");
  const handleNameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  // const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
  //   event: React.FormEvent<HTMLFormElement>
  // ) => {
  //   event.preventDefault();
  //   // let form: SignUpForm = event?.currentTarget;
  //   const { email, password, name } = Object.fromEntries(
  //     new FormData(event.currentTarget)
  //   );

  //   if (typeof email !== 'string' ||
  //     typeof password !== 'string' ||
  //     typeof name !== 'string') {
  //     return;
  //   };

  //   await supabase.auth.signUp({
  //     email,
  //     password,
  //     options: {
  //       data: {
  //         name: name,
  //       },
  //     },
  //   });
  // };

  // check if password input is equal to password confirmation input
  const isPasswordConfirmed = password === passwordConfirmation;

  const navigate = useNavigate();

  const handleSignIn = () => {
    navigate("/login");
  }

  const handleSignUp = async () => {
    if (!isPasswordConfirmed) {
      console.log("passwords don't match");
      toast({
        position: "top",
        title: "Passwords don't match",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const { error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          name: name,
        },
      },
    });

    if (error) {
      toast({
        position: "top",
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    toast({
      position: "top",
      title: "Success",
      description: "Sign up successful, please check your email for verification.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    navigate("/login")
  }

  return (
    <Box
      w="100%"
      h="100vh"
      backgroundColor={"white"}
      padding={"2rem"}
    >
      <Box
        w="100%"
        h="100%"
        backgroundColor={"white"}
        margin={"auto"}
      >
        <Stack
          spacing={4}
          w="100%"
          h="100%"
          margin="auto"
          justifyContent="center"
          alignContent="center"
        >
          <Image
            src="https://zzwxyobwfvjshssazycj.supabase.co/storage/v1/object/public/images/logoUC.png"
            alt="logo"
            boxSize="40vw"
            alignSelf="center"
            marginBottom={"2rem"}
          />
          <FormControl isRequired>
            <FormLabel>Email address</FormLabel>
            <Input
              type="email"
              name="email"
              value={email}
              onChange={handleEmailInput}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              name="password"
              value={password}
              onChange={handlePasswordInput}
            />
          </FormControl>

          <FormControl isRequired isInvalid={!isPasswordConfirmed}>
            <FormLabel>Password Confirmation</FormLabel>
            <Input
              type="password"
              name="passwordConfirmation"
              value={passwordConfirmation}
              onChange={handlePasswordConfirmationInput}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              name="name"
              value={name}
              onChange={handleNameInput}
            />
          </FormControl>

          <Text>By signing up you agree with UniConnect's "<span onClick={termOfUseOnOpen} style={{color: "#73B3A3"}}>Terms Of Use</span>" and "<span onClick={privacyPolicyOnOpen}  style={{color: "#73B3A3"}}>Privacy Policy</span>"</Text>
          <Modal
            isOpen={termOfUseIsOpen}
            onClose={termofUseOnClose}
            isCentered={true}
            size={"sm"}
          >
            <ModalOverlay bg="#00000060" />
            <ModalContent
              backgroundColor="white"
            >
              <ModalHeader textAlign={"center"}>Terms of Use Agreement</ModalHeader>
              <ModalBody>
                <TermsOfUse />
              </ModalBody>

              <ModalFooter p={0} h={"4rem"} borderTop={"1px black solid"}>
                <Button width="50%" height={"3rem"} backgroundColor={"white"} borderRight={"1px black solid"} borderRadius={0} color={"red"} disabled>
                  Cancel
                </Button>
                <Button width="50%" height={"3rem"} backgroundColor={"white"} onClick={termofUseOnClose} color={"green.UC"}>
                  OK
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Modal
            isOpen={privacyPolicyIsOpen}
            onClose={privacyPolicyOnClose}
            isCentered={true}
            size={"sm"}
          >
            <ModalOverlay bg="#00000060" />
            <ModalContent
              backgroundColor="white"
            >
              <ModalHeader textAlign={"center"}>Privacy Policy</ModalHeader>
              <ModalBody>
                <PrivacyPolicy />
              </ModalBody>

              <ModalFooter p={0} h={"4rem"} borderTop={"1px black solid"}>
                <Button width="50%" height={"3rem"} backgroundColor={"white"} borderRight={"1px black solid"} borderRadius={0} color={"red"} disabled>
                  Cancel
                </Button>
                <Button width="50%" height={"3rem"} backgroundColor={"white"} onClick={privacyPolicyOnClose} color={"green.UC"}>
                  OK
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Flex
            flexDir="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height="5.5rem"
            paddingTop="2rem"
          >
            <Button
              type="submit"
              onClick={handleSignIn}
              width={"50%"}
              height={"100%"}
              color={"purple.UC"}
              backgroundColor={"white"}
              borderRadius={"0"}
              fontWeight={"bold"}
            >
              Sign In
            </Button>
            <Button
              type="submit"
              onClick={handleSignUp}
              width={"50%"}
              height={"100%"}
              fontWeight={"bold"}
              backgroundColor={"green.UC"}
              color={"white"}
            >
              Sign Up
            </Button>
          </Flex>
        </Stack>
      </Box>
    </Box>
  );
};

export default SignUpPage;
