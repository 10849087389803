import React, { FC, useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useMemo, useState } from "react";
import { Flex, IconButton, Icon, Box, Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useToast
} from '@chakra-ui/react'
import { useRecoilState } from "recoil";
import { profileEventMappingState } from "recoilStore/store";
import { supabase } from "Services/supabase";

import { EventsListView } from "Views";
import { SelectMyProfile } from "Components";

type Props = {
  events: Array<any>;
  location: { latitude: number, longitude: number } | null;
  setLocation: (location: { latitude: number, longitude: number } | null) => void;
};

const EventsMapView: FC<Props> = ({ events, location, setLocation }: Props) => {
  const [myProfileId, setMyProfileId] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [profileEventMapping, setProfileEventMapping] = useRecoilState(profileEventMappingState);

  const toast = useToast();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
  })

  const [map, setMap] = React.useState(null);


  const center = useMemo(() => {
    return location ? { lat: location.latitude, lng: location.longitude } : { lat: 43.47, lng: -80.54 };
  }, [location]);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null)
  }, [])

  const onLoad = React.useCallback(function callback(map: any) {
    const bounds = new google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const handleRecenter = () => {

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });

        // console.log("The user Location: ", location)
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const pulsateEffect = {
    scale: [1, 1.1, 1], // Scale up and down
    opacity: [1, 0.1, 1], // Change opacity
    transition: {
      duration: 1.5, // Duration of the animation in seconds
      repeat: Infinity, // Repeat indefinitely
    },
  };

  const [selectedEvent, setSelectedEvent] = useState<any | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedLocationEvents, setSelectedLocationEvents] = useState<any | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<any | null>(null);

  const handleMarkerClick = (event: any) => {
    setSelectedEvent(event);
    setSelectedLocation(event.location_name);
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    if (!selectedEvent) return;

    const locationGeo = selectedEvent?.location_geo;

    const locationEvents = events.filter((event) => {
      return event.location_geo === locationGeo;
    });

    // console.log("locationEvents: ", locationEvents)

    setSelectedLocationEvents(locationEvents);
  }, [selectedEvent]);

  const [focusEventId, setFocusEventId] = useState<string>("");

  const addEvent = async () => {
    // check if event exists
    const focusedChatId = focusEventId

    if (profileEventMapping) {
      if (profileEventMapping[myProfileId]) {
        const profileEvents = profileEventMapping[myProfileId];
        const profileEventIds = profileEvents.map((event) => event.chat_id);

        if (profileEventIds.includes(focusedChatId)) {
          toast({
            position: "top",
            title: "Already joined event",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          onClose();
          return;
        }
      }
    }

    const { data, error } = await supabase.rpc('join_group_chat', {
      p_room_id: focusEventId,
      p_profile_id: myProfileId
    });

    if (error) {
      toast({
        position: "top",
        title: "Error joining event",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    } else {
      toast({
        position: "top",
        title: "Joined event",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }

    onClose();
  };

  const ht = window.innerHeight - 70 - 70;

  return isLoaded ? (
    <Box
      position="relative"
    >
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: ht }}
        center={center}
        zoom={16}
        // onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          mapId: 'default-light',
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          zoomControl: false,
        }}
      >
        {events.map((event) => {
          // console.log(event.location_name);

          const [longitude, latitude] = event.location_geo
            .replace("POINT(", "")
            .replace(")", "")
            .split(" ");

          // console.log(latitude, longitude);
          return (
            <Marker
              key={event.id}
              position={{ lat: Number(latitude), lng: Number(longitude) }}
              onClick={() => handleMarkerClick(event)}
            />
          );
        })}

        {location && (
          <Box
            as={motion.div}
            whileHover={pulsateEffect} // Apply the glow effect on hover
            whileTap={{ scale: 2.2 }} // Scale down when tapped
          >
            <Marker
              position={{ lat: location.latitude, lng: location.longitude }}
              icon={{
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: "blue",
                fillOpacity: 0.6,
                strokeColor: "white",
                strokeWeight: 5,
                scale: 10, // Adjust the scale as needed
              }}
            />
          </Box>
        )}
      </GoogleMap>
      <Flex
        position="fixed"
        bottom="90px"
        right="2.5rem"
        zIndex={1}
      >
        <IconButton
          aria-label="Recenter Map"
          icon={<Icon as={FontAwesomeIcon} icon={faLocationArrow} />}
          isRound
          size="lg"
          bgColor="blue.500"
          color="white"
          onClick={handleRecenter}
        />
      </Flex>
      <Drawer
        isOpen={isDrawerOpen}
        placement="right"
        onClose={handleDrawerClose}
        size="xl">
        <DrawerOverlay bg="rgba(0,0,0,0.7)" />
        <DrawerContent bg={"white"}>
          <DrawerCloseButton />
          <DrawerHeader
            borderBottomWidth="1px"
          >
            <Box>
              <Text variant={"soft"} fontSize={"16px"} pb={"0.5rem"}>
                Select an event to join
              </Text>
              <Text noOfLines={1} fontSize={"14px"}>
                <Icon as={FontAwesomeIcon} icon={faLocationDot} mr={2} />
                {selectedLocation}
              </Text>
            </Box>
          </DrawerHeader>

          <DrawerBody
            pt={"1rem"}
          >
            {
              selectedLocationEvents ? <EventsListView
                events={selectedLocationEvents}
                focusEventId={focusEventId || ""}
                setFocusEventId={setFocusEventId}
              /> : <></>
            }
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={handleDrawerClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" mr={3} onClick={onOpen}>
              Join chat
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
        size={"xs"}
      >
        <ModalOverlay bg="#00000060" />

        <ModalContent
          bg={"white"}
          margin={"auto"}
        >
          <ModalHeader textAlign={"center"}>Select a profile to sign in</ModalHeader>

          <ModalBody
            pb={"2rem"}
          >
            <SelectMyProfile myProfileId={myProfileId} setMyProfileId={setMyProfileId} />
          </ModalBody>
          <ModalFooter p={0} h={"4rem"} borderTop={"1px black solid"}>
            <Button width="50%" onClick={onClose} height={"3rem"} backgroundColor={"white"} borderRight={"1px black solid"} borderRadius={0}>
              Cancel
            </Button>
            <Button width="50%" height={"3rem"} backgroundColor={"white"} onClick={addEvent} color={"#73B3A3"}>
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box >
  ) : (
    <></>
  );

}



export default EventsMapView;