import React, { FC, useRef, useState } from "react";
import MenuItem from "./MenuItem";
import LocationForm from "Components/LocationForm";
import EventForm from "Components/EventForm";
import GroupForm from "Components/GroupForm";
import ProfileForm from "Components/ProfileForm";

import {
    Button,
    Flex,
    Icon,
    useDisclosure,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    DrawerFooter,
    DrawerCloseButton,
    Text,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useToast,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCompass as solidCompass,
    faComment as solidComment,
    faPlus as solidPlus,
    faAddressBook as solidAddressBook,
    faUser as solidUser,
} from "@fortawesome/free-solid-svg-icons";

import {
    faCompass as regularCompass,
    faComment as regularComment,
    faAddressBook as regularAddressBook,
    faUser as regularUser,
} from "@fortawesome/free-regular-svg-icons";
import { supabase } from "Services/supabase";
import { useRecoilState } from "recoil";
import {
    userIDState,
    profilesIDs,
    profilesState,
    profileEventMappingState,
    msgNotificationNum,
    friendNotificationNum,
} from "recoilStore/store";

type Props = {};
const Menu: FC<Props> = (props: Props) => {
    const [msgNotification, setMsgNotification] =
        useRecoilState(msgNotificationNum);
    const [friendNotification, setFriendNotification] = useRecoilState(
        friendNotificationNum
    );

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [tabIndex, setTabIndex] = useState(0);
    const [formType, setFormType] = useState("group chat");
    const [userID, setUserID] = useRecoilState(userIDState);
    const [profilesIDsState, setProfilesIDsState] = useRecoilState(profilesIDs);
    const [profilesRecoilState, setProfilesRecoilState] =
        useRecoilState(profilesState);

    const [groupName, setGroupName] = useState("");
    const [groupDescription, setGroupDescription] = useState("");
    const [selectedFriends, setSelectedFriends] = useState<string[]>([]);
    const [myProfileId, setMyProfileId] = useState<string>("");

    const [profileAvatar, setProfileAvatar] = useState<File>();
    const [profileName, setProfileName] = useState<string>("");
    const [profileDescription, setProfileDescription] = useState<string>("");
    const [profileBackground, setProfileBackground] =
        useState<string>("#5C7BA1");
    const [nameOnProfile, setNameOnProfile] = useState<string>("");

    type DateValuePiece = Date | null;
    type DateValue = DateValuePiece | [DateValuePiece, DateValuePiece];

    const [eventDescription, setEventDescription] = useState<string>("");
    const [eventTags, setEventTags] = useState<string>("")
    const [startDate, setStartDate] = useState<DateValuePiece>(new Date());
    const [endDate, setEndDate] = useState<DateValuePiece>(new Date());
    const [eventName, setEventName] = useState<string>("");
    const [eventLocation, setEventLocation] = useState<string>("");
    const [eventProfile, setEventProfile] = useState<string>("");
    const [cord, setCord] = useState<{ lat: number; lng: number }>({
        lat: 0,
        lng: 0,
    });
    const [eventLocationName, setEventLocationName] = useState<string>("");

    const [profileEventMapping, setProfileEventMapping] = useRecoilState(
        profileEventMappingState
    );

    const [chatsNotificationNum, setChatsNotificationNum] = useState<number>(0);
    const [contactsNotificationNum, setContactsNotificationNum] =
        useState<number>(0);
    const [isClosing, setIsClosing] = useState(false);

    // const [locationName, setLocationName] = useState<string>('');
    // const [locationDescription, setLocationDescription] = useState<string>('');
    // const [lognitude, setLognitude] = useState<number>(0);
    // const [latitude, setLatitude] = useState<number>(0);

    const ht = window.innerHeight;
    const toast = useToast();

    const handleClose = () => {
        handleFormChange(0);
        onClose();
        setIsClosing(false);
    };

    async function createGroupChat(
        roomName: string,
        roomDescription: string,
        createdBy: string,
        members: string[]
    ) {
        const { data, error } = await supabase.rpc("create_group_chat", {
            p_room_name: roomName,
            p_room_description: roomDescription,
            p_created_by: createdBy,
            p_member_ids: members,
        });

        if (error) {
            console.error("Error creating group chat:", error);
            return;
        }

        toast({
            position: "top",
            title: "Room created.",
            description: "Your room has been successfully created.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
    }

    type ProfileEventType = {
        profile_id: string;
        event_id: string;
        chat_id: string;
        place_id: string;
        event_name: string;
        start_time: string;
        end_time: string;
        event_discription: string;
        create_time: string;
        is_muted: boolean;
        location_name: string;
    };

    const fetchMyEvents = async () => {
        supabase.rpc("get_events_by_profiles", {
            profile_ids: [myProfileId],
        }).then((res) => {
            const mapping: { [key: string]: ProfileEventType[] } = {
                ...profileEventMapping,
            };

            if (res.error) {
                console.log(res.error);
                return;
            }

            if (res.data) {
                res.data.forEach((item: any) => {
                    if (!mapping[item.profile_id]) {
                        mapping[item.profile_id] = [item];
                    } else {
                        let found = false;
                        mapping[item.profile_id].forEach((event) => {
                            if (event.event_id === item.event_id) {
                                found = true;
                            }
                        });
                        if (!found) {
                            mapping[item.profile_id] = [
                                ...mapping[item.profile_id],
                                item,
                            ];
                        }
                    }
                });

                setProfileEventMapping(mapping);
            }
        })
    };

    async function createEvent(
        eventName: string,
        eventDescription: string,
        eventLocation: string,
        startDate: string,
        endDate: string,
        profileID: string
    ) {
        setMyProfileId(profileID);
        supabase.rpc("create_event", {
            p_event_name: eventName,
            p_event_desc: eventDescription,
            p_location_id: eventLocation,
            p_start: startDate,
            p_end: endDate,
            p_created_by: profileID,
            p_room_name: eventName,
            p_room_description: eventDescription,
            p_event_tags: eventTags,
            p_cord: `POINT(${cord.lng} ${cord.lat})`,
            p_location_name: eventLocationName,
        }).then((res) => {
            if (res.error) {
                console.error("Error creating event:", res.error);
                toast({
                    position: "top",
                    title: "Event created.",
                    description: res.error.message || "Failed to create event",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                return;
            } else {
                toast({
                    position: "top",
                    title: "Event created.",
                    description: "Your event has been successfully created.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });

                fetchMyEvents();
            }
        });


    }

    async function uploadImage(image: any, profileId: any) {
        // console.log(image, profileId)
        if (image && profileId) {
            // filePath as jpg or png
            let filePath = "";
            if (image.type === "image/jpeg") {
                filePath = `${profileId}.jpg`;
            } else if (image.type === "image/png") {
                filePath = `${profileId}.png`;
            } else {
                console.error("Image type not supported.");
                return;
            }

            let { data, error } = await supabase.storage
                .from("avatars")
                .upload(filePath, image);
            if (error) {
                console.log(error);

                toast({
                    position: "top",
                    title: "Update Failed",
                    description: "Failed to update avatar.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                const data = await supabase
                    .from("profiles")
                    .update({
                        avatar: filePath,
                    })
                    .eq("id", profileId)
                    .then((data) => {
                        // console.log(data);
                    });
            }
        }
    }

    const updateProfiles = (profile_id: string) => {
        let filePath = "";

        if (profileAvatar?.type === "image/jpeg") {
            filePath = `${profile_id}.jpg`;
        } else if (profileAvatar?.type === "image/png") {
            filePath = `${profile_id}.png`;
        }

        console.log("background1", profileBackground.toUpperCase());

        const newProfiles = [
            ...profilesRecoilState,
            {
                id: profile_id,
                name: profileName,
                description: profileDescription,
                background_color: profileBackground.toUpperCase(),
                avatar: `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/avatars/${filePath}`,
                name_on_profile: nameOnProfile,
                owner: userID,
            },
        ];
        setProfilesRecoilState(newProfiles as any);
    };

    async function createProfile(
        userId: string,
        profileName: string,
        profileDescription: string,
        profileBackground: string,
        nameOnProfile: string,
        profileAvatar: File
    ) {
        try {
            const { data, error } = await supabase.rpc("create_profile", {
                profile_owner: userId,
                profile_name: profileName,
                profile_description: profileDescription,
                profile_background: profileBackground.toUpperCase(),
                name_on_profile: nameOnProfile,
            });

            if (error) {
                console.error("Error creating profile:", error);
            } else {
                const profile_id = data.new_id;

                await uploadImage(profileAvatar, profile_id);

                toast({
                    position: "top",
                    title: "Profile created.",
                    description: "Your profile has been successfully created.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });

                // update profileIDs, profilesState
                const newProfilesIDs = [...profilesIDsState, profile_id];
                setProfilesIDsState(newProfilesIDs as any);

                // update profilesState
                updateProfiles(profile_id as any);
            }
        } catch (err) {
            console.error("Unexpected error:", err);
        }
    }

    const currPath = useLocation().pathname.split("/")[1];
    const ExploreIcon = (
        <Icon
            as={FontAwesomeIcon}
            icon={currPath === "map" ? solidCompass : regularCompass}
            boxSize={"1.3rem"}
            color={currPath === "map" ? "green.UC" : "purple.UC"}
        />
    );
    const ChatsIcon = (
        <Icon
            as={FontAwesomeIcon}
            icon={currPath === "chats" ? solidComment : regularComment}
            boxSize={"1.3rem"}
            color={currPath === "chats" ? "green.UC" : "purple.UC"}
        />
    );
    const PlusIcon = (
        <Icon
            as={FontAwesomeIcon}
            icon={solidPlus}
            boxSize={"2.5rem"}
            borderRadius={"100%"}
            bg="green.UC"
            color="white.UC"
            p={"0.75rem"}
            boxShadow={"0 0 10px #000D"}
            marginTop={"calc(-1*(100% - 0.75rem))"}
        />
    );
    const ContactsIcon = (
        <Icon
            as={FontAwesomeIcon}
            icon={
                currPath === "contacts" ? solidAddressBook : regularAddressBook
            }
            boxSize={"1.3rem"}
            color={currPath === "contacts" ? "green.UC" : "purple.UC"}
        />
    );
    const ProfileIcon = (
        <Icon
            as={FontAwesomeIcon}
            icon={currPath === "home" ? solidUser : regularUser}
            boxSize={"1.3rem"}
            color={currPath === "home" ? "green.UC" : "purple.UC"}
        />
    );

    const handleFormChange = (index: number) => {
        if (index === 0) {
            setFormType("event");
        } else if (index === 1) {
            setFormType("group chat");
        } else {
            setFormType("location");
        }

        setTabIndex(index);
    };

    const handleGroupChatSubmit = async () => {
        // prevent default form submission
        if (selectedFriends.length >= 2) {
            // const [member1, member2] = selectedFriends;
            await createGroupChat(
                groupName,
                groupDescription,
                myProfileId,
                selectedFriends
            );
            onClose(); // Close the drawer
        } else {
            console.error("Select at least two friends for the group chat.");
            toast({
                position: "top",
                title: "UniConnect Notification",
                description: "Select at least two friends for the group chat.",
                status: "info",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleEventSubmit = async () => {
        // prevent default form submission
        createEvent(
            eventName,
            eventDescription,
            eventLocation,
            startDate?.toISOString() || "",
            endDate?.toISOString() || "",
            eventProfile
        );

        onClose(); // Close the drawer
    };

    const handleProfileSubmit = async () => {
        // prevent default form submission
        // console.log(userID, profileName, profileDescription, profileBackground, nameOnProfile);
        if (profileAvatar) {
            await createProfile(
                userID,
                profileName,
                profileDescription,
                profileBackground,
                nameOnProfile,
                profileAvatar
            );
        } else {
            console.error("Profile avatar is not provided.");
        }

        onClose(); // Close the drawer
    };

    const handleSubmit = async () => {
        if (tabIndex === 0) {
            await handleEventSubmit();
        } else if (tabIndex === 1) {
            await handleGroupChatSubmit();
        } else {
            await handleProfileSubmit();
        }
    };

    return (
        <Flex
            flexDir={"row"}
            justifyContent={"space-evenly"}
            w={"100%"}
            position={"sticky"}
            bottom={"0"}
            borderTop={"2px var(--chakra-colors-black-light) solid"}
            paddingX={"0.75rem"}
            paddingTop={"0.75rem"}
            paddingBottom={"0.5rem"}
            bg={"white"}
            zIndex={1000}
        >
            <MenuItem
                name="Explore"
                path="/map"
                icon={ExploreIcon}
                currPath={currPath}
            />
            <MenuItem
                name="Chats"
                path="/chats"
                icon={ChatsIcon}
                currPath={currPath}
                newMsg={msgNotification ? msgNotification : 0}
            />

            <Button onClick={onOpen} bg={"white!important"}>
                {PlusIcon}
            </Button>

            <Drawer
                placement={"right"}
                onClose={handleClose}
                isOpen={isOpen}
                size={"full"}
            >
                <DrawerOverlay bg={"white"} />
                <DrawerContent bg={"white"}>
                    <DrawerHeader borderBottomWidth="1px">
                        <Text variant={"tileTitle"}>
                            Create a new {formType}
                        </Text>
                        <DrawerCloseButton />
                    </DrawerHeader>

                    <DrawerBody padding={"0"}>
                        <Tabs
                            isFitted
                            onChange={handleFormChange}
                            index={tabIndex}
                        >
                            <TabList>
                                <Tab>
                                    <Text variant={"soft"}>Events</Text>
                                </Tab>
                                <Tab>
                                    <Text variant={"soft"}>Group chats</Text>
                                </Tab>
                                {/* <Tab>
                  <Text variant={"soft"} >
                    Locations
                  </Text>
                </Tab> */}
                                <Tab>
                                    <Text variant={"soft"}>Profile</Text>
                                </Tab>
                            </TabList>
                            <TabPanels
                                h={ht - 80 + "px"}
                                w={"100%"}
                                padding={"0"}
                            >
                                <TabPanel p={0}>
                                    <EventForm
                                        eventDescription={eventDescription}
                                        setEventDescription={setEventDescription}
                                        eventTags={eventTags}
                                        setEventTags={setEventTags}
                                        startDate={startDate}
                                        setStartDate={setStartDate}
                                        endDate={endDate}
                                        setEndDate={setEndDate}
                                        eventName={eventName}
                                        setEventName={setEventName}
                                        eventLocation={eventLocation}
                                        setEventLocation={setEventLocation}
                                        profileId={eventProfile}
                                        setProfileId={setEventProfile}
                                        cord={cord}
                                        setCord={setCord}
                                        eventLocationName={eventLocationName}
                                        setEventLocationName={
                                            setEventLocationName
                                        }
                                    />
                                </TabPanel>
                                <TabPanel p={0}>
                                    <GroupForm
                                        groupName={groupName}
                                        setGroupName={setGroupName}
                                        groupDescription={groupDescription}
                                        setGroupDescription={
                                            setGroupDescription
                                        }
                                        selectedFriends={selectedFriends}
                                        setSelectedFriends={setSelectedFriends}
                                        myProfileId={myProfileId}
                                        setMyProfileId={setMyProfileId}
                                    />
                                </TabPanel>
                                <TabPanel p={0}>
                                    <ProfileForm
                                        profileId={""}
                                        profileAvatar={
                                            profileAvatar ||
                                            new File([""], "defaultAvatar.png")
                                        }
                                        setProfileAvatar={
                                            setProfileAvatar ||
                                            ((file: File) => { })
                                        }
                                        profileName={profileName}
                                        setProfileName={setProfileName}
                                        profileDescription={profileDescription}
                                        setProfileDescription={
                                            setProfileDescription
                                        }
                                        profileBackground={profileBackground}
                                        setProfileBackground={
                                            setProfileBackground
                                        }
                                        nameOnProfile={nameOnProfile}
                                        setNameOnProfile={setNameOnProfile}
                                    />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </DrawerBody>
                    <DrawerFooter borderTopWidth="1px">
                        <Button
                            variant="outline"
                            mr={3}
                            onClick={() => {
                                if (!isClosing) {
                                    setIsClosing(true);
                                    handleClose();
                                }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button colorScheme="blue" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>

            <MenuItem
                name="Contacts"
                path="/contacts"
                icon={ContactsIcon}
                currPath={currPath}
                newMsg={friendNotification ? friendNotification : 0}
            />

            {
                userID ? <MenuItem
                    name="Me"
                    path="/home"
                    icon={ProfileIcon}
                    currPath={currPath}
                /> : <MenuItem
                    name="Me"
                    path="/welcome"
                    icon={ProfileIcon}
                    currPath={currPath}
                />
            }

        </Flex>
    );
};

export default Menu;
