import { Box } from "@chakra-ui/react";
import { ChatRoomView } from "Views";
import React, { FC } from "react";
type Props = {};

const ChatListPage: FC<Props> = (props: Props) => {
  return (
    <Box>
      <ChatRoomView />
    </Box>
  );
};

export default ChatListPage;
