import {
  Box, Flex, Heading, IconButton, Button, useToast, Text, useDisclosure, Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { EditIcon, CopyIcon, ChatIcon, AddIcon } from "@chakra-ui/icons";
import { LabeledIcon, ProfileAvatar, ProfileHeader, ProfileForm } from "Components";
import { Profiles as ProfilesType } from "Views/FullProfileView";
import React, { FC, useEffect, useState } from "react";
import { supabase } from "Services/supabase";
import { useRecoilState } from "recoil";
import { friendsState, profilesIDs, profilesState } from "recoilStore/store";

// import {
//   faFireFlameCurved,
//   faThumbsUp,
// } from "@fortawesome/free-solid-svg-icons";

type Props = {
  profileData: ProfilesType;
  handleTopLeftButtonClick?: () => void;
  myProfileId?: string;
  profiletype?: string;
};

const FullProfile: FC<Props> = ({ profileData, handleTopLeftButtonClick, profiletype, myProfileId }: Props) => {
  const [profilesRecoilState, changeProfiles] = useRecoilState(profilesState);
  const [friends, setFriends] = useRecoilState(friendsState);
  const [profileIds, setProfileIds] = useRecoilState(profilesIDs);

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [profileAvatar, setProfileAvatar] = useState<File>();
  const [profileName, setProfileName] = useState<string>('');
  const [profileDescription, setProfileDescription] = useState<string>('');
  const [profileBackground, setProfileBackground] = useState<string>("#5C7BA1");
  const [nameOnProfile, setNameOnProfile] = useState<string>('');
  const [textColor, setTextColor] = useState<string>('#efefef'); // setting a default value

  const getBrightness = (hexColor: string) => {
    const r = parseInt(hexColor.substring(1, 3), 16);
    const g = parseInt(hexColor.substring(3, 5), 16);
    const b = parseInt(hexColor.substring(5, 7), 16);
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  useEffect(() => {
    if (profileData && profileData.background_color) {
      const brightness = getBrightness(profileData.background_color);
      const color = brightness < 128 ? "#efefef" : "#2e2e2e";
      setTextColor(color);
    }
  }, [profileData.background_color]);

  const toast = useToast();

  const handleCopyClick = () => {
    navigator.clipboard.writeText(profileData.text_identifier || profileData.num_identifier)
      .then(() => {
        toast({
          position: "top",
          title: "Copied",
          description: "Identifier copied to clipboard",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch(err => {
        toast({
          position: "top",
          title: "Error",
          description: "Failed to copy text identifier",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const uploadImage = async (image: any, profileId: any) => {
    // console.log(image, profileId)
    const { data, error } = await supabase.storage.from('avatars').remove([`${profileId}.jpg`, `${profileId}.png`]);
    if (error) {
      console.log(error);
    } else {
      if (image && profileId) {
        // filePath as jpg or png
        let filePath = ""
        if (image.type === "image/jpeg") {
          filePath = `${profileId}.jpg`;
        } else if (image.type === "image/png") {
          filePath = `${profileId}.png`;
        } else {
          console.error("Image type not supported.");
          return;
        }

        let { data, error } = await supabase.storage.from('avatars').upload(filePath, image);
        if (error) {
          console.log(error);

          toast({
            position: "top",
            title: "Error",
            description: "Failed to upload image",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else {
          // change avatar in profiles table
          const data = await supabase
            .from('profiles')
            .update({
              avatar: filePath,
            }).eq('id', profileId).then((data) => {
              // console.log(data);
              // update recoil state
              const index = profilesRecoilState.findIndex(profile => profile.id === profileId);
              const updatedProfiles = [...profilesRecoilState];
              updatedProfiles[index] = { ...updatedProfiles[index], avatar: `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/avatars/${filePath}` };
              changeProfiles(updatedProfiles);
            })
        }
      }
    }
  }

  const handleSubmit = async () => {
    // update profile
    if (profileAvatar) {
      await uploadImage(profileAvatar, profileData.id);
    }

    const updates = {
      profile_name: profileName,
      description: profileDescription,
      background_color: profileBackground,
      name_on_profile: nameOnProfile,
    };

    await supabase
      .from("profiles")
      .update(updates)
      .eq("id", profileData.id)
      .then(({ data, error }) => {
        if (error) {
          toast({
            position: "top",
            title: "Update Failed",
            description: "Failed to update profile.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }

        // update recoil state
        const index = profilesRecoilState.findIndex(profile => profile.id === profileData.id);
        const updatedProfiles = [...profilesRecoilState];
        updatedProfiles[index] = { ...updatedProfiles[index], ...updates };
        changeProfiles(updatedProfiles);

        toast({
          position: "top",
          title: "Profile Updated",
          description: "Your profile has been successfully updated.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        closeDrawer();
      });
  }

  const closeDrawer = () => {
    setProfileAvatar(undefined);
    setProfileName('');
    setProfileDescription('');
    setProfileBackground('#5C7BA1');
    setNameOnProfile('');
    onClose();
  };

  // const simplifyNumber: Function = (): string => {
  //   let str = profileData.popularity.toString();
  //   let start = str.slice(0, 3);
  //   let dec = str.slice(3, 4);
  //   return (
  //     start +
  //     "." +
  //     dec +
  //     (profileData.popularity > 1000
  //       ? "K"
  //       : profileData.popularity > 1000000
  //       ? "M"
  //       : "")
  //   );
  // };

  const generateTopRightButton = () => {
    const topButton: JSX.Element[] = [];

    const isFriend = friends.find(f => f.friend_profile_id === profileData.id);

    const isMyProfile = profileIds.find(id => id === profileData.id);

    if (isFriend) {
      topButton.push(
        <IconButton
          aria-label='Search database'
          icon={<ChatIcon />}
          position="absolute"
          top="0"
          right="0"
          zIndex={3}
          colorScheme="white"
          size={"lg"}
          onClick={() => { }}
        />
      )
    } else if (myProfileId) {
      topButton.push(
        <IconButton
          aria-label='Search database'
          icon={<AddIcon />}
          position="absolute"
          top="0"
          right="0"
          zIndex={3}
          colorScheme="white"
          size={"lg"}
          onClick={handleTopLeftButtonClick}
        />
      )
    } else if (isMyProfile) {
      topButton.push(
        <IconButton
          aria-label='Search database'
          icon={<EditIcon />}
          position="absolute"
          top="0"
          right="0"
          zIndex={3}
          colorScheme="white"
          size={"lg"}
          onClick={onOpen}
        />
      )
    } else {
      topButton.push(
        <></>
      )
    }

    return topButton;
  }

  return (
    <Box
      px={"4vw"}
      pt={"4vw"}
      pb={"12vw"}
      w={"100%"}
      h={"100%"}
      borderRadius="1rem"
      overflow="hidden"
    >
      <Flex
        h="25%"
        borderTopRadius="1rem"
        position="relative"
        backgroundColor={"#666666"}
        backgroundSize="cover"
        px="2vw"
      >
        <Flex
          flexDir="row"
          flexGrow={1}
          justifyContent="space-between"
          mb="-1rem"
          gap="2vw"
          px="2vw"
          h="25%"
          alignItems="flex-start"
          margin="auto auto 1.5rem auto"
        >
          <Box h="5rem" w="5rem" zIndex={2}>
            <ProfileAvatar userId={profileData.id} chatRoomName={profileData.name_on_profile} />
          </Box>

          {
            profileData ? generateTopRightButton() : <></>
          }

          <Drawer
            placement={"right"}
            isOpen={isOpen}
            size={"full"}
            onClose={closeDrawer}
          >
            <DrawerOverlay bg={"white"} />
            <DrawerContent bg={"white"} >
              <DrawerCloseButton />
              <DrawerHeader>Edit your profile</DrawerHeader>

              <DrawerBody>
                <ProfileForm
                  profileId={profileData.id}
                  profileAvatar={profileAvatar || new File([""], "defaultAvatar.png")}
                  setProfileAvatar={setProfileAvatar || ((file: File) => { })}
                  profileName={profileData.profile_name}
                  setProfileName={setProfileName}
                  profileDescription={profileData.description}
                  setProfileDescription={setProfileDescription}
                  profileBackground={profileBackground}
                  setProfileBackground={setProfileBackground}
                  nameOnProfile={profileData.name_on_profile}
                  setNameOnProfile={setNameOnProfile}
                />
              </DrawerBody>

              <DrawerFooter>
                <Button variant='outline' mr={3} onClick={closeDrawer}>
                  Cancel
                </Button>
                <Button colorScheme='blue' onClick={handleSubmit}>Submit</Button> {/* Step 3 */}
              </DrawerFooter>
            </DrawerContent>
          </Drawer>

          <Flex flexDir="row" flexGrow={1} gap="2vw" alignItems="center">
            <Box flexGrow={1}>
              <ProfileHeader
                username={profileData.name_on_profile}
              // level={1}
              // UCN={profileData.num_identifier}
              />
            </Box>
            {/* <Flex flexDir={"row"} gap={"2vw"}>
              <LabeledIcon
                icon={faFireFlameCurved}
                label={simplifyNumber(profileData.popularity)}
              />
              <LabeledIcon
                icon={faThumbsUp}
                label={simplifyNumber(profileData.likes)}
              />
            </Flex> */}
          </Flex>
        </Flex>
        <Box
          h="2px"
          w="100%"
          backgroundColor="white.grey"
          pos="absolute"
          bottom="0"
          left="0"
        />
      </Flex>
      <Box
        h={"75%"}
        bg={profileData.background_color}
        px="2vw"
        py="4vw"
        borderBottomRadius="1rem"
        position={"relative"}
      >
        <Box
          padding={"3vw"}
          paddingTop={"2rem"}
          height={"85%"}
          overflowY={"scroll"}
        >
          <Heading color={textColor} fontSize={"16px"}>About Me:</Heading>

          <Box mt={4}>
            <Text
              fontSize={"14px"}
              color={textColor}
            >
              {profileData.description}
            </Text>
          </Box>
        </Box>

        <Box
          position="absolute"
          bottom="1rem"
          left="1rem"
          right="1rem"
          display={"flex"}
          justifyContent={"center"}
        >
          <Button
            onClick={handleCopyClick}
            width={"100%"}
          >
            {profileData.text_identifier || profileData.num_identifier}
            <CopyIcon ml={2} />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FullProfile;
