import { Card, Text, Flex, Icon, Drawer, useToast, Textarea, Box, useDisclosure, DrawerBody, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, Button } from '@chakra-ui/react'
import React, { FC, useState } from 'react'
import { supabase } from 'Services/supabase'
import { useRecoilState } from "recoil";
import { userID } from "recoilStore/store";
type Props = {}

const RateTile: FC<Props> = (props: Props) => {
  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [q1value, setQ1Value] = useState("")
  const [q2value, setQ2Value] = useState("")
  const [userIDValue, setUserIDValue] = useRecoilState(userID)

  const openAboutUs = () => {
    // console.log("openAboutUs")
    onOpen()
  }

  const handleQ1InputChange = (e: any) => {
    setQ1Value(e.target.value)
  }

  const handleQ2InputChange = (e: any) => {
    setQ2Value(e.target.value)
  }

  const handleSubmission = () => {
    const q1 = q1value
    const q2 = q2value

    const insertFeedback = async () => {
      const { data, error } = await supabase
        .from('feedbacks')
        .insert([
          { creator_id: userIDValue, q_one: q1, q_two: q2 }
        ])

      if (error) {
        console.log(error)
      } else {
        toast({
          title: "Feedback submitted.",
          description: "Thank you for your feedback!",
          status: "success",
          duration: 5000,
          isClosable: true,
        })

        setQ1Value("")
        setQ2Value("")

        onClose()
      }
    }

    insertFeedback()
  }

  return (
    <>
      <Card
        w={"100%"}
        h={"100%"}
        borderRadius={"10px"}
        boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
        backgroundColor={"blue.UC"}
        onClick={openAboutUs}
      >
        <Flex
          w={"100%"}
          h={"80%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Icon
            viewBox="0 0 53 45"
            w={"60%"}
            h={"60%"}
          >
            <svg width="53" height="45" viewBox="0 0 53 45" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_105_2530)">
                <path d="M11.4286 30.2857C14.19 30.2857 16.4286 28.0471 16.4286 25.2857C16.4286 22.5243 14.19 20.2857 11.4286 20.2857C8.66715 20.2857 6.42857 22.5243 6.42857 25.2857C6.42857 28.0471 8.66715 30.2857 11.4286 30.2857Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <mask id="path-2-inside-1_105_2530" fill="white">
                  <path d="M22.7143 44.2857V40.2338C22.7143 38.5805 22.1273 36.9949 21.0825 35.8258C20.0376 34.6568 18.6205 34 17.1429 34H6C4.52237 34 3.10525 34.6568 2.06041 35.8258C1.01556 36.9949 0.428572 38.5805 0.428572 40.2338V44.2857" />
                </mask>
                <path d="M22.7143 44.2857V40.2338C22.7143 38.5805 22.1273 36.9949 21.0825 35.8258C20.0376 34.6568 18.6205 34 17.1429 34H6C4.52237 34 3.10525 34.6568 2.06041 35.8258C1.01556 36.9949 0.428572 38.5805 0.428572 40.2338V44.2857" fill="white" />
                <path d="M20.7143 44.2857C20.7143 45.3903 21.6097 46.2857 22.7143 46.2857C23.8189 46.2857 24.7143 45.3903 24.7143 44.2857H20.7143ZM17.1429 34V32V34ZM6 34V32V34ZM0.428572 40.2338H-1.57143H0.428572ZM-1.57143 44.2857C-1.57143 45.3903 -0.675998 46.2857 0.428572 46.2857C1.53314 46.2857 2.42857 45.3903 2.42857 44.2857H-1.57143ZM24.7143 44.2857V40.2338H20.7143V44.2857H24.7143ZM24.7143 40.2338C24.7143 38.118 23.9652 36.05 22.5737 34.4931L19.5912 37.1586C20.2894 37.9398 20.7143 39.0429 20.7143 40.2338H24.7143ZM22.5737 34.4931C21.1756 32.9288 19.2281 32 17.1429 32V36C18.0129 36 18.8996 36.3848 19.5912 37.1586L22.5737 34.4931ZM17.1429 32H6V36H17.1429V32ZM6 32C3.9148 32 1.96726 32.9288 0.569192 34.4931L3.55162 37.1586C4.24324 36.3848 5.12993 36 6 36V32ZM0.569192 34.4931C-0.822301 36.05 -1.57143 38.118 -1.57143 40.2338H2.42857C2.42857 39.0429 2.85342 37.9398 3.55162 37.1586L0.569192 34.4931ZM-1.57143 40.2338V44.2857H2.42857V40.2338H-1.57143Z" fill="white" mask="url(#path-2-inside-1_105_2530)" />
                <path d="M50 0H28.6667C27.1933 0 26.0133 1.19333 26.0133 2.66667V21L24 26L31.3333 21.3333H50C51.4733 21.3333 52.6667 20.14 52.6667 18.6667V2.66667C52.6667 1.19333 51.4733 0 50 0ZM31.3333 16V12.7L40.5067 3.52667C40.7667 3.26667 41.1867 3.26667 41.4467 3.52667L43.8067 5.88667C44.0667 6.14667 44.0667 6.56667 43.8067 6.82667L34.6333 16H31.3333ZM47.3333 16H37.3333L40 13.3333H47.3333V16Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_105_2530">
                  <rect width="53" height="45" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Icon>
        </Flex>
        <Flex
          textAlign={"center"}
          flexDirection={"column"}
          alignItems={"center"}
          position={"relative"}
          bottom={"0.5rem"}
        >
          <Text
            variant={"RateTitle"}
          >
            Suggestions
          </Text>
        </Flex>
      </Card>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        size={"full"}
      >
        <DrawerOverlay bg="white" />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Text variant={"soft"}>Suggestions</Text>
          </DrawerHeader>

          <DrawerBody
            backgroundColor={"blue.UC"}
            color={"white.UC"}
          >
            <Box
              backgroundColor={"white.UC"}
              marginTop={"1rem"}
              padding={"1rem"}
              borderRadius={"10px"}
            >
              <Text
                variant={"soft"}
                fontSize={"14px"}
                fontStyle={"normal"}
                color={"blue.UC"}
              >
                Your input is invaluable in helping us improve UniConnect and shape it into a platform that best serves your needs. It helps us refine features, spot gaps, and even come up with cool new stuff you might not have known you needed! So, really, thank you for being an active part of our community and contributing to our collective journey toward something awesome. Cheers!              </Text>
            </Box>

            <Box
              marginTop={"1rem"}
              padding={"0.5rem"}
            >
              <Text mb='8px'>What do you think about this app so far?</Text>
              <Textarea
                value={q1value}
                onChange={handleQ1InputChange}
                placeholder='tell me how much you love me :) or not :('
                size='sm'
                backgroundColor={"white.UC"}
                color={"black.UC"}
                borderRadius={"10px"}
                height={"10rem"}
              />
            </Box>

            <Box
              marginTop={"0.5rem"}
              padding={"0.5rem"}
            >
              <Text mb='8px'>How can we improve it?</Text>
              <Textarea
                value={q2value}
                onChange={handleQ2InputChange}
                placeholder="I'll try my best to make it happen!"
                size='sm'
                backgroundColor={"white.UC"}
                color={"black.UC"}
                borderRadius={"10px"}
                height={"10rem"}
              />
            </Box>

            <Box
              padding={"0.5rem"}
              width={"100%"}
            >
              <Button
                marginTop={"1rem"}
                backgroundColor={"green.UC"}
                color={"white.UC"}
                borderRadius={"10px"}
                onClick={handleSubmission}
                width={"100%"}
              >
                Submit
              </Button>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default RateTile