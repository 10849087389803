import React, { FC, useEffect, useState } from "react";
import {
    Box,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    TabPanel,
    TabPanels,
    Tabs,
    useDisclosure,
    Flex,
    Text,
    FormControl,
    Switch
} from "@chakra-ui/react";
import {
    ChatRoomHeader,
    ChatSettingViewer,
    ContactsLists,
    ConversationViewer,
    DefaultTitle,
    NewMsgMarker,
    ProfileViewer,
    RequestsLists
} from "Components";

import { useRecoilState } from "recoil";
import { friendNotificationNum, userID } from "recoilStore/store";

type Props = {};

const ContactsView: FC<Props> = (props: Props) => {
    const [chatId, setChatId] = React.useState("");
    const [tabIndex, setTabIndex] = React.useState(0);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [contactType, setContactType] = useState("Show new requests"); // ["friends", "requests"]
    const [userId, setUserId] = useRecoilState(userID);

    const [friendNotification, setFriendNotification] = useRecoilState(friendNotificationNum);

    const ht = window.innerHeight;

    const handleClick = (newId: string) => {
        setChatId(newId);
        onOpen();
    };

    const handleTabsChange = (index: number) => {
        setTabIndex(index);
    };

    return (
        <Box backgroundColor={"white.UC"}>
            {
                !userId ? <>
                    <Box
                        width={"100%"}
                        height={"100%"}
                        position={"fixed"}
                        textAlign={"center"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        top={"50%"}
                    >
                        <Text>
                            Please sign in to view chats
                        </Text>
                    </Box>
                </> : <></>
            }
            <Tabs
                variant="unstyled"
                p={0}
                index={tabIndex}
                onChange={handleTabsChange}
            >
                <Box
                    backgroundColor={"white"}
                    h={"70px"}
                >
                    <Flex
                        w="100%"
                        h="70px"
                        alignItems="center"
                        p={4}
                        borderBottom="1px solid #CDCDCD"
                        background={"white"}
                    >
                        <Text variant={"tileTitle"}>
                            {contactType}
                        </Text>
                        <Box
                            position="relative"
                            pl={"1rem"}
                            zIndex={3}
                        >
                            <NewMsgMarker numberOfNewMsg={friendNotification ? friendNotification : 0} />
                        </Box>

                        <Switch
                            ml="auto"
                            size='lg'
                            isChecked={contactType === "Show my friends only"}
                            onChange={() => {
                                setFriendNotification(0);
                                setContactType(contactType === "Show new requests" ? "Show my friends only" : "Show new requests");
                            }}
                        />
                    </Flex>
                </Box>
                {
                    contactType === "Show new requests" ? (
                        <ContactsLists
                            handleClick={handleClick}
                        />
                    ) : <RequestsLists />
                }
                <Drawer
                    onClose={onClose}
                    isOpen={isOpen}
                    size={"full"}
                    isFullHeight={true}
                    id={"chat" + chatId}
                >
                    <DrawerOverlay bg={"white"} />
                    <DrawerContent>
                        <ChatRoomHeader onClose={onClose} chatId={chatId} profileId=""/>
                        <TabPanels h={"100%"} w={"100%"} padding={"0"}>
                            <TabPanel p={"0"} h={"100%"}>
                                <ConversationViewer chatId={chatId} />
                            </TabPanel>
                            <TabPanel p={"0"} h={"100%"}>
                                <ProfileViewer chatId={chatId} />
                            </TabPanel>
                            <TabPanel p={"0"} h={"100%"}>
                                <ChatSettingViewer chatId={chatId} />
                            </TabPanel>
                        </TabPanels>
                    </DrawerContent>
                </Drawer>
            </Tabs>
        </Box>
    );
};

export default ContactsView;
