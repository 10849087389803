import React, { FC, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { Box, Flex, Text, UnorderedList, ListItem } from '@chakra-ui/react'
import { supabase } from 'Services/supabase'
import { roomProfilesState } from 'recoilStore/store'
import { Avatar } from '@chakra-ui/react'

type Props = {
    event: any
}

const AttendanceViewer: FC<Props> = ({ event }: Props) => {
    const [attendees, setAttendees] = useState<Set<any>>(new Set())
    const [attendeesProfiles, setAttendeesProfiles] = useState<any[]>([])

    const [roomProfiles, setRoomProfiles] = useRecoilState(roomProfilesState);

    useEffect(() => {
        console.log(event.event_id)
        const getAttendees = async () => {
            const { data, error } = await supabase
                .rpc('get_attendees_for_event',
                    { p_event_id: event.event_id })
            if (error) {
                console.log(error)
            } else {
                // data is list of profile ids, convert it to set of profile ids    
                console.log("Attendess: ", data)
                setAttendees(new Set(data))
            }
        }
        getAttendees()

    }, [event])

    useEffect(() => {
        const allProfiles = roomProfiles[event.chat_id] || []
        console.log("All Profiles: ", allProfiles)

        const attendeesProfiles = allProfiles.filter((profile) => {
            return attendees.has(profile.profile_id)
        })

        console.log("Attendees Profiles: ", attendeesProfiles)

        setAttendeesProfiles(attendeesProfiles)
       
        
    }, [attendees, roomProfiles])

    return (
        <Box>
            <UnorderedList>
                {attendeesProfiles.map((profile) => {
                    return (
                        <ListItem key={profile.profile_id} style={{ listStyleType: 'none' }}>
                            <Flex alignItems="center">
                                <Avatar
                                    key={profile.profile_id}
                                    name={profile.name_on_profile}
                                    src={"https://zzwxyobwfvjshssazycj.supabase.co/storage/v1/object/public/avatars/" + profile.avatar}
                                    color={"purple.UC"}
                                    backgroundColor={"white.UC"}
                                    fontWeight={"bold"}
                                />
                                <Text marginLeft={2}>{profile.name_on_profile}</Text>
                            </Flex>
                        </ListItem>
                    )
                })}
            </UnorderedList>
        </Box>
    )
}

export default AttendanceViewer
