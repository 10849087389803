import { Box, Flex, Text } from "@chakra-ui/react";
import React, { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import { NewMsgMarker } from "Components";
import { useRecoilState } from "recoil";
import { msgNotificationNum, friendNotificationNum, profilesIDs, roomsState, unread_messages } from "recoilStore/store";
import { supabase } from "Services/supabase";

// Type ALL props, this helps reduce the need for smoke tests and prevents datartype errors ont he app.
// Typing the props also allows Editors to provide better suggestions.
type Props = {
  name: string;
  path: string;
  icon: ReactNode;
  currPath: string;
  newMsg?: number;
};

type unread_messagesType = {
  friends_unread: number;
  groups_unread: number;
  events_unread: number;
  location_unread: number;
}

// NO Class Components unless 1000% needed!
const MenuItem: FC<Props> = ({ name, path, icon, currPath, newMsg }: Props) => {
  const [msgNum, setMsgNum] = useRecoilState(msgNotificationNum);
  const [friendNum, setFriendNum] = useRecoilState(friendNotificationNum);
  const [profileIDs, setProfileIDs] = useRecoilState(profilesIDs);
  const [rooms, setRooms] = useRecoilState(roomsState);
  const [unreadMsg, setUnreadMsg] = useRecoilState(unread_messages);

  const readAllUnread = async () => {
    const { data, error } = await supabase.rpc("read_all_unread", {
      p_profile_ids: profileIDs,
    });

    if (error) {
      console.log(error);
    } else {
      setMsgNum(0);
      setFriendNum(0);

      const updatedRooms = rooms.map((room) => {
        return {
          ...room,
          unread_messages: 0,
        };
      });

      setRooms(updatedRooms);

      const updatedUnreadMsg = {
        friends_unread: 0,
        groups_unread: 0,
        events_unread: 0,
        location_unread: 0,
      }

      setUnreadMsg(updatedUnreadMsg as any);
    }
  }
  
  return (
    <Box w={"15% !important"} position="relative">
      <Link to={path}>
        <Flex flexDir={"column"} alignItems={"center"}>
          <Box
            position="absolute"
            top="-0.5rem"
            right="0.3rem"
            zIndex={3}
            onClick={() => {
              readAllUnread();
            }}
          >
            <NewMsgMarker numberOfNewMsg={newMsg || 0} />
          </Box>
          {icon}
          <Text
            variant={"soft"}
            fontSize={"sm"}
            color={"/" + currPath === path ? "green.UC" : "purple.UC"}
          >
            {name}
          </Text>
        </Flex>
      </Link>
    </Box>
  );
};

export default MenuItem;
