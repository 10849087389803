import React, { FC, useEffect, useState } from "react";
import { Box, Drawer, useToast, Text, useDisclosure, DrawerBody, DrawerOverlay, DrawerContent, DrawerHeader, DrawerFooter, Button } from "@chakra-ui/react";
import { ProfilePreviewCard, ProfileForm } from "Components";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { profilesState, profilesIDs, userIDState } from "recoilStore/store";
import { supabase } from "Services/supabase";

type Props = {};

type Profile = {
  avatar: string;
  created_at: string;
  description: string;
  id: string;
  name_on_profile: string;
  owner: string;
  profile_name: string;
  background_color: string;
  text_identifier: string;
  num_identifier: string;
};

const ProfilesStack: FC<Props> = (props: Props) => {
  const [profiles, setProfiles] = useRecoilState(profilesState);
  const [localProfiles, setLocalProfiles] = useState<Profile[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const [profileAvatar, setProfileAvatar] = useState<File>();
  const [profileName, setProfileName] = useState<string>('');
  const [profileDescription, setProfileDescription] = useState<string>('');
  const [profileBackground, setProfileBackground] = useState<string>("#5C7BA1");
  const [nameOnProfile, setNameOnProfile] = useState<string>('');

  const [profilesIDsState, setProfilesIDsState] = useRecoilState(profilesIDs);
  const [profilesRecoilState, setProfilesRecoilState] = useRecoilState(profilesState);
  const [userID, setUserID] = useRecoilState(userIDState);

  const handleSubmit = async () => {
    await handleProfileSubmit();
  };

  async function uploadImage(image: any, profileId: any) {
    // console.log(image, profileId)
    if (image && profileId) {
      // filePath as jpg or png
      let filePath = ""
      if (image.type === "image/jpeg") {
        filePath = `${profileId}.jpg`;
      } else if (image.type === "image/png") {
        filePath = `${profileId}.png`;
      } else {
        console.error("Image type not supported.");
        return;
      }

      let { data, error } = await supabase.storage.from('avatars').upload(filePath, image);
      if (error) {
        console.log(error);

        toast({
          position: "top",
          title: "Update Failed",
          description: "Failed to update avatar.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        const data = await supabase
          .from('profiles')
          .update({
            avatar: filePath,
          }).eq('id', profileId).then((data) => {
            // console.log(data);
          })
      }
    }
  }

  async function createProfile(
    userId: string,
    profileName: string,
    profileDescription: string,
    profileBackground: string,
    nameOnProfile: string,
    profileAvatar: File
  ) {
    try {
      const { data, error } = await supabase.rpc('create_profile', {
        profile_owner: userId,
        profile_name: profileName,
        profile_description: profileDescription,
        profile_background: profileBackground.toUpperCase(),
        name_on_profile: nameOnProfile,
      });

      if (error) {
        console.error("Error creating profile:", error);
      } else {
        const profile_id = data.new_id;

        await uploadImage(profileAvatar, profile_id);

        toast({
          position: "top",
          title: "Profile created.",
          description: "Your profile has been successfully created.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        // update profileIDs, profilesState
        const newProfilesIDs = [...profilesIDsState, profile_id];
        setProfilesIDsState(newProfilesIDs as any);

        // update profilesState
        updateProfiles(profile_id as any);
      }
    } catch (err) {
      console.error("Unexpected error:", err);
    }
  }

  const updateProfiles = (profile_id: string) => {
    let filePath = ""

    if (profileAvatar?.type === "image/jpeg") {
      filePath = `${profile_id}.jpg`;
    } else if (profileAvatar?.type === "image/png") {
      filePath = `${profile_id}.png`;
    }

    console.log("background1", profileBackground.toUpperCase());

    const newProfiles = [...profilesRecoilState, {
      id: profile_id,
      name: profileName,
      description: profileDescription,
      background_color: profileBackground.toUpperCase(),
      avatar: `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/avatars/${filePath}`,
      name_on_profile: nameOnProfile,
      owner: userID,
    }];
    setProfilesRecoilState(newProfiles as any);
  }

  const handleProfileSubmit = async () => {
    // prevent default form submission
    // console.log(userID, profileName, profileDescription, profileBackground, nameOnProfile);
    if (profileAvatar) {
      await createProfile(userID, profileName, profileDescription, profileBackground, nameOnProfile, profileAvatar);
    } else {
      console.error("Profile avatar is not provided.");
      toast({
        position: "top",
        title: "Update Failed",
        description: "Failed to update avatar.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    onClose();  // Close the drawer
  };

  useEffect(() => {
    setLocalProfiles(profiles);
  }, [profiles]);

  const dw = window.outerWidth;
  return (
    <Box
      height={"100%"}
      width={"100%"}
    >
      {
        profiles.length > 0 ?
          <Box
            w={dw * 0.92 + "px"}
            h={dw * 0.92 + "px"}
            borderRadius={"20px"}
            position={"relative"}
          >
            {
              localProfiles.map((profile, index) => {
                // console.log(profile)
                return (
                  <Link to="/profile">
                    <ProfilePreviewCard
                      name={profile.name_on_profile}
                      image={profile.avatar}
                      backgroundColor={profile.background_color}
                      num={index}
                      total={profiles.length}
                    />
                  </Link>
                )
              })
            }
          </Box>
          :
          <Box
            padding={"1rem 0 0 0"}
          >
            <Text variant={"Welcome"}>
              Welcome to <span style={{ color: "#73B3A3", fontSize: "1.5rem" }}>Uni</span><span style={{ color: "#5B5081", fontSize: "1.5rem" }}>Connect</span>! Here, we empower you to
              <span style={{ backgroundColor: "#73B3A3", padding: "2px", borderRadius: "2px", fontSize: "1.25rem" }}>"Live in the Moment and Safely Connect with Your Surroundings."</span>
            </Text>
            <Text variant={"Welcome"}>
              Take the first step by creating your <span style={{ backgroundColor: "#5B5081", color: "white", padding: "2px" }}>profile</span>,
              and unlock a world where <span style={{ backgroundColor: "#73B3A3", padding: "2px" }}>social connections</span> and
              <span style={{ backgroundColor: "#770000", color: "white", padding: "2px", fontSize: "1.25rem" }}>security</span> go hand in hand.
            </Text>
            <Text variant={"Welcome"}>
              Let's get <span style={{ color: "#73B3A3", fontSize: "1.75rem" }} onClick={onOpen}>started</span> by clicking on the round add button!
            </Text>
          </Box>
      }
      <Drawer
        placement={"right"}
        onClose={onClose}
        isOpen={isOpen}
        size={"full"}
      >
        <DrawerOverlay bg={"white"} />
        <DrawerContent bg={"white"}>
          <DrawerHeader borderBottomWidth="1px" borderBottomColor={"gray.300"}>
            <Text variant={"drawerTitle"}>
              Create your first Profile
            </Text>
          </DrawerHeader>
          <DrawerBody>
            <ProfileForm
              profileId={""}
              profileAvatar={profileAvatar || new File([""], "defaultAvatar.png")}
              setProfileAvatar={setProfileAvatar || ((file: File) => { })}
              profileName={profileName}
              setProfileName={setProfileName}
              profileDescription={profileDescription}
              setProfileDescription={setProfileDescription}
              profileBackground={profileBackground}
              setProfileBackground={setProfileBackground}
              nameOnProfile={nameOnProfile}
              setNameOnProfile={setNameOnProfile}
            />
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px" borderTopColor={"gray.300"}>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='blue' onClick={handleSubmit}>Submit</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default ProfilesStack;
