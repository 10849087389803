export const getDeltaTime = (now: Date, then: Date): string => 
((millis: number) => 
  millis < 3600000 ? `${(millis / 60000) | 0} minutes ago` :
  millis < 86400000 ? `${(millis / 3600000) | 0} hours ago` :
  millis < 604800000 ? `${(millis / 86400000) | 0} days ago` :
  millis < 2592000000 ? `${(millis / 604800000) | 0} weeks ago` :
  millis < 31536000000 ? `${(millis / 2592000000) | 0} months ago` :
  `${(millis / 31536000000) | 0} years ago`
)(now.getTime() - then.getTime());

