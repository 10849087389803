import React, { Dispatch, SetStateAction, FC, useState  } from "react";
import { Box, FormControl, FormLabel, Input, Textarea } from "@chakra-ui/react";
import DateTimePicker from "react-datetime-picker";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import SelectMyProfile from "Components/SelectMyProfile";

import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

type DateValuePiece = Date | null;
type DateValue = DateValuePiece | [DateValuePiece, DateValuePiece];

type Props = {
    eventDescription: string;
    setEventDescription: Dispatch<SetStateAction<string>>;
    startDate: DateValuePiece ;
    setEventTags: Dispatch<SetStateAction<string>>;
    eventTags: string;
    setStartDate: Dispatch<SetStateAction<DateValuePiece>>;
    endDate: DateValuePiece;
    setEndDate: Dispatch<SetStateAction<DateValuePiece>>;
    eventName: string;
    setEventName: Dispatch<SetStateAction<string>>;
    eventLocation: string;
    setEventLocation: Dispatch<SetStateAction<string>>;
    profileId: string;
    setProfileId: Dispatch<SetStateAction<string>>;
    cord: { lat: number; lng: number };
    setCord: Dispatch<SetStateAction<{ lat: number; lng: number }>>;
    eventLocationName: string;
    setEventLocationName: Dispatch<SetStateAction<string>>;
};

const EventForm: FC<Props> = ({
    eventDescription, setEventDescription,
    eventTags, setEventTags,
    startDate, setStartDate,
    endDate, setEndDate,
    eventName, setEventName,
    eventLocation, setEventLocation,
    profileId, setProfileId,
    cord, setCord,
    eventLocationName, setEventLocationName}: Props) => {


    const [place, setPlace] = useState<any>(null);

    const handleEventNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEventName(e.target.value);
    };

    const handleEventDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEventDescription(e.target.value);
    };

    const handleEventTagsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEventTags(e.target.value);
    };

    const handleLocationChange = (placeObject: any) => {
        setPlace(placeObject);
        // console.log(placeObject);
        setEventLocation(placeObject?.value?.place_id);
        setEventLocationName(placeObject?.label);

        if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
            console.error('Google Maps JavaScript API is not loaded.');
            return;
        }

        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ placeId : placeObject?.value?.place_id }, (results, status) => {
            if (status === 'OK') {
                if (results && results.length > 0) {
                    setCord({
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng(),
                    });
                }
                // console.log(results);
                
            } else {
                console.log('Geocode was not successful for the following reason: ' + status);
            }
        });
       
    };

    return (
        <Box p={4}>
            <FormControl isRequired>
                <FormLabel>Select Profile</FormLabel>
                <SelectMyProfile
                    setMyProfileId={setProfileId}
                    myProfileId={profileId} />
            </FormControl>
            <FormControl isRequired>
                <FormLabel>Event Name</FormLabel>
                <Input placeholder='Your event name' onChange={handleEventNameChange} />
            </FormControl>

            <FormControl isRequired>
                <FormLabel>Event Description</FormLabel>
                <Textarea
                    placeholder='Your event description'
                    onChange={handleEventDescriptionChange}
                    size="md"
                    h={40}
                />
            </FormControl >

            <FormControl isRequired>
                <FormLabel>Event Tags</FormLabel>
                <Input
                    placeholder='Your event Tag separated by comma'
                    onChange={handleEventTagsChange}
                />
            </FormControl >

            <FormControl isRequired>
                <FormLabel>Start Date</FormLabel>
                <DateTimePicker onChange={setStartDate} value={startDate} />
            </FormControl>

            <FormControl isRequired>
                <FormLabel>End Date</FormLabel>
                <DateTimePicker onChange={setEndDate} value={endDate} />
            </FormControl>

            <FormControl isRequired>
                <FormLabel>Event Location</FormLabel>
                <GooglePlacesAutocomplete
                apiKey="AIzaSyDfaZ84GF-hak7X2koDcHJ6pxIXaXviiMc"
                selectProps={{
                    onChange: handleLocationChange,
                    value: place,
                }}
            />
                
            </FormControl>
        </Box>
    );
}

export default EventForm;