import React from "react";
import PageRouter from "Pages/PageRouter";
import { Helmet } from "react-helmet";
import OneSignal from 'react-onesignal';
import { useRecoilState } from "recoil";
import { oneSignal } from "recoilStore/store";



type Props = {};

const App = (props: Props) => {
    const [oneSignalValue, setOneSignalValue] = useRecoilState(oneSignal);
    OneSignal.init({ appId: "ef55bc05-ead8-4eb6-9c2c-455629e8727c" }).then(() => {
        setOneSignalValue(true);
        OneSignal.Slidedown.promptPush();
    // do other stuff
    }).catch(() => {
        setOneSignalValue(false);
    })

    return (
        <div>
            {/* <WelcomePage /> */}
            {/* <PageRouter /> */}

            <PageRouter />
        </div>
    );
};

export default App;
