import React, { FC, useEffect, useState } from "react";
import { ProfileInfoView, UCAccountView, HomeBottomView } from "Views";
import { Box, Button, useToast } from "@chakra-ui/react";
import { ProfileForm } from "Components";
import { supabase } from "Services/supabase";
import { useRecoilState } from "recoil";
import { profilesIDs, profilesState } from "recoilStore/store";
import { useNavigate } from "react-router-dom";

type Props = {
    userId: string;
};

const ht = window.outerHeight;

const FirstTimePage: FC<Props> = ({ userId }: Props) => {
    const navigate = useNavigate();

    const [profileAvatar, setProfileAvatar] = useState<File>();
    const [profileName, setProfileName] = useState<string>('');
    const [profileDescription, setProfileDescription] = useState<string>('');
    const [profileBackground, setProfileBackground] = useState<string>("#5C7BA1");
    const [nameOnProfile, setNameOnProfile] = useState<string>('');

    const [profilesIDsState, setProfilesIDsState] = useRecoilState(profilesIDs);
    const [profiles, setProfiles] = useRecoilState(profilesState);

    const toast = useToast();

    const updateProfiles = (profile_id: string) => {
        let filePath = ""

        if (profileAvatar?.type === "image/jpeg") {
            filePath = `${profile_id}.jpg`;
        } else if (profileAvatar?.type === "image/png") {
            filePath = `${profile_id}.png`;
        }

        console.log("background1", profileBackground.toUpperCase());

        const newProfiles = [...profiles, {
            id: profile_id,
            name: profileName,
            description: profileDescription,
            background_color: profileBackground.toUpperCase(),
            avatar: `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/avatars/${filePath}`,
            name_on_profile: nameOnProfile,
            owner: userId,
        }];
        setProfiles(newProfiles as any);
    }

    async function uploadImage(image: any, profileId: any) {
        // console.log(image, profileId)
        if (image && profileId) {
            // filePath as jpg or png
            let filePath = ""
            if (image.type === "image/jpeg") {
                filePath = `${profileId}.jpg`;
            } else if (image.type === "image/png") {
                filePath = `${profileId}.png`;
            } else {
                console.error("Image type not supported.");
                return;
            }

            let { data, error } = await supabase.storage.from('avatars').upload(filePath, image);
            if (error) {
                console.log(error);

                toast({
                    position: "top",
                    title: "Update Failed",
                    description: "Failed to update avatar.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                const data = await supabase
                    .from('profiles')
                    .update({
                        avatar: filePath,
                    }).eq('id', profileId).then((data) => {
                        // console.log(data);
                    })
            }
        }
    }

    async function createProfile(
        userId: string,
        profileName: string,
        profileDescription: string,
        profileBackground: string,
        nameOnProfile: string,
        profileAvatar: File
    ) {
        try {
            const { data, error } = await supabase.rpc('create_profile', {
                profile_owner: userId,
                profile_name: profileName,
                profile_description: profileDescription,
                profile_background: profileBackground.toUpperCase(),
                name_on_profile: nameOnProfile,
            });

            if (error) {
                console.error("Error creating profile:", error);
            } else {
                const profile_id = data.new_id;

                await uploadImage(profileAvatar, profile_id);

                toast({
                    position: "top",
                    title: "Profile created.",
                    description: "Your profile has been successfully created.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });

                // update profileIDs, profilesState
                const newProfilesIDs = [...profilesIDsState, profile_id];
                setProfilesIDsState(newProfilesIDs as any);

                // update profilesState
                updateProfiles(profile_id as any);

                // navigate to home page
                navigate("/home");
            }
        } catch (err) {
            console.error("Unexpected error:", err);
        }
    }

    const handleProfileSubmit = async () => {
        // prevent default form submission
        // console.log(userID, profileName, profileDescription, profileBackground, nameOnProfile);
        if (profileAvatar) {
            await createProfile(userId, profileName, profileDescription, profileBackground, nameOnProfile, profileAvatar);
        } else {
            toast({
                position: "top",
                title: "Update Failed",
                description: "Please upload an avatar.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleSubmit = async () => {
        await handleProfileSubmit();
    };

    return (
        <Box
            bg="white"
            w="100%"
            h="100%"
            p="1rem"
            pt="2rem"
            pb={"2rem"}
        >
            <ProfileForm
                profileId={""}
                profileAvatar={profileAvatar || new File([""], "defaultAvatar.png")}
                setProfileAvatar={setProfileAvatar || ((file: File) => { })}
                profileName={profileName}
                setProfileName={setProfileName}
                profileDescription={profileDescription}
                setProfileDescription={setProfileDescription}
                profileBackground={profileBackground}
                setProfileBackground={setProfileBackground}
                nameOnProfile={nameOnProfile}
                setNameOnProfile={setNameOnProfile}
            />
            <Box
                paddingTop={"2rem"}
                w="90%"
                pb={"2rem"}
            >
                <Button w="100%" backgroundColor={"green.UC"} color={"white"} onClick={handleSubmit}>
                    Create My First Profile
                </Button>
            </Box>
        </Box>
    );
};

export default FirstTimePage;
