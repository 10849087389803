import React, { Dispatch, SetStateAction, useEffect, useState, FC } from "react";
import Select from "react-select";
import { useRecoilState } from "recoil";
import { friendsState } from "recoilStore/store";

interface OptionType {
    label: string;
    value: string;
}

interface Props {
    setSelectedFriends: Dispatch<SetStateAction<string[]>>;
    selectedFriends: string[];
}

const ComponentMultiselect: FC<Props> = ({ setSelectedFriends, selectedFriends }: Props) => {
    const [friends, setFriends] = useRecoilState(friendsState);
    const [selectedOptions, setSelectedOptions] = useState<OptionType[] | null>(null);

    const friendOptions: OptionType[] = friends.map((friend) => ({
        label: friend.friend_name_on_profile,
        value: friend.friend_profile_id,
    }));

    const handleChange = (selectedOptions: OptionType[] | null) => {
        setSelectedOptions(selectedOptions);
        const newSelectedFriends = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setSelectedFriends(newSelectedFriends);
    };

    return (
        <Select
            isMulti // enable multi-select
            value={selectedOptions}
            onChange={handleChange as any}  // Cast to 'any' to silence type mismatch error
            options={friendOptions}
        />
    );
};

export default ComponentMultiselect;
