import React, { FC, useEffect, useState } from "react";
import { supabase } from "Services/supabase";
import {
  Box, Switch, Stack, Button, useToast, useDisclosure, ModalHeader,
  ModalFooter, ModalContent,
  ModalBody, Modal, 
  ModalOverlay,
} from "@chakra-ui/react";
import { EventsMapView, EventsListView } from "Views";
import { SelectMyProfile } from "Components";
import { useRecoilState } from "recoil";
import { profileEventMappingState } from "recoilStore/store";

type Props = {};

const MapPage: FC<Props> = (props: Props) => {
  const [location, setLocation] = useState<{ latitude: number, longitude: number } | null>({ latitude: 43.467998128 , longitude: -80.537331184 });
  const [events, setEvents] = useState<Array<any>>([]);

  const [viewMode, setViewMode] = useState("map");
  const [myProfileId, setMyProfileId] = useState<string>("");
  const [profileEventMapping, setProfileEventMapping] = useRecoilState(profileEventMappingState);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [focusEventId, setFocusEventId] = useState<string>("");

  const toggleViewMode = () => {
    setViewMode(viewMode === "map" ? "list" : "map");
  };

  const toast = useToast();

  const fetchEvents = async () => {
    if (!location) return;

    // console.log("in fetch events from: ", location)

    const { latitude, longitude } = location;

    const { data, error } = await supabase.rpc('fetch_events_after_now', {
      long: latitude,
      lat: longitude,
      radius: 100000,
    });

    if (error) {
      console.log("Error fetching events: ", error);
      return;
    }

    setEvents(data);

    // console.log("Events: ", data);

  };

  useEffect(() => {
    // console.log("use effect")

    if ("geolocation" in navigator || location == null) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setLocation({ latitude, longitude });
      }, (error) => {
        console.log("Error getting geolocation: ", error);
      });
    } else {
      console.log("Geolocation is not available in this browser.");
    }

    console.log("The user Location: ", location)
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [location]);

  const ht = window.innerHeight;

  const addEvent = async () => {
    // check if event exists
    const focusedChatId = focusEventId

    if (profileEventMapping) {
      if (profileEventMapping[myProfileId]) {
        const profileEvents = profileEventMapping[myProfileId];
        const profileEventIds = profileEvents.map((event) => event.chat_id);

        if (profileEventIds.includes(focusedChatId)) {
          toast({
            position: "top",
            title: "Already joined event",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          onClose();
          return;
        }
      }
    }

    if (!focusedChatId) {
      toast({
        position: "top",
        title: "No event selected",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const { data, error } = await supabase.rpc('join_group_chat', {
      p_room_id: focusEventId,
      p_profile_id: myProfileId
    });

    if (error) {
      toast({
        position: "top",
        title: "Error joining event",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    } else {
      toast({
        position: "top",
        title: "Joined event",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }

    onClose();
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        position={"fixed"}
        bottom={"90px"}
        left={"0.5rem"}
        zIndex={1000}
        backgroundColor={"white"}
        padding={2}
        borderRadius={8}
      >
        <Box ml={2} mr={4}>Map</Box>
        <Switch isChecked={viewMode === "list"} onChange={toggleViewMode} size="lg" colorScheme="pink" />
        <Box ml={4} mr={2}>List</Box>
      </Box>
      <EventsMapView events={events} location={location} setLocation={setLocation} />

      {
        viewMode !== "map" ? <Stack
          backgroundColor={"white.UC"}
          padding={"1rem"}
          position={"absolute"}
          top={"80px"}
          bottom={"60px"}
          left={"0"}
          right={"0"}
          zIndex={999}
          overflow={"scroll"}
        >
          {
            events ? <EventsListView
              events={events}
              focusEventId={focusEventId || ""}
              setFocusEventId={setFocusEventId}
            /> : <></>
          }
        </Stack> : <></>
      }

      {
        focusEventId !== "" ? <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position={"fixed"}
          bottom={"90px"}
          right={"1.5rem"}
          zIndex={1000}
          backgroundColor={"white"}
          padding={2}
          borderRadius={8}
        >
          <Button backgroundColor={"white"} onClick={onOpen} color={"#73B3A3"}>
            Join
          </Button>
        </Box> : <></>
      }
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
        size={"xs"}
      >
        <ModalOverlay bg="#00000060" />

        <ModalContent
          bg={"white"}
          margin={"auto"}
        >
          <ModalHeader textAlign={"center"}>Select a profile to sign in</ModalHeader>

          <ModalBody
            pb={"2rem"}
          >
            <SelectMyProfile myProfileId={myProfileId} setMyProfileId={setMyProfileId} />
          </ModalBody>
          <ModalFooter p={0} h={"4rem"} borderTop={"1px black solid"}>
            <Button width="50%" onClick={onClose} height={"3rem"} backgroundColor={"white"} borderRight={"1px black solid"} borderRadius={0}>
              Cancel
            </Button>
            <Button width="50%" height={"3rem"} backgroundColor={"white"} onClick={addEvent} color={"#73B3A3"}>
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box >
  );
};

export default MapPage;