import React, { FC } from "react";
import ContactsView from "Views/ContactsView";
import { Box } from "@chakra-ui/react";

type Props = {};

const ContactsPage: FC<Props> = (props: Props) => {
  return (
    <ContactsView />
  );
};

export default ContactsPage;
