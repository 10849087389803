import { Box, Text } from '@chakra-ui/react';

const PrivacyPolicy = () => {
  return (
    <Box p={3} paddingTop={0} height={"60vh"} overflowY={"scroll"}>
      <Text mt={4}>Last Updated: September 29, 2023</Text>

      <Text mt={4} fontWeight="semibold">Welcome to UniConnect</Text>
      <Text mt={2}>UniConnect ("we," "our," or "us") is committed to protecting the privacy of our users ("you"). This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our mobile applications, websites, and related services (collectively, "Services").</Text>

      <Text mt={4} fontWeight="semibold">1. Information We Collect</Text>
      <Text mt={2}>1.1 Personal Information</Text>
      <Text ml={4}>We collect personal information you provide when you register for an account, including but not limited to:</Text>
      <Text ml={6}>- Name</Text>
      <Text ml={6}>- Email Address</Text>
      <Text ml={6}>- Password</Text>
      <Text mt={2}>1.2 Usage Data</Text>
      <Text ml={4}>We may collect data on how you interact with the Services, such as:</Text>
      <Text ml={6}>- IP Address</Text>
      <Text ml={6}>- Device Information</Text>
      <Text ml={6}>- Activity Logs</Text>

      <Text mt={4} fontWeight="semibold">2. How We Use Your Information</Text>
      <Text mt={2}>We may use your information for the following purposes:</Text>
      <Text ml={4}>- Provide, maintain, and improve the Services.</Text>
      <Text ml={4}>- Personalize your user experience.</Text>
      <Text ml={4}>- Send newsletters, promotions, and updates.</Text>
      <Text ml={4}>- Monitor and analyze usage and trends.</Text>

      <Text mt={4} fontWeight="semibold">3. How We Share Your Information</Text>
      <Text mt={2}>We do not sell or share your personal information with third parties unless:</Text>
      <Text ml={4}>- To comply with legal obligations.</Text>
      <Text ml={4}>- To protect and defend the rights of UniConnect and its users.</Text>
      <Text ml={4}>- To engage in business transfers, like mergers or acquisitions.</Text>

      <Text mt={4} fontWeight="semibold">4. Cookies and Tracking Technologies</Text>
      <Text mt={2}>We may use cookies, web beacons, or similar technologies to improve user experience and analyze usage.</Text>

      <Text mt={4} fontWeight="semibold">5. Data Security</Text>
      <Text mt={2}>We implement reasonable security measures to protect your data, although no method of transmission or storage is 100% secure.</Text>

      <Text mt={4} fontWeight="semibold">6. User Rights</Text>
      <Text mt={2}>You have the right to:</Text>
      <Text ml={4}>- Access, correct, or delete your personal information.</Text>
      <Text ml={4}>- Opt-out of marketing communications.</Text>

      <Text mt={4} fontWeight="semibold">7. Children’s Privacy</Text>
      <Text mt={2}>Our Services are not intended for individuals under the age of 13. We do not knowingly collect information from children under 13.</Text>

      <Text mt={4} fontWeight="semibold">8. Changes to This Policy</Text>
      <Text mt={2}>We may update this Privacy Policy at any time. We will notify you of any changes by updating the "Last Updated" date at the top of this Policy.</Text>

      <Text mt={4} fontWeight="semibold">9. Governing Law</Text>
      <Text mt={2}>This Privacy Policy is governed by the laws of the Province of Ontario, Canada.</Text>

      <Text mt={4} fontWeight="semibold">10. Contact Us</Text>
      <Text mt={2}>For any questions about this Privacy Policy, please contact us at [Contact Information].</Text>
    </Box>
  );
};

export default PrivacyPolicy;
