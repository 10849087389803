import {
  Box,
  Text,
  Flex,
  Avatar,
  Heading,
  IconButton,
  Button,
  AvatarGroup,
  useDisclosure,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  useToast,
} from "@chakra-ui/react";
import { AddIcon, CopyIcon, ChatIcon } from "@chakra-ui/icons";
import React, { FC, useEffect, useState } from "react";
import { supabase } from "Services/supabase";
import { useRecoilState } from "recoil";
import { roomProfilesState, roomProfileMappingState, profileEventMappingState } from "recoilStore/store";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot
} from '@fortawesome/free-solid-svg-icons'

import { NewMsgMarker, ProfileAvatar } from "Components";

type Props = {
  event: any;
  handleTopLeftButtonClick?: () => Promise<void>;
  myProfileId?: string;
  newMsg?: number;
  isChatList?: boolean;
  addToEvent?: (event_room_id: string, event_my_profile: string) => Promise<void>;
  focusEventId?: string;
  setFocusEventId?: (cardId: string) => void;
};

type GroupType = {
  room_id: string;
  created_at: string;
  room_name: string;
  room_description: string;
  num_of_members: number;
  error_message: string;
}

type RoomProfileType = {
  profile_id: string,
  avatar: string,
  name_on_profile: string,
  description: string,
  background_color: string,
  text_identifier: string,
  num_identifier: string
}

type EventType = {
  id: string
  event_name: string
  created_at: string
  event_diecription: string
  start_time: string
  end_time: string
  chat_id: string
  place_id: string
  location_name: string
}

const EventCard: FC<Props> = ({ event, handleTopLeftButtonClick, myProfileId, newMsg, isChatList, addToEvent, focusEventId, setFocusEventId }: Props) => {
  const [roomProfiles, setRoomProfiles] = useRecoilState(roomProfilesState);
  const [roomProfileMapping, setRoomProfileMapping] = useRecoilState(roomProfileMappingState);
  const [profileEventMapping, setProfileEventMappingState] = useRecoilState(profileEventMappingState);
  const [buttonType, setButtonType] = useState("chat");
  const [thisProfileId, setThisProfileId] = useState("");
  const [isThisCardFocused, setIsThisCardFocused] = useState(false);

  useEffect(() => {
    getProfiles();

    if (focusEventId) {
      console.log("focusEventId:", focusEventId);
      console.log("event.id:", event.chat_id);
      setIsThisCardFocused(focusEventId === event.chat_id)
    }
  }, [focusEventId, event]);

  const getProfiles = async () => {
    // console.log("fetching profiles in events chat");
    const { data, error } = await supabase.rpc("get_users_in_chatroom", {
      p_room_id: event.chat_id,
    });

    if (error) {
      console.log(error);
      return;
    }

    const roomProfiles: RoomProfileType[] = data;

    setRoomProfiles((prevRoomProfiles) => {
      return {
        ...prevRoomProfiles,
        [event.chat_id]: roomProfiles  // Updating profiles for the current chatId
      };
    })
  }

  const generateTopRightButton = () => {
    const topButton: JSX.Element[] = [];

    if (isChatList) {
      topButton.push(
        <IconButton
          aria-label='Search database'
          icon={<ChatIcon />}
          position="absolute"
          top="0"
          right="0"
          zIndex={3}
          colorScheme="white"
          size={"lg"}
          onClick={handleTopLeftButtonClick}
        />
      )
    } else {
      if (buttonType === "chat") {
        topButton.push(
          <IconButton
            aria-label='Search database'
            icon={<ChatIcon />}
            position="absolute"
            top="0"
            right="0"
            zIndex={3}
            colorScheme="white"
            size={"lg"}
            onClick={handleTopLeftButtonClick}
          />
        )
      } else if (buttonType === "add" && event.chat_id && myProfileId && addToEvent) {
        topButton.push(
          <IconButton
            aria-label='Search database'
            icon={<AddIcon />}
            position="absolute"
            top="0"
            right="0"
            zIndex={3}
            colorScheme="white"
            size={"lg"}
            onClick={() => addToEvent(event.chat_id, myProfileId)}
          />
        )
      }
    }

    return topButton;
  }

  useEffect(() => {
    const exist = Object.values(profileEventMapping).flat().find(myevent => myevent.chat_id === event.chat_id && myevent.profile_id === myProfileId);

    if (!myProfileId || myProfileId === "") {
      setButtonType("none");
    } else if (exist) {
      setButtonType("chat");
      setThisProfileId(exist.profile_id);
    } else {
      setButtonType("add");
      setThisProfileId(myProfileId);
    }
  }, [myProfileId]);

  const hasEventPassed = (event: EventType): boolean => {
    const currentTime = new Date();
    const eventEndTime = new Date(event.end_time);
    return currentTime > eventEndTime;
  };

  return <Box
    width={"100%"}
    height={"100%"}
    // display={"flex"}
    justifyContent={"center"}
  >
    <Card
      width={"100%"}
      backgroundColor={"purple.UC"}
      color={"white.UC"}
      // paddingBottom={"1rem"}
      boxShadow={isThisCardFocused ? "0 0 0 0.2rem #73B3A3" : "none"}
      onClick={() => {
        if (setFocusEventId) {
          if (event.chat_id !== focusEventId) {
            setFocusEventId(event.chat_id);
          } else {
            setFocusEventId("");
            setIsThisCardFocused(false);
          }
        }
      }}

    >
      <CardHeader
        // borderBottom={"1px black solid"}
        padding={"1rem"}
      >
        <Flex>
          <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
            <Box display={"flex"} width={"100%"} alignContent={"center"} alignItems={"center"}>
              {
                thisProfileId && thisProfileId !== "" ? <Box height={"3rem"} width={"3rem"}>
                  <ProfileAvatar userId={thisProfileId} chatRoomName="" gotBorder={false} group={false} />
                </Box> : <></>
              }
              <Heading size='md' width={"80%"} pl={"1rem"}>
                <Text variant={"soft"} fontSize={"16px"}>{event.event_name}</Text>
              </Heading>
            </Box>
          </Flex>
          <Box
            position="absolute"
            top="-0.5rem"
            right="-0.5rem"
            zIndex={3}
          >
            <NewMsgMarker numberOfNewMsg={newMsg || 0} />
          </Box>
          {generateTopRightButton()}
        </Flex>
      </CardHeader>
      {
        hasEventPassed(event) ? <></> : <CardBody
          padding={"0rem 1rem"}
          paddingBottom={"1rem"}
        >
          <Text
            marginTop={"0.5rem"}
            color={"grey.UC"}
            fontSize={"14px"}
            mb={"0.5rem"}
          >
            <FontAwesomeIcon icon={faLocationDot} style={{ marginRight: "0.5rem" }} />
            {event.location_name || "No location specified"}
          </Text>

          <Text
            fontSize={"14px"}
            fontWeight={"bold"}
            marginBottom={"0.5rem"}
            width={"100%"}
          >
            <Text>Starts: {new Date(event.start_time).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</Text>
            <Text>Ends: {new Date(event.end_time).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</Text>
            <Text>Tags: {event.tags}</Text>
          </Text>
          <Text
            fontSize={"14px"}
          >
            {event.event_discription || event.event_discription}
          </Text>

          <AvatarGroup
            size='md'
            max={3}
            mt={"1rem"}
            color={"purple.UC"}
          >
            {roomProfiles[event.chat_id] && roomProfiles[event.chat_id].map((profile, index) => {
              return (
                <Avatar
                  key={index}
                  name={profile.name_on_profile}
                  src={"https://zzwxyobwfvjshssazycj.supabase.co/storage/v1/object/public/avatars/" + profile.avatar}
                  color={"white.UC"}
                  backgroundColor={"white.UC"}
                />
              );
            })}
          </AvatarGroup>
        </CardBody>
      }
    </Card>
  </Box >
};

export default EventCard;