import {
    Box,
    Text,
    Flex,
    Avatar,
    Heading,
    IconButton,
    Button,
    AvatarGroup,
    useDisclosure,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    useToast,
} from "@chakra-ui/react";
import { AddIcon, CopyIcon, ChatIcon } from "@chakra-ui/icons";
import React, { FC, useEffect, useState } from "react";
import { supabase } from "Services/supabase";
import { useRecoilState } from "recoil";
import { roomProfilesState, roomProfileMappingState } from "recoilStore/store";

type Props = {
    groupData: GroupType;
    handleTopLeftButtonClick?: () => void;
    myProfileId: string;
};

type GroupType = {
    room_id: string;
    created_at: string;
    room_name: string;
    room_description: string;
    num_of_members: number;
    error_message: string;
}

type RoomProfileType = {
    profile_id: string,
    avatar: string,
    name_on_profile: string,
    description: string,
    background_color: string,
    text_identifier: string,
    num_identifier: string
}

const GroupCard: FC<Props> = ({ groupData, handleTopLeftButtonClick, myProfileId }: Props) => {
    const [roomProfiles, setRoomProfiles] = useRecoilState(roomProfilesState);
    const [roomProfileMapping, setRoomProfileMapping] = useRecoilState(roomProfileMappingState);

    useEffect(() => {
        getProfiles();
    }, [groupData.room_id]);

    const getProfiles = async () => {
        const { data, error } = await supabase.rpc("get_users_in_chatroom", {
            p_room_id: groupData.room_id,
        });

        if (error) {
            console.log(error);
            return;
        }

        const roomProfiles: RoomProfileType[] = data;

        setRoomProfiles((prevRoomProfiles) => {
            return {
                ...prevRoomProfiles,
                [groupData.room_id]: roomProfiles  // Updating profiles for the current chatId
            };
        })
    }

    const toast = useToast();

    const handleCopyClick = () => {
        navigator.clipboard.writeText(groupData.room_id)
            .then(() => {
                toast({
                    position: "top",
                    title: "Copied",
                    description: "Identifier copied to clipboard",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            })
            .catch(err => {
                toast({
                    position: "top",
                    title: "Error",
                    description: "Failed to copy text identifier",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            });
    };

    return (
        <Box
            width={"100%"}
            height={"100%"}
            // display={"flex"}
            justifyContent={"center"}
            paddingTop={"2rem"}
            paddingBottom={"2rem"}
        >
            <Card
                width={"100%"}
                backgroundColor={"green.UC"}
            >
                <CardHeader
                    borderBottom={"1px black solid"}
                >
                    <Flex>
                        <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                            <Avatar name={groupData.room_name} backgroundColor={"white"} />

                            <Box>
                                <Heading size='md'>{groupData.room_name}</Heading>
                                <Text>Created on {new Date(groupData.created_at).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</Text>
                            </Box>
                        </Flex>
                        {
                            (() => {
                                const isMyGroup = roomProfileMapping[groupData.room_id] === myProfileId;

                                if (myProfileId === "") {
                                    return (
                                        <></>
                                    )
                                } else if (isMyGroup) {
                                    return (
                                        <IconButton
                                            aria-label='Search database'
                                            icon={<ChatIcon />}
                                            position="absolute"
                                            top="0"
                                            right="0"
                                            zIndex={3}
                                            colorScheme="white"
                                            size={"lg"}
                                            onClick={() => { }}
                                        />
                                    )
                                } else {
                                    return (
                                        <IconButton
                                            aria-label='Search database'
                                            icon={<AddIcon />}
                                            position="absolute"
                                            top="0"
                                            right="0"
                                            zIndex={3}
                                            colorScheme="white"
                                            size={"lg"}
                                            onClick={handleTopLeftButtonClick}
                                        />
                                    )
                                }
                            })()
                        }
                    </Flex>
                </CardHeader>
                <CardBody>
                    <Text
                        fontSize={"1rem"}
                        fontWeight={"bold"}
                        marginBottom={"1rem"}
                    >
                        {groupData.room_description}
                    </Text>

                    <AvatarGroup size='lg' max={2}>
                        {roomProfiles[groupData.room_id] && roomProfiles[groupData.room_id].map((profile, index) => {
                            return (
                                <Avatar
                                    key={index}
                                    name={profile.name_on_profile}
                                    src={"https://zzwxyobwfvjshssazycj.supabase.co/storage/v1/object/public/avatars/" + profile.avatar}
                                    // backgroundColor={profile.background_color}
                                    // color={profile.text_identifier}
                                />
                            );
                        })}
                    </AvatarGroup>
                </CardBody>

                <CardFooter
                    padding={"2rem"}
                >
                    <Box
                        position="absolute"
                        bottom="1rem"
                        left="1rem"
                        right="1rem"
                        display={"flex"}
                        justifyContent={"center"}
                    >
                        <Button
                            onClick={handleCopyClick}
                            width={"100%"}
                        >
                            Copy room identifier
                            <CopyIcon ml={2} />
                        </Button>
                    </Box>
                </CardFooter>
            </Card>
        </Box >
    );
};

export default GroupCard;
