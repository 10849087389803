import React, { FC, useEffect } from 'react'
import { Card, Text, Flex, Box, Drawer, AccordionItem, AccordionPanel, AccordionButton, AccordionIcon, useDisclosure, DrawerBody, Accordion, CardFooter, CardBody, Heading, CardHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader } from '@chakra-ui/react'

import { supabase } from "Services/supabase";

type Props = {}

const UpdateTile: FC<Props> = (props: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [updates, setUpdates] = React.useState<updateType[]>([])

  type updateType = {
    id: string,
    feedback_id: string,
    new_feature: string,
    created_at: string,
    creator_name: string,
    q_one: string,
    q_two: string
  }

  const openAboutUs = () => {
    onOpen()
  }

  const getUpdates = async () => {
    const { data, error } = await supabase.rpc('fetch_updates_v2');

    if (error) {
      console.log("error", error)
      return null
    }

    if (data) {
      setUpdates(data)
    }
  }


  useEffect(() => {
    getUpdates()
  }, [])

  function shortenString(str: string) {
    if (str.length <= 4) return str;

    const start = str.slice(0, 2);
    const end = str.slice(-1);
    const middle = '*'.repeat(str.length - 3); // Subtract 4 for the start and end characters

    return start + middle + end;
  }

  return (
    <>
      <Card
        w={"100%"}
        h={"100%"}
        borderRadius={"10px"}
        boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
        backgroundColor={"purple.UC"}
        onClick={openAboutUs}
      >
        <Flex
          w={"100%"}
          h={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Text
            variant={"UpdateTitle"}
            alignContent={"center"}
            textAlign={"center"}
          >
            What next?
          </Text>
        </Flex>
      </Card>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        size={"full"}
      >
        <DrawerOverlay bg="green.UC" />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Text variant={"soft"} color={"white"}>What's next?</Text>
          </DrawerHeader>

          <DrawerBody

          >
            {
              updates?.map((update: updateType) => {
                return <Card align='center'>
                  <CardHeader>
                    <Text variant="soft">Pending Updates</Text>
                  </CardHeader>
                  <CardBody
                    backgroundColor={"purple.UC"}
                    color={"white.UC"}
                  >
                    <Text>{update.new_feature}</Text>
                  </CardBody>
                  <CardFooter
                    display="box"
                    width={"100%"}
                    p={"0"}
                  >
                    {
                      update.feedback_id ? <>
                        <Box>
                          <Text variant={"soft"} padding={3}>Check feedback from {shortenString(update.creator_name)}</Text>
                        </Box>
                        <Accordion allowToggle>
                          <AccordionItem>
                            <AccordionButton>
                              <Box as="span" flex='1' textAlign='left'>
                                What do you think about this app?
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                              {update.q_one}
                            </AccordionPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionButton>
                              <Box as="span" flex='1' textAlign='left'>
                                How can we improve it?
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                              {update.q_two}
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      </> : <></>
                    }
                  </CardFooter>
                </Card>
              })
            }
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default UpdateTile