import React, { FC } from "react";
import { useRecoilState } from "recoil";
import { userID } from "recoilStore/store";
import { ChatRoomView } from "Views";

type Props = {};

const ChatPage: FC<Props> = (props: Props) => {
  return <ChatRoomView />
};

export default ChatPage;
