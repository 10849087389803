import React from "react";
import { EventCard } from "Components";
import {
    Grid,
    GridItem,
    Stack,
    Box,
    Flex,
    Text,
    Input,
    Switch
} from "@chakra-ui/react";
import { EventsType } from "Types/types";
import { useRecoilState } from "recoil";
import { roomProfilesState } from "recoilStore/store";

type Props = {
    events: Array<EventsType>;
    handleTopLeftButtonClick?: () => Promise<void>;
    myProfileId?: string;
    addToEvent?: (
        event_room_id: string,
        event_my_profile: string
    ) => Promise<void>;
    focusEventId?: string;
    setFocusEventId?: (focusedEventId: string) => void;
};

const EventsListView = ({
    events,
    handleTopLeftButtonClick,
    myProfileId,
    addToEvent,
    focusEventId,
    setFocusEventId,
}: Props) => {
    const [rooms, setRooms] = useRecoilState(roomProfilesState);
    const [filterFocus, setFilterFocus] = React.useState<boolean>(false);
    const [allTags, setAllTags] = React.useState<Array<string>>([]);
    const [suggestions, setSuggestions] = React.useState<Array<string>>([]);
    const [filter, setFilter] = React.useState<string>("");
    const [debounceChange, setDebounceChange] = React.useState<boolean>(false);
    const [timeoutId, setTimeoutId] = React.useState<ReturnType<
        typeof setTimeout
    > | null>(null);
    const [sortBy, setSortBy] = React.useState<string>("popularity");

    const [filteredEvents, setFilteredEvents] =
        React.useState<Array<EventsType>>();

    React.useEffect(() => {
        const tags = new Set<string>();
        events.forEach((event) => {
            event.tags.split(",").forEach((tag: string) => {
                tags.add(tag.trim());
            });
        });
        setAllTags(Array.from(tags));
        setFilteredEvents([...(filteredEvents || events)].sort((a, b) => sortEvents(a, b, sortBy)));
        return () => {
            setFilteredEvents([]);
        };
    }, []);

    React.useEffect(() => {
        setFilteredEvents([...(filteredEvents || events)].sort((a, b) => sortEvents(a, b, sortBy)));
    }, [rooms])

    const rankFilters = (
        filtersArray: Array<string>,
        eventTags: Array<string>
    ) => {
        return filtersArray.filter((filter) => eventTags.includes(filter))
            .length;
    };

    const sortEvents = (a: EventsType, b: EventsType, newSort:string) => {
        if (!a || !b) return -1;
        if (newSort === "time") {
            const timeA = new Date(a.start_time).getTime();
            const timeB = new Date(b.start_time).getTime();
            return timeA - timeB;
        }
        else {
            if (Object.keys(rooms).length === 0) return -1;
            const roomA = rooms[a.chat_id];
            const roomB = rooms[b.chat_id];
            return roomB.length - roomA.length;
        }
    }

    const rankEvents = (filtersArray: Array<string>) => {
        return events
            .map((event) => {
                const eventTags = event.tags
                    .split(",")
                    .map((s: string) => s.trim());
                const rank = rankFilters(filtersArray, eventTags);
                return { ...event, rank };
            })
            .filter((event) => event.rank > 0)
            .sort((a, b) => b.rank - a.rank)
            .sort((a, b) => sortEvents(a, b, sortBy))
            .map(({ rank, ...eventWithoutRank }) => eventWithoutRank);
    };

    const getFilteredArray = (strFilter: string) => {
        const filtersArray: Array<string> = strFilter
            .split(",")
            .map((s: string) => s.trim());
        const rankedEvents: Array<EventsType> = rankEvents(filtersArray);
        return rankedEvents;
    };

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (timeoutId != null) {
            clearTimeout(timeoutId);
            setTimeoutId(null);
        }
        const newFilter:string = e.target.value.trim();
        setFilter(newFilter);
        setDebounceChange(true);
        setTimeoutId(
            setTimeout(() => {
                setDebounceChange(false);
                if (!newFilter) {
                    setFilteredEvents(events);
                    setSuggestions(allTags);
                } else {
                    const allFilters:Array<string> = newFilter.split(",");
                    const lastFilter:string | undefined = allFilters !== undefined && allFilters.length > 0 ? allFilters.pop()?.trim() : "";
                    let matchedSuggestions: Array<string> = []
                    if (lastFilter) {
                        matchedSuggestions = allTags.filter((tag: string) =>
                            tag.toLowerCase()?.includes(lastFilter.toLowerCase())
                        );
                    }
                    setSuggestions(matchedSuggestions);
                    setFilteredEvents(getFilteredArray(newFilter));
                }
            }, 300)
        );
    };

    const handleSortChange = () => {
        const newSort = sortBy === "popularity" ? "time" : "popularity";
        setSortBy(newSort);
        setFilteredEvents([...(filteredEvents || events)].sort((a, b) => sortEvents(a, b, newSort)));
    }

    return (
        <Flex direction={"column"} gap={"0.5rem"}>
            <Flex direction={"column"}>
                <Flex
                    direction={"row"}
                    width={"100%"}
                    backgroundColor={"white"}
                    borderRadius={"0.3rem"}
                    border={"1px solid #0002"}
                    alignItems={"center"}
                    position={"relative"}
                >
                    <Flex
                        padding={"0.3rem"}
                        borderRight={"1px solid #0002"}
                        height={"100%"}
                        alignItems={"center"}
                    >
                        <Text>Filter:</Text>
                    </Flex>
                    <Input
                        id={"tagFilter"}
                        onFocus={() => setFilterFocus(true)}
                        onBlur={() => setFilterFocus(false)}
                        name={"filter"}
                        type="text"
                        flexGrow={1}
                        border={"none"}
                        onChange={handleFilterChange}
                        placeholder={
                            "Enter tags separated by a coma(,)"
                        }
                        value={filter}
                    />
                    {filterFocus && (
                        <Box
                            position={"absolute"}
                            width={"100%"}
                            maxH={"200px"}
                            overflowY={"auto"}
                            bg={"white"}
                            zIndex={999}
                            top={"calc(100% + 5px)"} // You can add a small margin top to separate it from the input field
                            boxShadow={"md"} // Optional, adds a shadow for better separation visually
                            borderRadius={"0.3rem"} // Optional, to match the input field borderRadius
                        >
                            {suggestions.map((suggestion, index) => (
                                <Box
                                    key={index}
                                    p={2}
                                    cursor={"pointer"}
                                    _hover={{ bg: "gray.100" }}
                                    onClick={() => {
                                        const filterParts = filter
                                            .split(",")
                                            .map((part) => part.trim());
                                        if (
                                            filterParts.length > 0 &&
                                            filterParts[
                                                filterParts.length - 1
                                            ] !== ""
                                        ) {
                                            filterParts.pop();
                                        }
                                        filterParts.push(suggestion);
                                        const newFilter =
                                            filterParts.join(", ");
                                        setFilter(newFilter); // This will update the input value as well
                                        setSuggestions([]);
                                        setFilteredEvents(
                                            getFilteredArray(newFilter)
                                        );
                                        if (timeoutId != null) {
                                            clearTimeout(timeoutId);
                                            setTimeoutId(null);
                                        }
                                    }}
                                >
                                    {suggestion}
                                </Box>
                            ))}
                        </Box>
                    )}
                </Flex>
                <Flex padding={"0.5rem"} paddingLeft={"0"} gap={"0.5rem"}>
                    <Switch id="swSortBy" onChange={handleSortChange}></Switch>
                    <Text>Sort by {sortBy}</Text>
                </Flex>
            </Flex>
            <Stack
                justifyContent={"center"}
                direction={"column"}
                spacing="1rem"
            >
                {filteredEvents !== undefined ? (
                    filteredEvents.map((event: EventsType) => (
                        <EventCard
                            key={event.event_id}
                            event={event}
                            handleTopLeftButtonClick={handleTopLeftButtonClick}
                            myProfileId={myProfileId}
                            addToEvent={addToEvent}
                            focusEventId={focusEventId}
                            setFocusEventId={setFocusEventId}
                        />
                    ))
                ) : (
                    <></>
                )}
            </Stack>
        </Flex>
    );
};

export default EventsListView;
