import React, { FC } from 'react'

type Props = {}

const TimelinePage:FC<Props> = (props: Props) => {
  return (
    <div>TimelinePage</div>
  )
}

export default TimelinePage